import React, { useEffect } from "react";
import './mortgage-agent.scss';
import { Button, Container, Grid } from "@mui/material";
import Blog from "../../../../components/shared/blog/Blog";
import headOffice from "../../../../assets/public/images/head-office.jpg";
import LinkToDetail from "../../../../components/shared/link-to-detail/LinkToDetail";
import { teamMemberOne, teamMemberTwo, teamMemberThree } from "../../../../core/Images";


const MortgageAgent = function () {
    return (
        <>
            <section className="agent sec-padding">
                <Container>
                    <div className="m-agent">
                        <div className="m-agent-image">
                            <img src={teamMemberOne} alt="" />
                        </div>
                        <div className="m-agent-text">
                            <h2>Sarah Turck</h2>
                            <h3>RateShop Advisor</h3>
                            <Button className="main-button">Apply with me</Button>
                        </div>
                    </div>
                </Container>
            </section>
            <section className="locations-map l-m-small">
                <Container>
                    <Grid container>
                        <Grid item md={7}>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2882.7354224472683!2d-79.6926392845376!3d43.736822879118535!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b3c5233164c69%3A0x47c17de82a8628b9!2s6%20Indell%20Ln%2C%20Brampton%2C%20ON%20L6T%203Y3%2C%20Canada!5e0!3m2!1sen!2sin!4v1681233420830!5m2!1sen!2sin" width="100%" height={450} style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </Grid>
                        <Grid item md={5}>
                            <div className="l-text">
                                <h2>6 Indell Ln, Brampton, ON L6T 3Y3, Canada</h2>
                                <div>
                                    <h4>Call</h4>
                                    <ul>
                                        <li><span>Local:</span>416-827-2626</li>
                                        <li><span>Toll Free:</span>800-264-5855</li>
                                    </ul>
                                </div>
                                <div>
                                    <h4>Hours</h4>
                                    <ul>
                                        <li><span>Weekdays:</span>9am - 6pm</li>
                                        <li><span>Weekends:</span>10am - 6pm</li>
                                    </ul>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </section>
            <section className="loc-text sec-padding">
                <Container>
                    <h2>Maecenas non bibendum nibh. Suspendisse id elit condimentum, mollis ligula nec, tempor turpis.</h2>
                    <p>Suspendisse rutrum neque eget diam sagittis tempor. Phasellus at odio et eros consectetur feugiat non vitae massa. Morbi dictum interdum faucibus. Ut porta vitae tortor consectetur euismod. Nunc id tortor lectus. Praesent a consectetur ex, ut faucibus turpis.</p>
                    <p>Maecenas in risus et mi mattis tempor. Nullam dictum felis quis mi congue, ac accumsan massa pulvinar.</p>
                    <p>Cras a quam tincidunt, euismod dui in, interdum nulla. Mauris purus neque, scelerisque et gravida quis, placerat quis est. Proin consequat, odio non faucibus efficitur, libero lacus pulvinar ex, ac pharetra neque sapien quis tortor.</p>
                    <p>Fusce non urna id lorem laoreet rhoncus. Vivamus auctor pharetra ipsum, a pretium ligula dictum in.</p>
                    <p>Nulla consectetur id tellus non auctor. Nulla sed diam leo. Nullam nec enim a leo rutrum iaculis.</p>
                </Container>
            </section>
        </>
    )
}

export default MortgageAgent;