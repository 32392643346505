import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Container, Grid , Button} from "@mui/material/";
import './testimonials.scss';
import StarIcon from '@mui/icons-material/Star';
import { NavLink } from "react-router-dom";
import { Quoteicon } from '../../../core/Images'
import iconTrustpilot from "../../../assets/public/images/icon-trustpilot.png";
import logoTrustpilot from "../../../assets/public/images/logo-trustpilot.png";
import {
    GoogleSvg,
    FacebookSvg
  } from "../../../core/ImgsesSvg";
import classNames  from 'classnames';
import { Link, useNavigate  } from 'react-router-dom';
import Cookies from 'universal-cookie';
const Testimonials = (props) => {

    const {title,description,className} = props;
    let navigate = useNavigate(); 
    const cookies = new Cookies();
    const routeChange = () =>{ 
        var path = '';
        if(cookies.get('cookieAgentSlug') != '' && cookies.get('cookieAgentSlug') != undefined){
          var path = `apply/`+cookies.get('cookieAgentSlug');  
        }else{
          var path = `apply`;
        }
      navigate(path);
    }
    var options=  {
        item:2,
        className:"owl-theme",
        margin:20,
        responsiveClass: true,
        autoplay:true,
        dots:false,
        nav:true,
        responsive: {
          0: {
              items: 1,
          },
          768: {
              items: 2,
          },
          960: {
            items: 1,
          },
          992: {
              items: 2,
          }
        }
      }

    return (
        <>
            <section className={classNames('testimonial', className)}>
                <Container>
                    {
                        title && description && 
                        <div className="testimonail-title">
                            <div className="heading">
                                <h3>{title}</h3>
                            </div>
                            <p>{description}</p>
                        </div>

                    }
                    <div className="testimonial-outer">
                        <div className="t-o-heading">
                            <div className="review-heading">
                            <h2>Trusted Reviews from Real Customers</h2>
                            <div className="heading">
                                <h3>See why <span>clients</span> love working with us!</h3>
                                </div>
                            </div>
                            <div className="t-o-trustpilot">
                                <img src={logoTrustpilot} alt="" />
                                <ul>
                                    <li><img src={iconTrustpilot} alt="" /></li>
                                    <li><img src={iconTrustpilot} alt="" /></li>
                                    <li><img src={iconTrustpilot} alt="" /></li>
                                    <li><img src={iconTrustpilot} alt="" /></li>
                                    <li><img src={iconTrustpilot} alt="" /></li>
                                </ul>
                                <p>Excellent</p>
                                {/* <p>Based on 940 reviews</p> */}
                            </div>
                        </div>
                        {/* <div className="quote-icon">
                            <img src={Quoteicon} alt="quote"/>
                        </div> */}
                        <Grid container className="align-items-center">
                            <Grid sm={12} md={4} item lg={4}>
                                
                        <div className="quote-icon1">
                            <img src={Quoteicon} alt="quote" width="160" height="110"/>
                        </div>
                            </Grid>
                            <Grid sm={12} md={8} item lg={8}>
                            {/* <div className="elfsight-app-67cc0d84-cb13-42e4-8270-5ae89b369642"></div> */}
                                <OwlCarousel
                                    // items={2}
                                    // className="owl-theme"
                                    // loop
                                    // nav
                                    // margin={40}
                                    // autoplay={true}
                                    // dots={false}
                                    {...options}
                                >
                                    <div className="testimonial-wrap">
                                        <div className="quote-icon">
                                            <GoogleSvg/>
                                        </div>
                                        <div className="rating">
                                        <StarIcon />
                                        <StarIcon />
                                        <StarIcon />
                                        <StarIcon />
                                        <StarIcon />  
                                        </div>
                                        <div className="testimonia-text">
                                        <div className="info-details">
                                                
                                                <div className="info-content">
                                                    <h4>Ram Prasad</h4>
                                                    {/* <p>Principle Broker</p> */}
                                                </div>
                                            </div>
                                            <p>Being a first time home buyer, we had 101 questions related to mortgage and related stuffs. Ankur and Sarah from Rateshop guided us throughout the journey providing valuable guidance. They were able to secure a good mortgage rate too. We are endlessly grateful to have you as our agent and couldn't be happier with the way everything worked out. Would recommend!👍👍</p>
                                            <NavLink target="_blank" to="https://www.trustpilot.com/users/642df2172271270013cbe398">Read more...</NavLink>
                                           
                                        </div>
                                    </div>
                                    <div className="testimonial-wrap">
                                        <div className="quote-icon">
                                            <GoogleSvg/>
                                        </div>
                                        <div className="rating">
                                        <StarIcon />
                                        <StarIcon />
                                        <StarIcon />
                                        <StarIcon />
                                        <StarIcon />  
                                        </div>
                                        <div className="testimonia-text">
                                        <div className="info-details">
                                                
                                                <div className="info-content">
                                                    <h4>Evelien Mraz</h4>
                                                    {/* <p>Principle Broker</p> */}
                                                </div>
                                            </div>
                                            <p>Sarah was incredible! She was there every step of the way, was so knowledgeable and answered every single question we had! She has such a big network that she was able to help us and guide us through the whole process! Thank you Sarah! You made our dream come true!</p>
                                            <NavLink target="_blank" to="https://www.trustpilot.com/users/6421b8efe06d7400123a96ce">Read more...</NavLink>
                                           
                                        </div>
                                    </div>
                                    <div className="testimonial-wrap">
                                        <div className="quote-icon">
                                            <GoogleSvg/>
                                        </div>
                                        <div className="rating">
                                        <StarIcon />
                                        <StarIcon />
                                        <StarIcon />
                                        <StarIcon />
                                        <StarIcon />  
                                        </div>
                                        <div className="testimonia-text">
                                        <div className="info-details">
                                                
                                                <div className="info-content">
                                                    <h4>Tafiqul Abu Mohammad</h4>
                                                    {/* <p>Principle Broker</p> */}
                                                </div>
                                            </div>
                                            <p>Ariful was very caring and one of the best broker I have ever worked with. Thank you for all your hard work on my file Ariful.</p>
                                            <NavLink target="_blank" to="https://www.trustpilot.com/users/6276aff91b82d500111a51ac">Read more...</NavLink>
                                           
                                        </div>
                                    </div>
                                    <div className="testimonial-wrap">
                                        <div className="quote-icon">
                                            <GoogleSvg/>
                                        </div>
                                        <div className="rating">
                                        <StarIcon />
                                        <StarIcon />
                                        <StarIcon />
                                        <StarIcon />
                                        <StarIcon />  
                                        </div>
                                        <div className="testimonia-text">
                                        <div className="info-details">
                                                
                                                <div className="info-content">
                                                    <h4>Fazila Nazia</h4>
                                                    {/* <p>Principle Broker</p> */}
                                                </div>
                                            </div>
                                            <p>Sami Juneja is a very professional mortgage agent. We had home purchase closing and he was able to arrange a mortgage with a very competitive rate. Really appreciate his hard work during whole process from start to getting us our home.</p>
                                            <NavLink target="_blank" to="https://www.trustpilot.com/users/6407f47ce15124001415f115">Read more...</NavLink>
                                           
                                        </div>
                                    </div>
                                    <div className="testimonial-wrap">
                                        <div className="quote-icon">
                                            <GoogleSvg/>
                                        </div>
                                        <div className="rating">
                                        <StarIcon />
                                        <StarIcon />
                                        <StarIcon />
                                        <StarIcon />
                                        <StarIcon />  
                                        </div>
                                        <div className="testimonia-text">
                                        <div className="info-details">
                                                
                                                <div className="info-content">
                                                    <h4>Megan Mousavi</h4>
                                                    {/* <p>Principle Broker</p> */}
                                                </div>
                                            </div>
                                            <p>Chantel is nothing short of exceptional. I have never met someone who is as responsive, enthusiastic and relentlessly hardworking as Chantel. She helped me purchase my first home and I am very grateful for her guidance and professionalism during this stressful time! Chantel was able to find the exact rate, down payment and timeline I was requesting. I highly recommend her to all home buyers!</p>
                                            <NavLink target="_blank" to="https://www.trustpilot.com/users/6257743a5610220012cc2586">Read more...</NavLink>
                                           
                                        </div>
                                    </div>
                                    <div className="testimonial-wrap">
                                        <div className="quote-icon">
                                            <GoogleSvg/>
                                        </div>
                                        <div className="rating">
                                        <StarIcon />
                                        <StarIcon />
                                        <StarIcon />
                                        <StarIcon />
                                        <StarIcon />  
                                        </div>
                                        <div className="testimonia-text">
                                        <div className="info-details">
                                                
                                                <div className="info-content">
                                                    <h4>Elizabeth Barnett</h4>
                                                    {/* <p>Principle Broker</p> */}
                                                </div>
                                            </div>
                                            <p>The expertise, guidance and patience of the Rate Shop Team is impeccable. I have now had the pleasure of doing business with Ali twice and I look forward to future experiences. Ali takes all finances into consideration before recommending an excellent solution. He truly takes the 'stress' of off the client. Thank-you for all your teams hard work.</p>
                                            <NavLink target="_blank" to="https://www.trustpilot.com/users/6399f066221b410013d822a0">Read more...</NavLink>
                                           
                                        </div>
                                    </div>
                                    <div className="testimonial-wrap">
                                        <div className="quote-icon">
                                            <GoogleSvg/>
                                        </div>
                                        <div className="rating">
                                        <StarIcon />
                                        <StarIcon />
                                        <StarIcon />
                                        <StarIcon />
                                        <StarIcon />  
                                        </div>
                                        <div className="testimonia-text">
                                        <div className="info-details">
                                                
                                                <div className="info-content">
                                                    <h4>Nick</h4>
                                                    {/* <p>Principle Broker</p> */}
                                                </div>
                                            </div>
                                            <p>Sarah did an amusing job handling my reverse mortgage application and getting everything approved. From the initial contact thru a reliable source and her patience and never pressuring as I had taken several months deciding on this big decision. When I finally decided to move forward Sarah was always available to answer any questions I had and ensure I was headed in the right direction explaining every detail in great lengths. Made me and my wife feel very comfortable that we made the right decision. Sarah went out of her way to find us proper lawyers to handle the legal aspect of this process. In the end she followed up to ensure everything was completed with the lawyers and we were happy.</p>
                                            <NavLink target="_blank" to="https://www.trustpilot.com/users/626dd3abb4efc0001201a975">Read more...</NavLink>
                                           
                                        </div>
                                    </div>
                                    
                                    <div className="testimonial-wrap">
                                        <div className="quote-icon">
                                            <GoogleSvg/>
                                        </div>
                                        <div className="rating">
                                        <StarIcon />
                                        <StarIcon />
                                        <StarIcon />
                                        <StarIcon />
                                        <StarIcon />  
                                        </div>
                                        <div className="testimonia-text">
                                        <div className="info-details">
                                                
                                                <div className="info-content">
                                                    <h4>Famous Baby</h4>
                                                    {/* <p>Principle Broker</p> */}
                                                </div>
                                            </div>
                                            <p>Awesome staff, made our experience relaxed and fun</p>
                                            <NavLink target="_blank" to="https://www.trustpilot.com/users/5cc336023e992b884431e0fe">Read more...</NavLink>
                                           
                                        </div>
                                    </div>
                                    
                                </OwlCarousel>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="test-get-started">
                        <Button variant="contained" onClick={routeChange}>Get Started Now</Button>
                    </div>
                </Container>
            </section>
        </>
    );
};

export default Testimonials;
