import React, { useEffect } from "react";
import { Button, Container, Grid} from "@mui/material";
import AOS from 'aos';
import "aos/dist/aos.css";
import {
    blogimg1,
    blogimg2,
    blogimg3,
    blogimg4,
    blogBanner,
    reading, 
    clock
}
from "../../../../core/Images";
import Testimonials from "../../../../components/shared/testimonial/Testimonials";
// import OwlCarousel from "react-owl-carousel";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";
// import Testimonials from '../../../components/shared/testimonial/Testimonials';
// import Blog from '../../../components/shared/blog/Blog';
import PartnerLogo from '../../../../components/shared/partner-logo/PartnerLogo';
import Partner from '../../../../components/shared/partner/Partner';
// import CardCommon from "../../../components/shared/card-common/CardCommon";
// import Accordion from '@mui/material/Accordion';
// import AddIcon from '@mui/icons-material/Add';
// import MinimizeIcon from '@mui/icons-material/Minimize';
// import SelectUI from "../../../components/ui/select/SelectUI";
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { NavLink, useNavigate } from "react-router-dom";
import { Oval } from 'react-loader-spinner';
import { Link } from 'react-router-dom';   
import { posts } from '../../../../config/posts.js';
import ReactPaginate from 'react-paginate';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs'; 
import './Blogs.scss'; 
const Blogs = () => {
    const [currentPage, setCurrentPage] = React.useState(0);
    const [totalPages, setTotalPages] = React.useState(0);
    const [allBlogs, setAllBlogs] = React.useState([]);
    const [allCategories, setAllCategories] = React.useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        AOS.init();
        AOS.refresh();

        const token = {
            Authorization: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoiQWJoaXNoZWsgUHVyaSIsIm5hbWUiOiJSZWFjdCBSUyIsInBhc3N3b3JkIjpudWxsLCJBUElfVElNRSI6MTY3NjY0MjQyNn0.kcYNItsCPFOtwPIgffGx1Zf-BGDN_Vsx-r0vnGcucOs',
        };
        // const apiData = {...values, ...slugObj};
        // console.log('valuesss', apiData);
        // setShowLoader(true);
       
        fetch('https://crm.rateshop.ca/ReactController/allBlogs', {
            method: 'POST',
            headers: {
                Accept: 'application/form-data',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(token),
        })
            .then(res => res.json())
            .then(
                (result) => {
                    setAllBlogs(result.blogs);
                    setAllCategories(result.categories);
                    setTotalPages(Math.ceil(result.blogs.length / itemsPerPage));
                }
            )
    }, []);

   
    const itemsPerPage = 8; // Number of posts to display per page
  
    // Calculate the total number of pages based on the posts length
    useEffect(() => {
      setTotalPages(Math.ceil(allBlogs.length / itemsPerPage));
    }, []);
  
    // Function to handle page changes
    const handlePageChange = ({ selected }) => {
      setCurrentPage(selected);
    };
  
    // Get the paginated posts for the current page
    const getPaginatedPosts = () => {
      const startIndex = currentPage * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      return allBlogs.slice(startIndex, endIndex);
    };
    const routeChange = () =>{ 
        let path = `/apply`; 
        navigate(path);
      }
    if (allBlogs.length == 0) {
        return <Oval
            height={60}
            width={60}
            color="#FFFFFF"
            wrapperStyle={{}}
            wrapperClass="csLoader"
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor="#EEEEEE"
            strokeWidth={4}
            strokeWidthSecondary={4}
        />;
    }
    return (<>
    {/* blog banner section start here */}
    <section className="banner blog-banner">  
        <Container>
          <Grid container>
            <Grid lg={7} className="d-flex flex-column align-items-start" item>
              <div className="heading"  >
                <h1>
                  Canada's <span>Lowest Mortgage Rates</span><br /> Every Time, Guaranteed!
                </h1>
              </div>
              <div className="line"></div>
              <p>Haggle free Lowest Rates Upfront</p>
              <p>No Credit Checks, No Strings Attached</p>
                <Button onClick={routeChange} variant="contained">Quick Apply in 60 seconds</Button>
            </Grid>
            <Grid lg={1} item></Grid>
            <Grid lg={4} item>
                <figure>
                    <img src={blogBanner} alt="Rateshop"   />
                </figure>
            </Grid>
          </Grid>
        </Container>
      </section>
        <section className="common-image-bg blog-banner">
            <Container>
                <Grid container>
                    <Grid xs={12} sm={12} md={12} lg={12} item>
                        {/* <div className="banner-content"> 
                            <div className="heading">
                                <h1>OUR <span className="com-back-head">LEARNING CENTER</span></h1>
                            </div>
                        </div> */}
                        <div className="categories d-flex align-items-center">
                            <p>Categories  </p>
                            <ul className="d-flex flex-wrap align-items-center">
                            
                            {allCategories.map((item, index) => {
                               
                                return (
                                    <>
                                        <li>  
                                            <NavLink to={`/learning-center/category/${item.slug}`}>{item.name}</NavLink>
                                            
                                        </li>  
                                    </>
                                    )
                                })}
                            </ul>

                        </div>
                    </Grid>
                </Grid>
            </Container>
        </section>
    {/* blog banner section end here */}
    
    <Container> 
            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" to="/">
                    Home
                </Link> 
                <Typography color="text.primary">Learning Center</Typography>
            </Breadcrumbs>
        </Container>
    {/* blog top section start here */}
    <section className="top-blog">
        <Container>
            <div className="heading">
                <h3><span>Latest</span> Learning Center</h3>
            </div>
            <Grid container className="top-blog-container"> 
            {getPaginatedPosts().map((item, index) => {
            return (
                <>
                    <Grid xs={12} sm={6} md={6} lg={3} item  className="blog-col" key={item.id}>
                            <div className="blog-inner">
                                <NavLink to={`/learning-center/${item.slug}`}>
                                    <figure>
                                        <img src={`https://crm.rateshop.ca/assets/blogs/${item.social_media_image}`} alt="blog"/>
                                    </figure>
                                </NavLink>
                                <div className="blog-content">
                                    <p className="category">{item.categoryname} <span className="view-blog"><img src={clock} alt="View"/> {item.reading_time} Minutes Read  </span></p>
                                    <NavLink  to={`/learning-center/${item.slug}`}>{item.title} </NavLink> 
                                </div>
                            </div>
                    </Grid>
                </>
                )
            })} 
            <ReactPaginate
                pageCount={totalPages}
                pageRangeDisplayed={5}
                marginPagesDisplayed={2}
                onPageChange={handlePageChange}
                containerClassName="cspagination"
                activeClassName="active" 
            />
            </Grid> 
            {/* <Button  className="see-more-btn" variant="contained">See more articles</Button> */}
        </Container>
    </section>
    {/* blog top section ends here */}
    {/* blog top section start here */}
    {/* <section className="top-blog bg-white">
        <Container>
            <div className="heading">
                <h3><span>Latest</span> Learning Center</h3>
            </div>
            <Grid container className="top-blog-container">  
                <Grid xs={12} sm={6} md={6} lg={3} item  className="blog-col">
                    <div className="blog-inner">
                        <NavLink to="">
                            <figure>
                                <img src={blogimg1} alt="blog"/>
                            </figure>
                        </NavLink>
                        <div className="blog-content">
                            <p className="category">Category <span>5 min</span></p>
                            <NavLink>RateShop is excited to announce the launch of its partnership with Marble Financial Inc. </NavLink> 
                        </div>
                    </div>
                </Grid>
                <Grid xs={12} sm={6} md={6} lg={3} item  className="blog-col">
                    <div className="blog-inner">
                        <NavLink to="">
                            <figure>
                                <img src={blogimg2} alt="blog"/> 
                            </figure>
                        </NavLink> 
                        <div className="blog-content">
                            <p className="category">Category <span>5 min</span></p>
                            <NavLink>Amazing News! RateShop Has Won Toronto Readers' Choice Award 2021!</NavLink>
                        </div>
                    </div>
                </Grid>
                <Grid xs={12} sm={6} md={6} lg={3} item  className="blog-col">
                    <div className="blog-inner">
                        <NavLink to="">
                            <figure>
                                <img src={blogimg3} alt="blog"/>
                            </figure>
                        </NavLink> 
                        <div className="blog-content">
                            <p className="category">Category <span>5 min</span></p>
                            <NavLink>Exciting News! RateShop Has Been Awarded CMP Top Mortgage Brokerage 2021</NavLink>
                        </div>
                    </div>
                </Grid>
                <Grid xs={12} sm={6} md={6} lg={3} item  className="blog-col">
                    <div className="blog-inner">
                        <NavLink to="">
                            <figure>
                                <img src={blogimg4} alt="blog"/>
                            </figure>
                        </NavLink> 
                        <div className="blog-content">
                            <p className="category">Category <span>5 min</span></p>
                            <NavLink>Best No-Fee Cashback Credit Cards for 2019</NavLink>
                        </div>
                    </div>
                </Grid>
            </Grid> 
            <Button  className="see-more-btn" variant="contained">See more articles</Button>
        </Container>
    </section> */}
    {/* blog top section ends here */}
    {/* blog top section start here */}
    {/* <section className="top-blog">
        <Container>
            <div className="heading">
                <h3><span>Latest</span> Learning Center</h3>
            </div>
            <Grid container className="top-blog-container">  
                <Grid xs={12} sm={6} md={6} lg={3} item  className="blog-col">
                    <div className="blog-inner">
                        <NavLink to="">
                            <figure>
                                <img src={blogimg1} alt="blog"/>
                            </figure>
                        </NavLink>
                        <div className="blog-content">
                            <p className="category">Category <span>5 min</span></p>
                            <NavLink>RateShop is excited to announce the launch of its partnership with Marble Financial Inc. </NavLink> 
                        </div>
                    </div>
                </Grid>
                <Grid xs={12} sm={6} md={6} lg={3} item  className="blog-col">
                    <div className="blog-inner">
                        <NavLink to="">
                            <figure>
                                <img src={blogimg2} alt="blog"/> 
                            </figure>
                        </NavLink> 
                        <div className="blog-content">
                            <p className="category">Category <span>5 min</span></p>
                            <NavLink>Amazing News! RateShop Has Won Toronto Readers' Choice Award 2021!</NavLink>
                        </div>
                    </div>
                </Grid>
                <Grid xs={12} sm={6} md={6} lg={3} item  className="blog-col">
                    <div className="blog-inner">
                        <NavLink to="">
                            <figure>
                                <img src={blogimg3} alt="blog"/>
                            </figure>
                        </NavLink> 
                        <div className="blog-content">
                            <p className="category">Category <span>5 min</span></p>
                            <NavLink>Exciting News! RateShop Has Been Awarded CMP Top Mortgage Brokerage 2021</NavLink>
                        </div>
                    </div>
                </Grid>
                <Grid xs={12} sm={6} md={6} lg={3} item  className="blog-col">
                    <div className="blog-inner">
                        <NavLink to="">
                            <figure>
                                <img src={blogimg4} alt="blog"/>
                            </figure>
                        </NavLink> 
                        <div className="blog-content">
                            <p className="category">Category <span>5 min</span></p>
                            <NavLink>Best No-Fee Cashback Credit Cards for 2019</NavLink>
                        </div>
                    </div>
                </Grid>
            </Grid> 
            <Button  className="see-more-btn" variant="contained">See more articles</Button>
        </Container>
    </section> */}
    {/* blog top section ends here */}
    {/* blog top section start here */}
    {/* <section className="top-blog bg-white">
        <Container>
            <div className="heading">
                <h3><span>Latest</span> Learning Center</h3>
            </div>
            <Grid container className="top-blog-container">  
                <Grid xs={12} sm={6} md={6} lg={3} item  className="blog-col">
                    <div className="blog-inner">
                        <NavLink to="">
                            <figure>
                                <img src={blogimg1} alt="blog"/>
                            </figure>
                        </NavLink>
                        <div className="blog-content">
                            <p className="category">Category <span>5 min</span></p>
                            <NavLink>RateShop is excited to announce the launch of its partnership with Marble Financial Inc. </NavLink> 
                        </div>
                    </div>
                </Grid>
                <Grid xs={12} sm={6} md={6} lg={3} item  className="blog-col">
                    <div className="blog-inner">
                        <NavLink to="">
                            <figure>
                                <img src={blogimg2} alt="blog"/> 
                            </figure>
                        </NavLink> 
                        <div className="blog-content">
                            <p className="category">Category <span>5 min</span></p>
                            <NavLink>Amazing News! RateShop Has Won Toronto Readers' Choice Award 2021!</NavLink>
                        </div>
                    </div>
                </Grid>
                <Grid xs={12} sm={6} md={6} lg={3} item  className="blog-col">
                    <div className="blog-inner">
                        <NavLink to="">
                            <figure>
                                <img src={blogimg3} alt="blog"/>
                            </figure>
                        </NavLink> 
                        <div className="blog-content">
                            <p className="category">Category <span>5 min</span></p>
                            <NavLink>Exciting News! RateShop Has Been Awarded CMP Top Mortgage Brokerage 2021</NavLink>
                        </div>
                    </div>
                </Grid>
                <Grid xs={12} sm={6} md={6} lg={3} item  className="blog-col">
                    <div className="blog-inner">
                        <NavLink to="">
                            <figure>
                                <img src={blogimg4} alt="blog"/>
                            </figure>
                        </NavLink> 
                        <div className="blog-content">
                            <p className="category">Category <span>5 min</span></p>
                            <NavLink>Best No-Fee Cashback Credit Cards for 2019</NavLink>
                        </div>
                    </div>
                </Grid>
            </Grid> 
            <Button  className="see-more-btn" variant="contained">See more articles</Button>
        </Container>
    </section> */}
    {/* blog top section ends here */}
    {/* latest blog section start here */}
        
    {/* latest blog section ends here */}
    {/* Partner Logo Start*/}
        {/* <PartnerLogo/> */}
    {/* Partner Logo End*/}

    {/* partner logo Start*/}
        {/* <Partner/> */}
        <Testimonials />
    {/* partner logo End*/}
    </>)
}

export default Blogs