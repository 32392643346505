import React, {
    //  useState, 
     useEffect } from "react";
import { Button, Container, Grid, TextField, } from "@mui/material";
import './agentsDirectoryDetails.scss';
import AOS from 'aos';
import "aos/dist/aos.css";
// import Radio from '@mui/material/Radio';
// import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import QRCode from "react-qr-code"; 
import CallIcon from '@mui/icons-material/Call';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid'; 
import Testimonials from "../../../../components/shared/testimonial/Testimonials"; 
import ApplyForm from "../../../../components/shared/apply-form/applyForm";
import { styled  }  from '@mui/system';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import { 
    // agent1,
    trusted1,trusted2,trusted3,trusted4,trusted5,  adImage7,adImage8,defaultProfileImage} from "../../../../core/Images";
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import {useParams} from 'react-router-dom';
import axios from 'axios';
import { Oval } from 'react-loader-spinner';
import MortgageRate from "../../../../components/shared/mortgage-rate/MortgageRate";

import { 
    // Route, Routes, 
    useNavigate,
    //  NavLink, 
     Link } from "react-router-dom";
const useStyles = styled ((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        borderRadius:'10px', 
        width: '500px',
        maxWidth: '96%',
        backgroundColor: '#F5FAFE',
        border: '2px solid #153189', 
        overflow: 'auto',
        maxHeight: '100vh',
    },
}));


const AgentsDirectoryDetails = () => {
    const {slug} = useParams();
    // console.log(slug);
    const data = {
      type: 'single',
      Authorization: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoiQWJoaXNoZWsgUHVyaSIsIm5hbWUiOiJSZWFjdCBSUyIsInBhc3N3b3JkIjpudWxsLCJBUElfVElNRSI6MTY3NjY0MjQyNn0.kcYNItsCPFOtwPIgffGx1Zf-BGDN_Vsx-r0vnGcucOs',
      slug: slug
    };
    const navigate = useNavigate();
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [agentDetail, setAgentDetail] = React.useState();
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
  
        const getAgentsData = async () => {
            if(slug != undefined){
                axios.post(`https://crm.rateshop.ca/ReactController/getStaffSlugs`, { data })
                    .then(res => {
                    if(res.data.status != 200){
                        navigate('/404', { replace: true });
                    }else{
                        setAgentDetail(res.data.data[0]);
                    
                        
                    }
                })
            }
        }
    
    useEffect(() => {
        AOS.init();
        AOS.refresh();
        getAgentsData();
    }, []);
    // console.log(agentDetail);
    if (agentDetail === undefined) {
        return <Oval
            height={60}
            width={60}
            color="#ffffff"
            wrapperStyle={{}}
            wrapperClass="csLoader"
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor="#eeeeee"
            strokeWidth={4}
            strokeWidthSecondary={4}
        />;
    }
    return (
        <>
            {/* Directory listing */}
            <section className='agent-directory-detail'>
                <Container>
                    <Grid container>
                        <Grid container item md={8} >
                            <div className="directory-listing-inner" >
                                <div className="agent-img">
                                {(() => {
                                            let imagePath = agentDetail.profile_image;
                                            if (imagePath != null) {

                                                if (imagePath.includes('https://www.rateshop.ca/')) {
                                                    return (
                                                        <figure>
                                                            <img src={agentDetail.profile_image}></img>
                                                        </figure>
                                                    )
                                                }

                                                else if (agentDetail.profile_image != null) {
                                                    return (
                                                        <figure>
                                                            <img src={`https://crm.rateshop.ca/uploads/staff_profile_images/${agentDetail.staffid}/thumb_${agentDetail.profile_image}`}></img>
                                                        </figure>
                                                    )
                                                } else {
                                                    return (
                                                        <figure>
                                                            <img src={defaultProfileImage}></img>
                                                        </figure>
                                                    )
                                                }
                                            } else {
                                                return (
                                                    <figure>
                                                        <img src={defaultProfileImage}></img>
                                                    </figure>
                                                )
                                            }
                                        })()}
                                  
                                </div>
                                <div className="agent-details">
                                    <div className="agent-name">
                                        <h3>{agentDetail.firstname} {agentDetail.lastname}<span className="license-number">Agent License #: {agentDetail.lic}</span></h3>
                                        <h4 className="agent-position">{agentDetail.desigination}</h4>
                                    </div>
                                    <div className="agent-details-links">
                                        <ul>
                                            <li>
                                                <a href={`tel:${agentDetail.phonenumber}`}>
                                                    <span><CallIcon /></span>{agentDetail.phonenumber}
                                                </a>
                                            </li>
                                            
                                            <li>
                                                <a href={`tel:${agentDetail.cell_number}`}>
                                                    <span><PhoneAndroidIcon /></span> {agentDetail.cell_number}
                                                </a>
                                            </li>

                                            {/* <li>
                                                <a href={`mailto:${agentDetail.email}`}>
                                                    <span><MailIcon /></span> {agentDetail.email}
                                                </a>
                                            </li> */}
                                        </ul>
                                    </div>
                                </div>
                                    <div className="verified-icons">
                                        <ul>
                                            <li>
                                                <figure>
                                                    <img src={trusted1}></img>
                                                </figure>
                                            </li>
                                            {/* <li>
                                                <figure>
                                                    <img src={trusted2}></img>
                                                </figure>
                                            </li> */}
                                            <li>
                                                <figure>
                                                    <img src={trusted3}></img>
                                                </figure>
                                            </li>
                                            <li>
                                                <figure>
                                                    <img src={trusted4}></img>
                                                </figure>
                                            </li>
                                            <li>
                                                <figure>
                                                    <img src={trusted5}></img>
                                                </figure>
                                            </li>
                                        </ul>
                                    </div>
                            </div>
                        </Grid>
                        <Grid container item md={4} >
                            <div className="referral-partner">
                                <h3>SIGNUP AS A REFERRAL PARTNER</h3>
                                <div className="qr-code">
                                <div style={{ height: "auto", margin: "0 auto", maxWidth: 64, width: "100%" }}>
                                        <QRCode
                                        size={256}
                                        style={{ height: "auto", maxWidth: "100%", width: "100%"  }}
                                        value={`https://ref.rateshop.ca/web/sign-up.php?agent=${agentDetail.Slug}`}
                                        viewBox={`0 0 256 256`}
                                        />
                                    </div>
                                </div>
                                <p>Scan QR Code</p>
                            </div>
                        </Grid>
                        <Grid container item lg={8} md={7} >
                            <div className="agent-detail-info">
                                <p>Being part of the real estate and mortgage industry for over 20 years. I have affiliated myself with Rateshop Inc. one of the fastest growing independent mortgage brokerages in Ontario. With my experience in developing and growing businesses and having a</p>
                                <ul className="apply-download-btn">
                                    <li>
                                    <Link to={`/apply/${agentDetail.Slug}`}><Button variant="contained" color="secondary">
                                            Apply Online
                                        </Button>
                                    </Link>
                                    </li>
                                    {/* <li>
                                        <Button variant="contained" color="primary">
                                            Download Pdf
                                        </Button>
                                    </li> */}
                                    {/* <li>
                                        <Button variant="contained" color="secondary"  onClick={handleOpen}>
                                            Book Appointment
                                        </Button>
                                    </li> */}
                                </ul>
                            </div>
                        </Grid>
                        <Grid container item lg={4} md={5}>
                            <div className="advisor-assured">
                                <figure>
                                    <img src={adImage7} alt=""></img>
                                </figure>
                                <div className="advisor-assured-text">
                                    <h4>Advisor Assessed in:</h4>
                                    <ul>
                                        <li>Certified Product Expert</li>
                                        <li>120+ Hours in Training</li>
                                        <li>Service Guaranteed</li>
                                        <li>Lending Pro</li>
                                        <li>Lowest Rates</li>
                                    </ul>
                                </div>
                            </div>
                        </Grid>
                        <Grid container item lg={8} md={8}>
                            <div className="pre-qualification">
                                <h3>START YOUR PRE-QUALIFICATIONS NOW</h3>
                                <ul className="pre-qualification-steps">
                                    <li>
                                        <div className="pre-qualification-inner">
                                            <h4>Expert Mortgage Advice</h4>
                                            <div className="pre-qualification-text">
                                                <h3>STEP 1: LEARN</h3>
                                                <p>We'll open with a discovery phase. we want to know about you, your goals and your ideas home loan scenario</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="pre-qualification-inner">
                                            <h4>65+ Lenders to Compare</h4>
                                            <div className="pre-qualification-text">
                                                <h3>STEP 2: LEARN</h3>
                                                <p>Together we will plan for your rates, programs, payments and more. With our experienced Advisors, you will get the unbaised, honest advise you deserve</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="pre-qualification-inner">
                                            <h4>Get ready to close</h4>
                                            <div className="pre-qualification-text">
                                                <h3>STEP 3: LEARN</h3>
                                                <p>After selecting right plan for you we will finish processing loan and will prepare you to close . Your new home awaits.</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="best-mortgage-rates">
                                <h3>BEST MORTGAGE RATES</h3>
                                <MortgageRate/>
                                {/* <ul className="best-mortgage-list">
                                    <li>
                                        <div className="mortgage-inner"> 
                                            <p>Lowest Purchase 5-YR Variable</p>
                                            <h4> <img src={adImage8} alt=""/>4.4%</h4>
                                            <Button variant="contained" color="primary">
                                                Get this rate
                                            </Button>
                                            <Button variant="contained" color="secondary">
                                                Compare
                                            </Button>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="mortgage-inner"> 
                                            <p>Lowest Purchase 5-YR Variable</p>
                                            <h4> <img src={adImage8} alt=""/> 4.95%</h4>
                                            <Button variant="contained" color="primary">
                                                Get this rate
                                            </Button>
                                            <Button variant="contained" color="secondary">
                                                Compare
                                            </Button>
                                        </div>
                                    </li>
                                </ul> */}
                            </div>
                        </Grid>
                        <Grid container item lg={4} md={4}>
                            {/* Apply form */}
                            <ApplyForm props={agentDetail}/>
                            <Button variant="contained" className="calculators-btn" color="secondary">
                                Calculators
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </section>
            {/* Testimonial Section Start */}
            <Testimonials />
            {/* Testimonial Section End */}
            {/* Partner Logo Start*/}
            {/* <PartnerLogo /> */}
            {/* Partner Logo End*/}
            {/* partner logo Start*/}
            {/* <Partner /> */}
            {/* partner logo End*/}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={`message_agent_modal ${classes.modal}`}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <div className="contact-form-wrap">
                            <h2>Book Appointment with {agentDetail.firstname} {agentDetail.lastname}</h2>
                            <div className="form-outer-wrap">
                                <form className=""   autoComplete="off">
                                    <div className='form-outer'>
                                        <TextField id="" label="Agent" value={`${agentDetail.firstname} ${agentDetail.lastname}`}  />
                                        <FormControl className="">
                                            <InputLabel id="">Mortgage request</InputLabel>
                                            <Select
                                                labelId=""
                                                id=""
                                                value=""
                                                required
                                            >
                                                <MenuItem value='purchase'>Purchase</MenuItem>
                                                <MenuItem value='refinance'>Refinance</MenuItem>
                                                <MenuItem value='renewalSwitch'>Renewal/Switch</MenuItem>
                                                <MenuItem value='homeEquityLine'>Home Equity Line</MenuItem>
                                            </Select>
                                            <p className="cs-error">Required</p>
                                        </FormControl>
                                        <TextField id="" label="Your Name" required />
                                        <p className="cs-error">Required</p>
                                        <TextField id="" label="Your Email" required /> 
                                        <p className="cs-error">Required</p>
                                        <TextField id="" label="Your Phone" required /> 
                                        <p className="cs-error">Required</p>
                                        <TextField id="" label="Address" />  
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                required
                                                    name="checkedB"
                                                    color="primary"
                                                />
                                            }
                                            label="I agree with terms & conditions and privacy policy"
                                        />
                                        <p className="cs-error text-center">Required</p>
                                        <Button variant="contained" color="primary">
                                            Book
                                        </Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </>
    )
}

export default AgentsDirectoryDetails