import React, { useState } from "react";
import {Typography,Button,Stepper,Step,StepLabel,Grid} from "@mui/material/";
import { styled  }  from '@mui/system';
import {useForm,FormProvider} from "react-hook-form";
import {chatrobot}from "../../core/Images";
import { NavLink } from "react-router-dom";
import StepOne from "./steps/StepOne";
import StepTwo from "./steps/StepTwo";
import StepThree from "./steps/StepThree";
import StepFour from "./steps/StepFour";
import StepFive from "./steps/StepFive";
import StepSix from "./steps/StepSix";
import StepSeven from "./steps/StepSeven";
import StepEight from "./steps/StepEight";
import StepNine from "./steps/StepNine";
import StepTen from "./steps/StepTen";
import request from "../../api/public/request";
import {useParams, useNavigate, Link, useSearchParams} from 'react-router-dom';
import swal from 'sweetalert';
// const useStyles = styled ((theme) => ({
//     button: {
//         marginRight: theme.spacing(1),
//     },
// }));
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css"; 
import MuiDialogContent from '@mui/material/DialogContent'; 
import CloseIcon from '@mui/icons-material/Close'; 
import StarIcon from '@mui/icons-material/Star';
import "../../components/popup/popup-common.scss";
import "../../components/popup/QuickFormCompletion.scss";
import CheckIcon from '@mui/icons-material/Check'; 

// import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide'; 
import IconButton from '@mui/material/IconButton';
import Cookies from 'universal-cookie';
import axios from 'axios';
// import { useNavigate  } from 'react-router-dom';
import {
    GoogleSvg, 
    ElfSight,
  } from "../../core/ImgsesSvg";


function getSteps() {
    return ["step-1","step-2","step-3","step-4","step-5","step-6","step-8","step-9","step-10"];
}

const StepWrapper = (props) => {

    let classes = `step-com-main ${props.className}`;
    return (<div className={classes} >{props.children}</div>);
}

const StepOneForm = (props) => {

    return (
        <StepWrapper className="steps1 steps-animate">
           <StepOne {...props}/>
        </StepWrapper>
    );
}

const StepTwoForm = (props) => {

    return (
        <StepWrapper className="steps2 steps-animate">
           <StepTwo {...props} />
        </StepWrapper>
    );

}

const StepThreeForm = (props) => {

    return (
        <StepWrapper className="steps3 steps-animate">
           <StepThree {...props}/>
        </StepWrapper>
    );
}

const StepFourForm = (props) => {

    return (
        <StepWrapper className="steps4 steps-animate">
          <StepFour {...props} />
        </StepWrapper>
    );
}

const StepFiveForm = (props) => {

    return (
        <StepWrapper className="steps5 steps-animate">
         <StepFive {...props} />
        </StepWrapper>
    );
}

const StepSixForm = (props) => {

    return (
        <StepWrapper className="steps6 steps-animate">
          <StepSix {...props}/>
        </StepWrapper>
    );
}
const StepSevenForm = (props) => {

    return (
        <StepWrapper className="steps7 steps-animate">
          <StepSeven {...props} />
        </StepWrapper>
    )
}
const StepEightForm = (props) => {

    return (
        <StepWrapper className="steps8 steps-animate">
           <StepEight {...props}/>
        </StepWrapper>
    )
}
const StepNineForm = (props) => {

    return (
        <StepWrapper className="steps9 steps-animate">
           <StepNine {...props} />
        </StepWrapper>
    )
}

const StepTenForm = (props) => {

    return (
        <StepWrapper className="steps10 steps-animate">
           <StepTen {...props} />
        </StepWrapper>
    )
}

function getStepContent(props) {

    const { activeStep: step } = props;

    switch (step) {
        case 0:
            return <StepOneForm {...props} />;
        case 1:
            return <StepTwoForm {...props} />;
        case 2:
            return <StepThreeForm {...props} />;
        case 3:
            return <StepFourForm {...props} />;
        case 4:
            return <StepFiveForm  {...props} />;
        case 5:
            return <StepSixForm {...props}/>;
        case 6:
            return <StepEightForm {...props}/>;
        case 7:
            return <StepNineForm {...props}/>;
        case 8:
            return <StepTenForm {...props}/>;
        case 9:
            return <StepTenForm {...props}/>;
        default:
            return "unknown step";
    }
}

const LinearStepper = () => {
    const cookies = new Cookies();
    // const classes = useStyles();

    const [searchParams] = useSearchParams();
    const utm_source = cookies.get('utm_source');
    const utm_medium = cookies.get('utm_medium');
    const utm_campaign = cookies.get('utm_campaign');
    const utm_content = cookies.get('utm_content');
    const utm_keyword = cookies.get('utm_keyword');
    const utm_matchtype = cookies.get('utm_matchtype');
    const campaign_id = cookies.get('campaign_id');
    const ad_group_id = cookies.get('ad_group_id');
    const ad_id = cookies.get('ad_id');
    const gclid = cookies.get('gclid');

    let navigate = useNavigate(); 
    const routeChange = () =>{ 
      let path = `/mortgage-rates/`; 
      navigate(path);
    }
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
      };

      const [open1, setOpen1] = React.useState(false);
      const handleClickOpen1 = () => {
          setOpen1(true);
        };
    
      const handleClose1 = () => {
        setOpen1(false);
      };
    var options=  {
        item:2,
        className:"owl-theme",
        margin:20,
        loop:true,
        responsiveClass: true,
        autoplay:true,
        dots:false,
        nav:true,
        responsive: {
          0: {
              items: 1,
          },
          768: {
              items: 2,
          },
          960: {
            items: 1,
          },
          992: {
              items: 2,
          }
        }
      }

    const [activeStep, setActiveStep]      = useState(0);
    const [position, setPosition]          = useState(0);
    const [purchasePrice,setPurchasePrice] = useState('');
    const [agentSlug,setAgentSlug] = useState('');
    const {slug} = useParams();
    const steps = getSteps();
    const methods = useForm({

        defaultValues: {

            "mortgageType"   : "",
            "intended"       : "",
            "purchase_price" : "",
        }
    });

    const handleBack = () => {
        setActiveStep((step) => (step - 1));
    };
    const [agentDetail, setAgentDetail] = React.useState({});

    // console.log(cookies.get('agentSite'));
    // console.log('cookieAgentSlug', cookies.get('cookieAgentSlug'));
    const data = {
        type: 'single',
        Authorization: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoiQWJoaXNoZWsgUHVyaSIsIm5hbWUiOiJSZWFjdCBSUyIsInBhc3N3b3JkIjpudWxsLCJBUElfVElNRSI6MTY3NjY0MjQyNn0.kcYNItsCPFOtwPIgffGx1Zf-BGDN_Vsx-r0vnGcucOs',
        slug: slug
      };
    const getAgentsData = async () => {
        if(slug != undefined){
            axios.post(`https://crm.rateshop.ca/ReactController/getStaffSlugs`, { data })
                .then(res => {
                if(res.data.status != 200){
                    navigate('/404', { replace: true });
                }else{
                    setAgentDetail(res.data.data[0]);
                }
            })
        }
    }

    React.useEffect(() => {
        getAgentsData();
    }, []);
    const handleNext = (data) => {
        if(cookies.get('agentSite') == 1){
            setAgentSlug(cookies.get('cookieAgentSlug'));
        }else{
            setAgentSlug(slug);
            // getAgentsData();
        }
        const apiData = {
            Authorization: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoiQWJoaXNoZWsgUHVyaSIsIm5hbWUiOiJSZWFjdCBSUyIsInBhc3N3b3JkIjpudWxsLCJBUElfVElNRSI6MTY3NjY0MjQyNn0.kcYNItsCPFOtwPIgffGx1Zf-BGDN_Vsx-r0vnGcucOs',
            application_type : 'quick',
            first_name : data.first_name,
            email: data.email,
            number: data.phone_number,
            morge_type: data.mortgageType,
            slug: agentSlug,
            email_link: data.email_link,
            your_intended: ("intended" in data)?data.intended:'',
            currentPropVal:("purchase_price" in data)? data.purchase_price:'',
            estimated: ("purchase_price" in data)?data.purchase_price:'',
            credit_score: data.credit_score,
            rewards: ("reward_type" in data)?data.reward_type:'',
            province: ("province" in data)?data.province:'',
            lender: ("country1" in data)?data.country1.title:'',
            otherLender:'',
            firstMortgage: ("first_mortgage" in data)?data.first_mortgage:'',
            secondMortgage: ("second_mortgage" in data)?data.second_mortgage:'',
            lineOfCredit: ("line_of_credit" in data)?data.line_of_credit:'',
            marbleRegId: 0,
            marbleOppId: 0,
            oppCreditTier: 0,
            pageType: '',
            narcity: '',
            facebook: '',
            google: '',
            commercialLanding: '',
            homeEquityLanding: '',
            getQuoteLanding: '',
            has_referral: ("has_referral" in data)?data.has_referral:'',
            referral_first_name: ("referral_first_name" in data)?data.referral_first_name:'',
            referral_last_name: ("referral_last_name" in data)?data.referral_last_name:'',
            currentUrl: window.location.href,
            utm_source: utm_source,
            utm_medium: utm_medium,
            utm_campaign: utm_campaign,
            utm_content: utm_content,
            utm_keyword: utm_keyword,
            utm_matchtype: utm_matchtype,
            campaign_id: campaign_id,
            ad_group_id: ad_group_id,
            ad_id: ad_id,
            gclid: gclid,
        }
        if(activeStep <= 7)
        {
           setActiveStep((step) => (step + 1));
        }
        else
        {
            request('POST','https://crm.rateshop.ca/ReactController/saveQuickApplication',apiData)
            .then((response) => {
                if(response.data.status == 200){
                    setActiveStep((step) => 10);
                    // swal("Good job!", "Application Submitted", "success")
                    // navigate('/mortgage-rates', { replace: true });
                    setOpen(true);
                }else{
                    swal("OOPS!", "Email or Mobile Number already exists.", "error");
                }
                // console.log(response)
            })
            .catch((error) => {
 
                // console.log(error);
            })
          
        }
    };

    return (
        
        <div className="quick-form-inner">
            {/* <div className="quick-steps">
                {activeStep + 1} of 10
           </div> */}
           {/* <QuickFormCompletion/> */}
           <Grid container className="step-bottom mobile-chat">
                <Grid sm={12} md={12} lg={12} item className="quick-chat">
                    <div className="chat-massage">
                        {/* <NavLink to="/"> */}
                            <img src={chatrobot} alt="chat robot"/>
                        {/* </NavLink> */}
                        <div className="chat-messgae-text"> Hi👋 I'm MAT! Your Mortgage Approval Tool. 🏠 Want the Lowest Mortgage Rate Guarantee?✨</div>
                    </div>
                </Grid>                
            </Grid>
            <Stepper alternativeLabel activeStep={activeStep} className="steps-detail">
                {
                    steps.map((step, index) => {

                        return (
                            <Step key={index} className="quick-step-count">
                                <StepLabel >
                                    <span className="step-span"></span>
                                </StepLabel>
                            </Step>
                        );
                    })
                }
            </Stepper>
            {activeStep === steps.length+1 ? (
                <Typography variant="h4" align="center">
                    Thank You
                </Typography>
                

            ) : (
                <>   
                    <FormProvider {...methods}>
                        <form onSubmit={methods.handleSubmit(handleNext)}>
                            
                            {getStepContent({ activeStep, position, setPosition,purchasePrice,setPurchasePrice,setActiveStep,handleNext })}
                            <Grid container className="step-bottom">
                                <Grid sm={12} md={6} lg={6} item  className="  quick-chat desktop-chat">
                                    <div className="chat-massage">
                                        {/* <NavLink to="/"> */}
                                            <img src={chatrobot} alt="chat robot"/>
                                        {/* </NavLink> */}
                                        <div className="chat-messgae-text"> Hi👋 I'm MAT! Your Mortgage Approval Tool. 🏠 Want the Lowest Mortgage Rate Guarantee?✨</div>
                                    </div>
                                </Grid>
                                <Grid sm={12} md={6} lg={6} item  className="   prev-next-btn">
                                    {
                                        activeStep !== 0 && <Button
                                            // className={classes.button}
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                            variant="contained"
                                            color="primary"
                                        >
                                            back
                                        </Button>
                                    }

                                    <Button
                                        // className={classes.button}
                                        variant="contained"
                                        color="secondary"
                                        // onClick={handleNext}
                                        type="submit"

                                    >
                                        {activeStep === steps.length - 1 ? "Finish" : "Next"}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </FormProvider>
                </>
            )}

<>
    <div className=''>
            {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                Completion form
            </Button> */}
            <Dialog 
             open={open} 
            //  TransitionComponent={Transition}
            //  keepMounted
            //  onClose={handleClose}
             aria-describedby="alert-dialog-slide-description" 
                className="modal-dialog completion-dialog" fullWidth   maxWidth="md">
               
                <DialogContent dividers className='dialog-body'>
                    <div className='dialog-inner quick-completion'>
                        {/* <div className='comp-percentage'>
                            <span>Awesome</span>
                            <div className='line'>
                                <span style={{width:'75%'}}></span>
                            </div>
                            <p>Our technology searches for applicable lenders offering  the lowest rates in real time</p>
                        </div> */}
                         <div className='comp-percentage'>
                            <p>75% Complete</p> 
                            <div className='line'>
                                <span style={{width:'75%'}}></span>
                            </div> 
                        </div>
                        <div className='heading'>
                            <h2>Great, You're signed up for a free rate quote and  <img src="https://mbanc.ca/wp-content/uploads/2023/05/marble-logo.png" alt="img" />  Credit Monitoring.</h2>
                        </div>
                        <ul className='quick-ul'>
                            <li>
                                <img src="https://www.mbanc.ca/wp-content/uploads/2022/02/image_2022_02_14T22_34_37_547Z.png" alt="img" />
                                <span><CheckIcon/></span>
                            </li>
                            <li>
                                <img src="https://www.mbanc.ca/wp-content/uploads/2022/02/image_2022_02_14T22_34_37_549Z.png" alt="img" />     
                                <span><CheckIcon/></span>
                            </li>
                            <li>
                                <img src="https://www.mbanc.ca/wp-content/uploads/2022/02/image_2022_02_14T22_34_37_546Z.png" alt="img" />      
                                <span><CloseIcon/></span>
                            </li>
                        </ul> 
                        <div className='heading'>
                            <h2 className="just-one-step mb-4">In just One more step, get access to your personalized mortgage rates! </h2>
                        </div>
                        <div className='complete-save-btn'>
                            <Button className='green-btn' variant="contained" color="secondary" onClick={handleClickOpen1}>Schedule an Appointment</Button>
                            {(agentDetail.full_app_link == '' || agentDetail.full_app_link == undefined)?'':
                                <Link to={agentDetail.full_app_link} className="cs-btn  MuiButton-contained MuiButton-containedSecondary">Complete Full Application Now</Link>
                            }
                        </div>
                        <div className='user-reviews'>
                            <OwlCarousel
                                {...options}
                            >
                                <div className='user-review-wrap'>
                                    <div className='user-profile'>
                                        <GoogleSvg/>      
                                        <div className='user-name'>
                                            <h3> <a href="https://www.trustpilot.com/users/642df2172271270013cbe398">Ram Prasad</a></h3>
                                            <div className='user-rating'>
                                                <a href="javascript:void(0);"><StarIcon/></a>
                                                <a href="javascript:void(0);"><StarIcon/></a>
                                                <a href="javascript:void(0);"><StarIcon/></a>
                                                <a href="javascript:void(0);"><StarIcon/></a>
                                                <a href="javascript:void(0);"><StarIcon/></a>
                                            </div>
                                        </div>                  
                                    </div>
                                    <p>Being a first time home buyer, we had 101 questions related to mortgage and related stuffs. Ankur and Sarah from Rateshop guided us throughout the journey providing valuable guidance. They were able to secure a good mortgage rate too. We are endlessly grateful to have you as our agent and couldn't be happier with the way everything worked out. Would recommend!👍👍</p>
                                    {/* <div className='post-on-google'>
                                        <GoogleSvg/>
                                        <p>Post on <a href='javascript:void(0);'>Google</a></p>
                                    </div> */}
                                </div>
                                <div className='user-review-wrap'>
                                    <div className='user-profile'>
                                       
                                    <GoogleSvg/>            
                                        <div className='user-name'>
                                            <h3> <a href="https://www.trustpilot.com/users/6421b8efe06d7400123a96ce">Evelien Mraz</a></h3>
                                            <div className='user-rating'>
                                                <a href="javascript:void(0);"><StarIcon/></a>
                                                <a href="javascript:void(0);"><StarIcon/></a>
                                                <a href="javascript:void(0);"><StarIcon/></a>
                                                <a href="javascript:void(0);"><StarIcon/></a>
                                                <a href="javascript:void(0);"><StarIcon/></a>
                                            </div>
                                        </div>                  
                                    </div>
                                    <p>Sarah was incredible! She was there every step of the way, was so knowledgeable and answered every single question we had! She has such a big network that she was able to help us and guide us through the whole process! Thank you Sarah! You made our dream come true!</p>
                                    {/* <div className='post-on-google'>
                                        <GoogleSvg/>
                                        <p>Post on <a href='javascript:void(0);'>Google</a></p>
                                    </div> */}
                                </div>
                                <div className='user-review-wrap'>
                                    <div className='user-profile'>
                                        
                                    <GoogleSvg/>             
                                        <div className='user-name'>
                                            <h3> <a href="https://www.trustpilot.com/users/6276aff91b82d500111a51ac">	Tafiqul Abu Mohammad</a></h3>
                                            <div className='user-rating'>
                                                <a href="javascript:void(0);"><StarIcon/></a>
                                                <a href="javascript:void(0);"><StarIcon/></a>
                                                <a href="javascript:void(0);"><StarIcon/></a>
                                                <a href="javascript:void(0);"><StarIcon/></a>
                                                <a href="javascript:void(0);"><StarIcon/></a>
                                            </div>
                                        </div>                  
                                    </div>
                                    <p>Ariful was very caring and one of the best broker I have ever worked with. Thank you for all your hard work on my file Ariful.</p>
                                    {/* <div className='post-on-google'>
                                        <GoogleSvg/>
                                        <p>Post on <a href='javascript:void(0);'>Google</a></p>
                                    </div> */}
                                </div>
                                <div className='user-review-wrap'>
                                    <div className='user-profile'>
                                       
                                    <GoogleSvg/>            
                                        <div className='user-name'>
                                            <h3> <a href="javascript:void(0);">Fazila Nazia</a></h3>
                                            <div className='user-rating'>
                                                <a href="javascript:void(0);"><StarIcon/></a>
                                                <a href="javascript:void(0);"><StarIcon/></a>
                                                <a href="javascript:void(0);"><StarIcon/></a>
                                                <a href="javascript:void(0);"><StarIcon/></a>
                                                <a href="javascript:void(0);"><StarIcon/></a>
                                            </div>
                                        </div>                  
                                    </div>
                                    <p>Sami Juneja is a very professional mortgage agent. We had home purchase closing and he was able to arrange a mortgage with a very competitive rate. Really appreciate his hard work during whole process from start to getting us our home.</p>
                                    {/* <div className='post-on-google'>
                                        <GoogleSvg/>
                                        <p>Post on <a href='javascript:void(0);'>Google</a></p>
                                    </div> */}
                                </div>

                                <div className='user-review-wrap'>
                                    <div className='user-profile'>
                                       
                                    <GoogleSvg/>            
                                        <div className='user-name'>
                                            <h3> <a href="https://www.trustpilot.com/users/6257743a5610220012cc2586">Megan Mousavi</a></h3>
                                            <div className='user-rating'>
                                                <a href="javascript:void(0);"><StarIcon/></a>
                                                <a href="javascript:void(0);"><StarIcon/></a>
                                                <a href="javascript:void(0);"><StarIcon/></a>
                                                <a href="javascript:void(0);"><StarIcon/></a>
                                                <a href="javascript:void(0);"><StarIcon/></a>
                                            </div>
                                        </div>                  
                                    </div>
                                    <p>Chantel is nothing short of exceptional. I have never met someone who is as responsive, enthusiastic and relentlessly hardworking as Chantel. She helped me purchase my first home and I am very grateful for her guidance and professionalism during this stressful time! Chantel was able to find the exact rate, down payment and timeline I was requesting. I highly recommend her to all home buyers!</p>
                                    {/* <div className='post-on-google'>
                                        <GoogleSvg/>
                                        <p>Post on <a href='javascript:void(0);'>Google</a></p>
                                    </div> */}
                                </div>

                                <div className='user-review-wrap'>
                                    <div className='user-profile'>
                                       
                                    <GoogleSvg/>            
                                        <div className='user-name'>
                                            <h3> <a href="https://www.trustpilot.com/users/6399f066221b410013d822a0">Elizabeth Barnett</a></h3>
                                            <div className='user-rating'>
                                                <a href="javascript:void(0);"><StarIcon/></a>
                                                <a href="javascript:void(0);"><StarIcon/></a>
                                                <a href="javascript:void(0);"><StarIcon/></a>
                                                <a href="javascript:void(0);"><StarIcon/></a>
                                                <a href="javascript:void(0);"><StarIcon/></a>
                                            </div>
                                        </div>                  
                                    </div>
                                    <p>The expertise, guidance and patience of the Rate Shop Team is impeccable. I have now had the pleasure of doing business with Ali twice and I look forward to future experiences. Ali takes all finances into consideration before recommending an excellent solution. He truly takes the 'stress' of off the client. Thank-you for all your teams hard work.</p>
                                    {/* <div className='post-on-google'>
                                        <GoogleSvg/>
                                        <p>Post on <a href='javascript:void(0);'>Google</a></p>
                                    </div> */}
                                </div>

                                <div className='user-review-wrap'>
                                    <div className='user-profile'>
                                       
                                    <GoogleSvg/>            
                                        <div className='user-name'>
                                            <h3> <a href="https://www.trustpilot.com/users/626dd3abb4efc0001201a975">Nick</a></h3>
                                            <div className='user-rating'>
                                                <a href="javascript:void(0);"><StarIcon/></a>
                                                <a href="javascript:void(0);"><StarIcon/></a>
                                                <a href="javascript:void(0);"><StarIcon/></a>
                                                <a href="javascript:void(0);"><StarIcon/></a>
                                                <a href="javascript:void(0);"><StarIcon/></a>
                                            </div>
                                        </div>                  
                                    </div>
                                    <p>Sarah did an amusing job handling my reverse mortgage application and getting everything approved. From the initial contact thru a reliable source and her patience and never pressuring as I had taken several months deciding on this big decision. When I finally decided to move forward Sarah was always available to answer any questions I had and ensure I was headed in the right direction explaining every detail in great lengths. Made me and my wife feel very comfortable that we made the right decision. Sarah went out of her way to find us proper lawyers to handle the legal aspect of this process. In the end she followed up to ensure everything was completed with the lawyers and we were happy.</p>
                                    {/* <div className='post-on-google'>
                                        <GoogleSvg/>
                                        <p>Post on <a href='javascript:void(0);'>Google</a></p>
                                    </div> */}
                                </div>

                                <div className='user-review-wrap'>
                                    <div className='user-profile'>
                                       
                                    <GoogleSvg/>            
                                        <div className='user-name'>
                                            <h3> <a href="https://www.trustpilot.com/users/6257743a5610220012cc2586">Megan Mousavi</a></h3>
                                            <div className='user-rating'>
                                                <a href="javascript:void(0);"><StarIcon/></a>
                                                <a href="javascript:void(0);"><StarIcon/></a>
                                                <a href="javascript:void(0);"><StarIcon/></a>
                                                <a href="javascript:void(0);"><StarIcon/></a>
                                                <a href="javascript:void(0);"><StarIcon/></a>
                                            </div>
                                        </div>                  
                                    </div>
                                    <p>Chantel is nothing short of exceptional. I have never met someone who is as responsive, enthusiastic and relentlessly hardworking as Chantel. She helped me purchase my first home and I am very grateful for her guidance and professionalism during this stressful time! Chantel was able to find the exact rate, down payment and timeline I was requesting. I highly recommend her to all home buyers!</p>
                                    {/* <div className='post-on-google'>
                                        <GoogleSvg/>
                                        <p>Post on <a href='javascript:void(0);'>Google</a></p>
                                    </div> */}
                                </div>

                                <div className='user-review-wrap'>
                                    <div className='user-profile'>
                                       
                                    <GoogleSvg/>            
                                        <div className='user-name'>
                                            <h3> <a href="https://www.trustpilot.com/users/5cc336023e992b884431e0fe">Famous Baby</a></h3>
                                            <div className='user-rating'>
                                                <a href="javascript:void(0);"><StarIcon/></a>
                                                <a href="javascript:void(0);"><StarIcon/></a>
                                                <a href="javascript:void(0);"><StarIcon/></a>
                                                <a href="javascript:void(0);"><StarIcon/></a>
                                                <a href="javascript:void(0);"><StarIcon/></a>
                                            </div>
                                        </div>                  
                                    </div>
                                    <p>Awesome staff, made our experience relaxed and fun</p>
                                    {/* <div className='post-on-google'>
                                        <GoogleSvg/>
                                        <p>Post on <a href='javascript:void(0);'>Google</a></p>
                                    </div> */}
                                </div>
                            </OwlCarousel>
                        </div>
                      
                        <div className='quick-appoitment'>
                            <p>Our Mortgage Advisors are focused on Your Needs! Lowest Rates the first time, every time.</p>
                            {/* <Button variant="contained" color="secondary">Call Now 1-800-725-9946</Button> */}
                            <a className="cs-call-btn" href="tel:1-800-725-9946">Call Now 1-800-725-9946</a>
                            {/* <p>Get Your Lowest Rate <a href="#">Book An Appointment</a></p> */}
                        </div>
                    </div>
                </DialogContent>
               
            </Dialog>

            
        
        </div>    <Dialog 
             open={open1} 
            //  keepMounted
             onClose={handleClose1}
            //  aria-describedby="alert-dialog-slide-description"
            fullScreen >
                 <IconButton className='cs-close-btn'
              edge="start"
              color="inherit"
              onClick={handleClose1}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
               
                <DialogContent  className='dialog-body quick-form-iframe'>
             
                <iframe src="https://api.leadconnectorhq.com/widget/booking/GQoTxBNtn65vFhTBcR2V" scrolling="yes" id="GQoTxBNtn65vFhTBcR2V_1687803758835"></iframe>
                </DialogContent>
               
            </Dialog>
    </>
        </div>
        
    );
}

export default LinearStepper;