import React from 'react';
import { Container, Grid } from "@mui/material";

import "./location.scss";
import { Link } from 'react-router-dom';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { posts } from '../../../config/posts-2';

const LocationCarousel = () => {
    var option3 = {
        items: 4,
        responsiveClass: true,
        dots: false,
        autoplay: true,
        nav: false,
        autoWidth: false,
        loop: true,
        margin:20,  
        responsive:{
            0:{
                items:1, 
            },
            600:{
                items:2, 
            },
            992:{
                items:3, 
            },
            1199:{
                items:4, 
            }
        }
      }
    return (
        <section className="location-carousel">
            <Container>
                <div className="heading text-center">
                    <h3><span>Explore</span> Our Mortgages</h3>
                </div> 
                <div className="location-carousel-inner">
          
                <OwlCarousel
            {...option3}
          >
      {posts.map(post => (
         <div className="item">
        <div key={post.id}>
          {post.video && <video src={post.video} autoPlay playsInline muted />}
          {post.bannerImage && <img src={post.bannerImage} alt={post.title} />} 
          <h2><Link to={post.slug}>{post.title1}</Link></h2>
        </div>
        </div>
      ))}

            
                                        
          </OwlCarousel>
                </div>
            </Container>
        </section>
    );
}

export default LocationCarousel;