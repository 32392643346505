import React from 'react';
import axios from 'axios';
import Helmet from 'react-helmet';import Typography from '@mui/material/Typography';
const DynamicSitemap = () => {
const [links, setLinks] = React.useState([]);

  React.useEffect(() => {

    const data = {
    type: 'full',
    Authorization: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoiQWJoaXNoZWsgUHVyaSIsIm5hbWUiOiJSZWFjdCBSUyIsInBhc3N3b3JkIjpudWxsLCJBUElfVElNRSI6MTY3NjY0MjQyNn0.kcYNItsCPFOtwPIgffGx1Zf-BGDN_Vsx-r0vnGcucOs'
    };


    axios.post(`https://crm.rateshop.ca/ReactController/getStaffSlugs`, { data })
        .then(res => {
            setLinks(res.data.data)
            console.log(res.data.data);
    }).catch(error => {
        console.error(error);
      });
  }, []); 
  

  const generateSitemap = () => {
    var xml = `<?xml version="1.0" encoding="UTF-8"?>
    <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
    <url>
   <loc>https://www.rateshop.ca/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-payment-calculator</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/cost-closing-calculator</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/affordability-calculator</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/cmhc-insurance-calculator</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/land-transfer-tax-calculator</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/credit-score</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/apply/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/alberta/calgary</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/alberta/fort-mcMurray</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/alberta/cochrane</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/alberta/calgary</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/alberta/medicine-hat</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/alberta/okotoks</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/alberta/red-deer</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/alberta/grande-prairie</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/alberta/fort-saskatchewan</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/alberta/lethbridge</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/alberta/spruce-grove</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/alberta/lloydminster</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/alberta/airdrie</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/alberta/leduc</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/british-columbia/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/british-columbia/nanaimo</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/british-columbia/courtenay</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/british-columbia/victoria</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/british-columbia/kamloops</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/british-columbia/campbell-river</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/british-columbia/kelowna</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/british-columbia/chilliwack</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/british-columbia/penticton</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/british-columbia/abbotsford</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/british-columbia/prince-george</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/british-columbia/mission</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/british-columbia/white-rock</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/british-columbia/vernon</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/british-columbia/parksville</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
 
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/new-brunswick/moncton</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/new-brunswick/fredericton</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/new-brunswick/saint-john</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/nova-scotia/halifax</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/nova-scotia/cape-breton</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/nova-scotia/sydney</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/ontario/toronto</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/ontario/windsor</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/ontario/kingston</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/ontario/peterborough</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/ontario/bowmanville-newcastle</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/ontario/chatham</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/ontario/orangeville</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/ontario/keswick-elmhurst-beach</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/ontario/hamilton</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/ontario/st.-catharines</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/ontario/milton</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/ontario/belleville</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/ontario/north-bay</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/ontario/georgetown</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/ontario/orillia</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/ontario/bolton</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/ontario/kitchener</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/ontario/barrie</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/ontario/brantford</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/ontario/sarnia</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/ontario/cornwall</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/ontario/bradford</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/ontario/stratford</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>

<url>
   <loc>https://www.rateshop.ca/new-brunswick/fredericton/investing-in-private-mortgage/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>

<url>
   <loc>https://www.rateshop.ca/mortgage-rates/alberta/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>

<url>
   <loc>https://www.rateshop.ca/mortgage-rates/ontario/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>

<url>
   <loc>https://www.rateshop.ca/mortgage-rates/new-brusnwick/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>


<url>
   <loc>https://www.rateshop.ca/mortgage-rates/quebec/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>

<url>
   <loc>https://www.rateshop.ca/mortgage-rates/prince-edward-island/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/saskatchewan/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>

<url>
   <loc>https://www.rateshop.ca/investing-in-private-mortgage/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>

<url>
   <loc>https://www.rateshop.ca/ontario/toronto/investing-in-private-mortgage/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>

<url>
   <loc>https://www.rateshop.ca/ontario/ottawa/investing-in-private-mortgage/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>


<url>
   <loc>https://www.rateshop.ca/ontario/hamilton/investing-in-private-mortgage/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>

<url>
   <loc>https://www.rateshop.ca/alberta/calgary/investing-in-private-mortgage/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>

<url>
   <loc>https://www.rateshop.ca/alberta/edmonton/investing-in-private-mortgage/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>

<url>
   <loc>https://www.rateshop.ca/alberta/red-deer/investing-in-private-mortgage/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>

<url>
   <loc>https://www.rateshop.ca/british-columbia/vancouver/investing-in-private-mortgage/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>

<url>
   <loc>https://www.rateshop.ca/british-columbia/victoria/investing-in-private-mortgage/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>

<url>
   <loc>https://www.rateshop.ca/british-columbia/kelowna/investing-in-private-mortgage/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>

<url>
   <loc>https://www.rateshop.ca/new-brunswick/moncton/investing-in-private-mortgage/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/ontario/midland</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/ontario/london</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/ontario/guelph</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/ontario/thunder</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/nova-scotia/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/ontario/welland-pelham</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/ontario/woodstock</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/ontario/stouffville</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/ontario/innisfil</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/ontario/ottawa–gatineau</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/ontario/oshawa</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/ontario/kanata</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/ontario/sudbury</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/ontario/sault-ste.-marie</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/ontario/st.-thomas</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/ontario/leamington</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/ontario/timmins</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/prince-edward-island/charlottetown</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/quebec/montreal</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/quebec/saint-jérôme</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/quebec/beloeil</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/quebec/shawinigan</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/quebec/quebec-city</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/quebec/saint-jean-sur-richelieu</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/quebec/saint-hyacinthe</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/quebec/rimouski</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/quebec/sherbrooke</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
<loc>https://www.rateshop.ca/private-mortgage/</loc>
<lastmod>2023-06-08T16:23:11+01:00</lastmod>
<priority>1.0</priority>
</url>
<url>
<loc>https://www.rateshop.ca/ontario/toronto/private-mortgage/</loc>
<lastmod>2023-06-08T16:23:11+01:00</lastmod>
<priority>1.0</priority>
</url>
<url>
<loc>https://www.rateshop.ca/ontario/ottawa/private-mortgage/</loc>
<lastmod>2023-06-08T16:23:11+01:00</lastmod>
<priority>1.0</priority>
</url>
<url>
<loc>https://www.rateshop.ca/ontario/hamilton/private-mortgage/</loc>
<lastmod>2023-06-08T16:23:11+01:00</lastmod>
<priority>1.0</priority>
</url>
<url>
<loc>https://www.rateshop.ca/alberta/calgary/private-mortgage/</loc>
<lastmod>2023-06-08T16:23:11+01:00</lastmod>
<priority>1.0</priority>
</url>
<url>
<loc>https://www.rateshop.ca/alberta/edmonton/private-mortgage/</loc>
<lastmod>2023-06-08T16:23:11+01:00</lastmod>
<priority>1.0</priority>
</url>
<url>
<loc>https://www.rateshop.ca/alberta/red-deer/private-mortgage/</loc>
<lastmod>2023-06-08T16:23:11+01:00</lastmod>
<priority>1.0</priority>
</url>
<url>
<loc>https://www.rateshop.ca/british-columbia/vancouver/private-mortgage/</loc>
<lastmod>2023-06-08T16:23:11+01:00</lastmod>
<priority>1.0</priority>
</url>
<url>
<loc>https://www.rateshop.ca/british-columbia/victoria/private-mortgage/</loc>
<lastmod>2023-06-08T16:23:11+01:00</lastmod>
<priority>1.0</priority>
</url>
<url>
<loc>https://www.rateshop.ca/british-columbia/kelowna/private-mortgage/</loc>
<lastmod>2023-06-08T16:23:11+01:00</lastmod>
<priority>1.0</priority>
</url>
<url>
<loc>https://www.rateshop.ca/new-brunswick/moncton/private-mortgage/</loc>
<lastmod>2023-06-08T16:23:11+01:00</lastmod>
<priority>1.0</priority>
</url>

<url>
   <loc>https://www.rateshop.ca/new-brunswick/fredericton/private-mortgage/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/quebec/châteauguay</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/quebec/joliette</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/quebec/sorel</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/quebec/trois-rivières</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/quebec/drummondville</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/quebec/victoriaville</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/quebec/saint-georges</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/quebec/chicoutimi-jonquière</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/quebec/granby</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/quebec/salaberry-de-valleyfield</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/saskatchewan/saskatoon</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/saskatchewan/regina</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/saskatchewan/prince-albert</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-rates/saskatchewan/moose-jaw</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-lender/parama-credit-union</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-lender/rmg</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-lender/mcap</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-lender/aveo</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-lender/manulife</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-lender/duca</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-lender/sbi</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-lender/home-equity-bank</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-lender/lighthouse-credit-union</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-lender/scotiabank</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-lender/community-trust</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-lender/strive-bank</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-lender/b2b-bank</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-lender/desjardins</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-lender/marathon-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-lender/vancouver-city</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-lender/equitable-bank</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-lender/first-national</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-lender/blueshore</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-lender/hsbc</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-lender/servus</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-lender/wealthone</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-lender/cmls-financials</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-lender/home-trust</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-lender/canadian-western-bank</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-lender/icici</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-lender/shinhan-bank</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-lender/atb</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-lender/bridgewater-bank</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/bridge-loans</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/reverse-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/power-of-sale-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/rural-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/renovation-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/debt-consolidation-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/mortgage-arrears</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/cmhc-residential-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/commercial-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/home-equity-loan</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/ontario/toronto/bridge-loans</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/ontario/toronto/reverse-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/ontario/toronto/power-of-sale-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/ontario/toronto/rural-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/ontario/toronto/renovation-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/ontario/toronto/debt-consolidation-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/ontario/toronto/mortgage-arrears</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/ontario/toronto/cmhc-residential-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/ontario/toronto/commercial-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/ontario/toronto/home-equity-loan</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/ontario/ottawa/bridge-loans</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/ontario/ottawa/reverse-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/ontario/ottawa/power-of-sale-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/ontario/ottawa/rural-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/ontario/ottawa/renovation-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/ontario/ottawa/debt-consolidation-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/ontario/ottawa/mortgage-arrears</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/ontario/ottawa/cmhc-residential-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/ontario/ottawa/commercial-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/ontario/ottawa/home-equity-loan</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/ontario/hamilton/bridge-loans</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/ontario/hamilton/reverse-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/ontario/hamilton/power-of-sale-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/ontario/hamilton/rural-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/ontario/hamilton/renovation-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/ontario/hamilton/debt-consolidation-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/ontario/hamilton/mortgage-arrears</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/ontario/hamilton/cmhc-residential-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/ontario/hamilton/commercial-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/ontario/hamilton/home-equity-loan</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/alberta/calgary/bridge-loans</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/alberta/calgary/reverse-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/alberta/calgary/power-of-sale-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/alberta/calgary/rural-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/alberta/calgary/renovation-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/alberta/calgary/debt-consolidation-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/alberta/calgary/mortgage-arrears</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/alberta/calgary/cmhc-residential-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/alberta/calgary/commercial-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/alberta/calgary/home-equity-loan</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/alberta/edmonton/bridge-loans</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/alberta/edmonton/reverse-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/alberta/edmonton/power-of-sale-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/alberta/edmonton/rural-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/alberta/edmonton/renovation-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/alberta/edmonton/debt-consolidation-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/alberta/edmonton/mortgage-arrears</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/alberta/edmonton/cmhc-residential-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/alberta/edmonton/commercial-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/alberta/edmonton/home-equity-loan</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/alberta/red-deer/bridge-loans</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/alberta/red-deer/reverse-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/alberta/red-deer/power-of-sale-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/alberta/red-deer/rural-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/alberta/red-deer/renovation-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/alberta/red-deer/debt-consolidation-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/alberta/red-deer/mortgage-arrears</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/alberta/red-deer/cmhc-residential-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/alberta/red-deer/commercial-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/alberta/red-deer/home-equity-loan</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/british-columbia/vancouver/bridge-loans</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/british-columbia/vancouver/reverse-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/british-columbia/vancouver/power-of-sale-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/british-columbia/vancouver/rural-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/british-columbia/vancouver/renovation-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/british-columbia/vancouver/debt-consolidation-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/british-columbia/vancouver/mortgage-arrears</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/british-columbia/vancouver/cmhc-residential-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/british-columbia/vancouver/commercial-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/british-columbia/vancouver/home-equity-loan</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/british-columbia/victoria/bridge-loans</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/british-columbia/victoria/reverse-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/british-columbia/victoria/power-of-sale-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/british-columbia/victoria/rural-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/british-columbia/victoria/renovation-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/british-columbia/victoria/debt-consolidation-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/british-columbia/victoria/mortgage-arrears</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/british-columbia/victoria/cmhc-residential-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/british-columbia/victoria/commercial-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/british-columbia/victoria/home-equity-loan</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/british-columbia/kelowna/bridge-loans</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/british-columbia/kelowna/reverse-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/british-columbia/kelowna/power-of-sale-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/british-columbia/kelowna/rural-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/british-columbia/kelowna/renovation-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/british-columbia/kelowna/debt-consolidation-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/british-columbia/kelowna/mortgage-arrears</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/british-columbia/kelowna/cmhc-residential-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/british-columbia/kelowna/commercial-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/british-columbia/kelowna/home-equity-loan</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/new-brunswick/moncton/bridge-loans</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/new-brunswick/moncton/reverse-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/new-brunswick/moncton/power-of-sale-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/new-brunswick/moncton/rural-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/new-brunswick/moncton/renovation-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/new-brunswick/moncton/debt-consolidation-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/new-brunswick/moncton/mortgage-arrears</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/new-brunswick/moncton/cmhc-residential-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/new-brunswick/moncton/commercial-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/new-brunswick/moncton/home-equity-loan</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/new-brunswick/fredericton/bridge-loans</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/new-brunswick/fredericton/reverse-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/new-brunswick/fredericton/power-of-sale-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/new-brunswick/fredericton/rural-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/new-brunswick/fredericton/renovation-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/new-brunswick/fredericton/debt-consolidation-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/new-brunswick/fredericton/mortgage-arrears</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/new-brunswick/fredericton/cmhc-residential-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/new-brunswick/fredericton/commercial-mortgage</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/new-brunswick/fredericton/home-equity-loan</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>
<url>
   <loc> https://ref.rateshop.ca/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>1.0</priority>
</url>`;
    links.forEach(link => {
    xml += `
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-payment-calculator/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/affordability-calculator/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/land-transfer-tax-calculator/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/cmhc-insurance-calculator/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/cost-closing-calculator/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/credit-score/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/alberta/calgary/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/alberta/fort-mcMurray/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/alberta/cochrane/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/alberta/medicine-hat/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/alberta/okotoks/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/alberta/red-deer/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/alberta/grande-prairie/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/alberta/fort-saskatchewan/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/alberta/lethbridge/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/alberta/spruce-grove/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/alberta/lloydminster/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/alberta/airdrie/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/alberta/leduc/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/ontario/toronto/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/ontario/windsor/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/ontario/kingston/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/ontario/peterborough/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/ontario/bowmanville-newcastle/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/ontario/chatham/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/ontario/orangeville/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/ontario/keswick-elmhurst-beach/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/ontario/hamilton/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/ontario/st.-catharines/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/ontario/milton/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/ontario/belleville/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/ontario/north-bay/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/ontario/georgetown/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/ontario/orillia/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/ontario/bolton/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/ontario/kitchener/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/ontario/barrie/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/ontario/brantford/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/ontario/sarnia/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/ontario/cornwall/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/ontario/bradford/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/ontario/stratford/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/ontario/midland/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/ontario/london/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/ontario/guelph/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/ontario/thunder/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/ontario/welland-pelham/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/ontario/woodstock/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/ontario/stouffville/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/ontario/innisfil/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/ontario/ottawa–gatineau/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/ontario/oshawa/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/ontario/kanata/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/ontario/sudbury/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/ontario/sault-ste.-marie/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/ontario/st.-thomas/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/ontario/leamington/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/ontario/timmins/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/british-columbia/nanaimo/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/british-columbia/courtenay/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/british-columbia/victoria/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/british-columbia/kamloops/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/british-columbia/campbell-river/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/british-columbia/kelowna/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/british-columbia/chilliwack/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/british-columbia/penticton/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/british-columbia/abbotsford/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/british-columbia/prince-george/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/british-columbia/mission/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/british-columbia/white-rock/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/british-columbia/vernon/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/british-columbia/parksville/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url> 
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/new-brunswick/moncton/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/new-brunswick/fredericton/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/new-brunswick/saint-john/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/nova-scotia/halifax/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/nova-scotia/cape-breton/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/nova-scotia/sydney/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/prince-edward-island/charlottetown/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/quebec/montreal/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/quebec/saint-jérôme/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/quebec/beloeil/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/quebec/shawinigan/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/quebec/quebec-city/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/quebec/saint-jean-sur-richelieu/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/quebec/saint-hyacinthe/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/quebec/rimouski/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/quebec/sherbrooke/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/quebec/châteauguay/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/quebec/joliette/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/quebec/sorel/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/quebec/trois-rivières/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/quebec/drummondville/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/quebec/victoriaville/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/quebec/saint-georges/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/quebec/chicoutimi-jonquière/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/quebec/granby/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/quebec/salaberry-de-valleyfield/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/saskatchewan/saskatoon/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/saskatchewan/regina/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/saskatchewan/prince-albert/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-rates/saskatchewan/moose-jaw/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-lender/parama-credit-union/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-lender/rmg/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-lender/mcap/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-lender/aveo/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-lender/manulife/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-lender/duca/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-lender/sbi/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-lender/home-equity-bank/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-lender/lighthouse-credit-union/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-lender/scotiabank/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-lender/community-trust/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-lender/strive-bank/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-lender/b2b-bank/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-lender/desjardins/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-lender/marathon-mortgage/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-lender/vancouver-city/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-lender/equitable-bank/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-lender/first-national/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-lender/blueshore/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-lender/hsbc/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-lender/servus/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-lender/wealthone/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-lender/cmls-financials/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-lender/home-trust/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-lender/canadian-western-bank/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-lender/icici/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-lender/shinhan-bank/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-lender/atb/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
<url>
   <loc>https://www.rateshop.ca/${link.Slug}/mortgage-lender/bridgewater-bank/</loc>
   <lastmod>2023-06-08T16:23:11+01:00</lastmod>
   <priority>0.6</priority>
</url>
    `;
    });


    xml += `</urlset>`;

    return xml;
  };

  return (
   <>
   <Helmet>
<meta name="robots" content="noindex,nofollow" />
</Helmet>
    <div>
      <pre>{generateSitemap()}</pre>
    </div>
    </>
  );
};

export default DynamicSitemap;
