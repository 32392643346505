import * as React from 'react';
import "./calender.scss";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function CommercialFormPopup({ openCommercialForm, handleCloseCommercialForm }) {
    return (
        <BootstrapDialog
            onClose={handleCloseCommercialForm}
            aria-labelledby="customized-dialog-title"
            open={openCommercialForm}
            maxWidth="lg"
            className='broker-form cs-form-wrap'
        >
            <DialogContent dividers>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseCommercialForm}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <h2 className="heading">Begin Your Investment Journey Now!</h2>
                <div className="contact-frame">
                    <iframe
                        src="https://api.leadconnectorhq.com/widget/survey/uQjfyGxuzuTThGQJDyvA" 
                        data-layout="{'id':'INLINE'}"
                        data-trigger-type="alwaysShow" 
                        data-activation-type="alwaysActivated" 
                        data-deactivation-type="neverDeactivate" 
                        data-form-name="Commercial Survey"
                        data-height="500" 
                        title="Commercial Survey"
                    >
                    </iframe>
                    <script src="https://link.msgsndr.com/js/form_embed.js"></script>
                </div>
            </DialogContent>
        </BootstrapDialog>
    );
}
