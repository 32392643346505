import React from 'react'
import { NavLink } from 'react-router-dom';
import './navbar.scss';
import Button from '@mui/material/Button';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Stack from '@mui/material/Stack'; 
import Cookies from 'universal-cookie';

export default function Navbar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const cookies = new Cookies();
  const [baseUrl, setBaseUrl] = React.useState('/');
  const [showClass, setShowClass] = React.useState('');
  React.useEffect(() => {
    if(cookies.get('cookieAgentSlug') != undefined && cookies.get('agentSite')){
        setBaseUrl('/'+cookies.get('cookieAgentSlug')+'/');
    }

  }, [showClass]);
  
  // const open = true;

  

  const anchorRef = React.useRef(null);

  const handleToggle = () => {

    setShowClass((prevClass) => ((prevClass == '') ? 'show' : ''));

  };

  // dropdown
  const [openCalc, setOpenCalc] = React.useState(false);
  const [openCalc1, setOpenCalc1] = React.useState(false);
  const anchorRefCalc = React.useRef(null);
  const anchorRefCalc1 = React.useRef(null);

  const handleToggleCalc = () => {
    setOpenCalc((prevOpenCalc) => !prevOpenCalc);
  };
  const handleToggleCalc1 = () => {
    setOpenCalc1((prevOpenCalc1) => !prevOpenCalc1);
  };
  const handleCloseCalc1 = (event) => {
    if (anchorRefCalc1.current && anchorRefCalc1.current.contains(event.target)) {
      return;
    }

    setOpenCalc1(false);
  };

  const handleCloseCalc = (event) => {
    if (anchorRefCalc.current && anchorRefCalc.current.contains(event.target)) {
      return;
    }

    setOpenCalc(false);
  };

  function handleListKeyDownCalc(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenCalc(false);
    } else if (event.key === 'Escape') {
      setOpenCalc(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpenCalc = React.useRef(openCalc);
  React.useEffect(() => {
    if (prevOpenCalc.current === true && openCalc === false) {
      anchorRefCalc.current.focus();
    }

    prevOpenCalc.current = openCalc;
  }, [openCalc]);
  // dropdown

  return (
    <div className='top-nav'>
      <Paper className={`desktop-main ${showClass}`}>
        <MenuList className='desktop-menu' >
          <MenuItem><NavLink to={baseUrl} onClick={handleToggle}>Home</NavLink> </MenuItem>
          <MenuItem><NavLink to={`${baseUrl}mortgage-rates`} onClick={handleToggle}>Mortgages Rates</NavLink></MenuItem>
          {/* <MenuItem><NavLink to="/learning-center">Learning Center</NavLink></MenuItem> */}
          {/* <MenuItem><NavLink to="/agent-directory">Our Agents</NavLink></MenuItem> */}
          <MenuItem>
            <Button
              ref={anchorRefCalc}
              id="composition-button"
              aria-controls={openCalc ? 'composition-menu' : undefined}
              aria-expanded={openCalc ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleToggleCalc} 
              className='has-dropdown'
            >
              Calculators 
            </Button>
            <Popper
              open={openCalc}
              anchorEl={anchorRefCalc.current}
              role={undefined}
              placement="bottom-start"
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === 'bottom-start' ? 'left top' : 'left bottom',
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleCloseCalc}>
                      <MenuList
                        autoFocusItem={openCalc}
                        id="composition-menu"
                        aria-labelledby="composition-button"
                        onKeyDown={handleListKeyDownCalc}
                      >
                        <MenuItem onClick={handleCloseCalc}><NavLink to={`${baseUrl}mortgage-payment-calculator`} onClick={handleToggle}>Mortgage Payment Calculator</NavLink></MenuItem>
                        <MenuItem onClick={handleCloseCalc}><NavLink to={`${baseUrl}affordability-calculator`} onClick={handleToggle}>Affordability Calculator</NavLink></MenuItem>
                        <MenuItem onClick={handleCloseCalc}><NavLink to={`${baseUrl}land-transfer-tax-calculator`} onClick={handleToggle}>Land Transfer Tax Calculator</NavLink></MenuItem>
                        <MenuItem onClick={handleCloseCalc}><NavLink to={`${baseUrl}cmhc-insurance-calculator`} onClick={handleToggle}>CMHC Insurance Calculator</NavLink></MenuItem>
                        <MenuItem onClick={handleCloseCalc}><NavLink to={`${baseUrl}cost-closing-calculator`} onClick={handleToggle}>Closing Cost Calculator</NavLink></MenuItem>
                        <MenuItem onClick={handleCloseCalc}><NavLink to={`${baseUrl}purchase-calculator`} onClick={handleToggle}>Purchase Calculator</NavLink></MenuItem>
                        <MenuItem onClick={handleCloseCalc}><NavLink to={`${baseUrl}maximum-mortgage-calculator`} onClick={handleToggle}>Maximum Mortgage Calculator</NavLink></MenuItem>
                        <MenuItem onClick={handleCloseCalc}><NavLink to={`${baseUrl}required-income-calculator`} onClick={handleToggle}>Required Income Calculator</NavLink></MenuItem>
                        <MenuItem onClick={handleCloseCalc}><NavLink to={`${baseUrl}debt-service-calculator`} onClick={handleToggle}>Debt Service Calculator</NavLink></MenuItem>
                        <MenuItem onClick={handleCloseCalc}><NavLink to={`${baseUrl}compare-side-by-side-calculator`} onClick={handleToggle}>Compare Side-by-side</NavLink></MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </MenuItem>
          {/* <MenuItem><NavLink to={`${baseUrl}credit-score`} onClick={handleToggle}>Credit Score</NavLink></MenuItem> */}
          <MenuItem><NavLink to="https://ref.rateshop.ca/" target={'_blank'} onClick={handleToggle}>Partners</NavLink></MenuItem> 
          {/* <MenuItem><NavLink to="/contact">Contact</NavLink></MenuItem> */}
          <MenuItem>
            <NavLink to={`apply${baseUrl}`} className="apply-now" onClick={handleToggle}>Apply Now</NavLink>
          </MenuItem>
          <MenuItem>
            <NavLink to={`${baseUrl}join-us`} className="apply-now" onClick={handleToggle}>Careers</NavLink>
          </MenuItem> 
      
        </MenuList>
      </Paper>
      <div className='mobile-menu'>
        <div className={`mobile-overlay ${showClass}`}></div>
        <Button ref={anchorRef} aria-controls={open ? 'menu-list-grow' : undefined} aria-haspopup="true" onClick={handleToggle} className={`mobile-toggle-btn ${showClass}`}>
          <MenuIcon className="mobile-toggle" />
          <CloseIcon className="mobile-toggle toggle-close" />
        </Button>
        {/* <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal className={`mobile-menu-list  ${showClass}`}> */}
        {/* <Popper open={open} anchorEl={anchorRef.current} role={undefined}   disablePortal className={`mobile-menu-list  ${showClass}`}> */}
        {/* {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <MenuList autoFocusItem={open} id="menu-list-grow">
                <MenuItem><NavLink to='/'>Home</NavLink> </MenuItem>
                <MenuItem><NavLink to="/mortgages">Mortgages Rates</NavLink></MenuItem> 
                <MenuItem><NavLink to="/blogs">Learning Center</NavLink></MenuItem>
                <MenuItem><NavLink to="/our-agents">Our Agents</NavLink></MenuItem>
                <MenuItem><NavLink to="/credit-score">Credit Score</NavLink></MenuItem>
                <MenuItem><NavLink to="/contact">Contact</NavLink></MenuItem> */}
        {/* <MenuItem><NavLink to='/'>Home</NavLink> </MenuItem>
                  <MenuItem><NavLink to="/mortgages">Mortgages</NavLink></MenuItem>
                  <MenuItem><NavLink to="/loans">Loans</NavLink></MenuItem>
                  <MenuItem><NavLink to="/credit-cards">Credit Cards</NavLink></MenuItem>
                  <MenuItem><NavLink to="/banking">Banking</NavLink></MenuItem>
                  <MenuItem><NavLink to="/investments">Investments</NavLink></MenuItem>
                  <MenuItem><NavLink to="/insurance">Insurance</NavLink></MenuItem>
                  <MenuItem><NavLink to="/money-transfers">Money Transfers</NavLink></MenuItem>
                  <MenuItem><NavLink to="/credit-score">Credit Score</NavLink></MenuItem>
                  <MenuItem><NavLink to="/contact">Contact</NavLink></MenuItem>
                  <MenuItem><NavLink to="/blogs">Blogs</NavLink></MenuItem> */}
        {/* <MenuItem>
                    <Button variant="contained" className="apply-now" color="secondary" >Apply Now</Button>
                  </MenuItem>
                </MenuList>
              </Paper>
            </Grow> */}
        {/* )} */}
        {/* </Popper> */}
      </div>
    </div>
  );
}
