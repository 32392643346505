import React from 'react';
import { Outlet } from "react-router-dom"; 

const SweaptakeHead = () => { 
  return ( 
    <> 
      <Outlet />
    </>
  )
}
export default SweaptakeHead
