import React, { useState, useEffect } from "react";
import { Button, Container, Grid, TextField } from "@mui/material";
import './agentsDirectory.scss';
import AOS from 'aos';
import "aos/dist/aos.css";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

// import { agentListing } from '../../../constant';
import CallIcon from '@mui/icons-material/Call';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import MailIcon from '@mui/icons-material/Mail';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
// import Pagination from '@mui/material/Pagination';
import Partner from "../../../../components/shared/partner/Partner";
import axios from 'axios';
import { Oval } from 'react-loader-spinner';
import {
    //  Route, Routes, useNavigate, 
     Link } from "react-router-dom";
import {
    defaultProfileImage
}
    from "../../../../core/Images";

import * as Yup from "yup";
import { useFormik } from "formik";
    
import { styled  }  from '@mui/system';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import swal from 'sweetalert';

const useStyles = styled ((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        borderRadius:'10px', 
        width: '500px',
        maxWidth: '96%',
        backgroundColor: '#F5FAFE',
        border: '2px solid #153189', 
        overflow: 'auto',
        maxHeight: '100vh',
    },
}));



const AgentsDirectory = () => {


    const initialValues = {
        f_name: "",
        number: "",
        email: "",
        message: "",
      };
      
    const handleFormSubmit = (values) => {
        const slugObj = {
            slug: agentPopupSlug,
            Authorization: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoiQWJoaXNoZWsgUHVyaSIsIm5hbWUiOiJSZWFjdCBSUyIsInBhc3N3b3JkIjpudWxsLCJBUElfVElNRSI6MTY3NjY0MjQyNn0.kcYNItsCPFOtwPIgffGx1Zf-BGDN_Vsx-r0vnGcucOs',
            l_name: '',
            lead_sourcef1: 4,
            country: 'Canada',
            zip: '',
            city: '',
            state: '',
            address: '',
            morge_type: '',
            status_type: 1,
            preferred_agent: staffId,
            isRoundRobin: 0,
        };
        const apiData = {...values, ...slugObj};
    // console.log('valuesss', apiData);
    setShowLoader(true);
    fetch('https://crm.rateshop.ca/ReactController/shortFormLeads', {
      method: 'POST',
      headers: {
        Accept: 'application/form-data',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(apiData),
    })
      .then(res => res.json())
      .then(
        (result) => {
          // alert(result['message'])
          // if (result['status'] === 'ok') {
          //   window.location.href = '/';
          // }
          setOpen(false);
          setShowLoader(false);
          swal("Good job!", "Message Sent!", "success");
          
        }
      )
    }
    const formSchema = Yup.object({
        number: Yup.string()
        .required("Required")
        .max(10, 'Max allowed length is 10'),
        f_name: Yup.string()
        .required("Required"),
        email: Yup.string()
        .required("Required"),
        message: Yup.string()
        .required("Required"),
        
      });
      const { values, errors, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: formSchema,
        onSubmit: (values) => {
          handleFormSubmit(values);
        },
      });
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    
    const [agentPopupName, setAgentPopupName] = React.useState('');
    const [agentPopupSlug, setAgentPopupSlug] = React.useState('');
    const [staffId, setStaffId] = React.useState('');
    
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const data = {
        type: 'full',
        Authorization: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoiQWJoaXNoZWsgUHVyaSIsIm5hbWUiOiJSZWFjdCBSUyIsInBhc3N3b3JkIjpudWxsLCJBUElfVElNRSI6MTY3NjY0MjQyNn0.kcYNItsCPFOtwPIgffGx1Zf-BGDN_Vsx-r0vnGcucOs',

    };
    const [agentlist, setAgentList] = useState({});

    const getAgentsList = async () => {
        try {

            await axios.post(`https://crm.rateshop.ca/ReactController/getStaffSlugs`, { data })
                .then(res => {
                    setAgentList(res.data.data);
                })

        } catch (error) {

        }
    }
    const [searchValue, setSearchValue] = useState('');
    const searchFilter = async (e) => {
        e.preventDefault();
        const staffData = {
            searchText: searchValue,
            Authorization: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoiQWJoaXNoZWsgUHVyaSIsIm5hbWUiOiJSZWFjdCBSUyIsInBhc3N3b3JkIjpudWxsLCJBUElfVElNRSI6MTY3NjY0MjQyNn0.kcYNItsCPFOtwPIgffGx1Zf-BGDN_Vsx-r0vnGcucOs',

        };
        try {
            setAgentList({});
            await axios.post(`https://crm.rateshop.ca/ReactController/getStaffByFilter`, { staffData })
                .then(res => {
                    // console.log(res.data);
                    setAgentList(res.data.data);
                })

        } catch (error) {

        }

    }
    const [showLoader, setShowLoader] = useState(false);
    useEffect(() => {
        AOS.init();
        AOS.refresh();
        getAgentsList();


    }, []);
    if ((Object.keys(agentlist).length === 0 && agentlist.constructor === Object) || showLoader) {
        return <Oval
            height={60}
            width={60}
            color="#FFFFFF"
            wrapperStyle={{}}
            wrapperClass="csLoader"
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor="#EEEEEE"
            strokeWidth={4}
            strokeWidthSecondary={4}
        />;
    }

    //    console.log(searchValue);

    return (
        <>
            <section className='agent-directory-banner'>
                <Container>
                    <Grid container>
                        <Grid lg={12} xs={12} item className="cs-p0">
                            <div className='banner-content'>
                                <div className="heading text-center">
                                    <h1>Agents <span className="com-back-head">Directory</span></h1>
                                </div>
                                <div className="agents-directory-search">
                                    {/* <RadioGroup
                                        defaultValue=""
                                        name="radio-buttons-group"
                                    >
                                        <FormControlLabel value="yes" control={<Radio />} label="Expert" />
                                        <FormControlLabel value="no" control={<Radio />} label="Office" />
                                    </RadioGroup> */}
                                    <TextField id="standard-basic" onKeyUp={(e) => setSearchValue(e.target.value)} placeholder="Postal code, city, province or agent " />
                                    <Button variant="contained" onClick={searchFilter} className="search-btn">
                                        Search
                                    </Button>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </section>
            {/* {/ Directory listing /} */}
            <section className='agent-directory-listing'>
                <Container>
                    {/* <h2 className="result-found-title">Found 1-10 of 142 Results</h2> */}
                    <Grid container>
                        <div className="directory-listing-wrap">
                            {agentlist.map((item) =>
                                <div className="directory-listing-inner" key={item.staffid}>
                                    <div className="agent-img">
                                    <Link variant="contained" to={`/agent/${item.Slug}`}>
                                        {(() => {
                                            let imagePath = item.profile_image;
                                            if (imagePath != null) {

                                                if (imagePath.includes('https://www.rateshop.ca/')) {
                                                    return (
                                                        <figure>
                                                            <img src={item.profile_image}></img>
                                                        </figure>
                                                    )
                                                }

                                                else if (item.profile_image != null) {
                                                    return (
                                                        <figure>
                                                            <img src={`https://crm.rateshop.ca/uploads/staff_profile_images/${item.staffid}/thumb_${item.profile_image}`}></img>
                                                        </figure>
                                                    )
                                                } else {
                                                    return (
                                                        <figure>
                                                            <img src={defaultProfileImage}></img>
                                                        </figure>
                                                    )
                                                }
                                            } else {
                                                return (
                                                    <figure>
                                                        <img src={defaultProfileImage}></img>
                                                    </figure>
                                                )
                                            }
                                        })()}       
                                        </Link>         
                                    </div>
                                    <div className="agent-details">
                                        <div className="agent-name">
                                            <h3><Link variant="contained" to={`/agent/${item.Slug}`}>
                                                {item.firstname} {item.lastname}</Link></h3>
                                            <h4 className="agent-position">{item.desigination}</h4>
                                        </div>
                                        <div className="agent-details-links">
                                            <ul>
                                                <li>
                                                    <a href={'tel:' + item.phonenumber}>
                                                        <span><CallIcon /></span> {item.phonenumber}
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href={'tel:' + item.Cell_Phone}>
                                                        <span><PhoneAndroidIcon /></span> {item.Cell_Phone}
                                                    </a>
                                                </li>
                                                <li>
                                                    {/* <a href={'mailto:' + item.email}> */}
                                                    <a  onClick={event => {
                                                            handleOpen();
                                                            setAgentPopupName(item.firstname+' '+item.lastname);
                                                            setAgentPopupSlug(item.Slug);
                                                            setStaffId(item.staffid);
                                                        }}>
                                                        <span><MailIcon /></span>Send Me a Message
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="social-icons-details-btn">
                                            <ul className="social-icons">
                                                <li>
                                                    <a href={item.twitter_id} target='_blank'><TwitterIcon /></a>
                                                </li>
                                                <li>
                                                    <a href={item.facebook} target='_blank'><FacebookIcon /></a>
                                                </li>
                                                <li>
                                                    <a href={item.instagram} target='_blank'><InstagramIcon /></a>
                                                </li>
                                            </ul>
                                            <div className="detail-btn">

                                                <Link variant="contained" to={`/agent/${item.Slug}`}>See Profile</Link>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </Grid>
                    {/* <div className="cs-apgination">
                        <Pagination count={10} />
                    </div> */}
                </Container>
            </section>
            {/* partner logo Start*/}
            <Partner />
            {/* partner logo End*/}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={`message_agent_modal ${classes.modal}`}
                open={open}
                onClose={handleClose}   
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <div className="contact-form-wrap">
                            <h2>Contact {agentPopupName}</h2>
                            <div className="form-outer-wrap">
                                <form className=""   autoComplete="off" onSubmit={handleSubmit}>
                                    <div className='form-outer'>
                                        
                                        <TextField id="" label="Your Name" name="f_name" onChange={handleChange}  />
                                        <p className="cs-error">{errors.fullName}</p>
                                        <TextField id="" label="Your Email" name="email" onChange={handleChange}/>
                                        <p className="cs-error">{errors.email}</p>
                                        <TextField id="" label="Your Phone" name="number" onChange={handleChange}/> 
                                        <p className="cs-error">{errors.phone}</p>
                                        <TextField id="" multiline maxRows={4} name="message" onChange={handleChange} label="Your Messsage" /> 
                                        <p className="cs-error">{errors.message}</p>
                                        <Button variant="contained" color="primary" type="submit">
                                            Send Message
                                        </Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </>
    )
}

export default AgentsDirectory