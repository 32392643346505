import React, { useEffect ,useState } from 'react';
import './packages.scss';
import Helmet from 'react-helmet';
import { Container } from "@mui/material";

const Packages = function () { 
    useEffect(() => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://js.zohostatic.com/books/zfwidgets/assets/js/zf-widget.js';
        script.onload = () => {
          const pricingTableComponentOptions = {
            id: 'zf-widget-root-id',
            product_id: '2-c708195360af4142db8b2f7423890844d6dbcde52d9ed23e69264ab25dd5d98758231e8073989dd758bcad6fd428a6fd8c96db5de59c3d3595e7f706b749742d',
            template: 'combo',
            most_popular_plan: '',
            is_group_by_frequency: false,
            isFrequencyDropdown: false,
            isCurrencyDropdown: false,
            can_show_plan_freq: true,
            pricebooks: [
          
            ],
            group_options: [
              
            ],
            plans: [
              {
          plan_code: '003',
          selectedAddons: [
            {
          addon_code: 'COMMITMENT',
          addon_quantity: '1'
      },
    
          ]
      },
    
            ],
            theme: { color: '#152A92', theme_color_light: '#F9FCFF'},
            button_text: 'Register Now',
            product_url: 'https://subscriptions.zoho.com',
            price_caption: 'RateShop Broker Registration',
            language_code: 'en_ca',
            open_inSameTab: false
          };
          window.ZFWidget.init('zf-pricing-table', pricingTableComponentOptions);
        };
        document.body.appendChild(script);
    
        return () => {
          document.body.removeChild(script);
        };
      }, []);
    return (
        <>
            {/* <Helmet>
          <meta charSet="utf-8" />
          <title>Canada's Maximum Mortgage & Affordability Calculator</title>
          <meta name='description' content='Canada&#x27;s maximum mortgage and affordability calculator helps prospective home buyers estimate how much they can afford to borrow based on their income, expenses, and other financial factors. Use this tool to determine your mortgage affordability and make informed decisions when purchasing a home in Canada.' />
          <meta name="keywords" content="mortgage affordability calculator, td mortgage affordability calculator, mortgage affordability calculator canada, affordability calculator"/>
        </Helmet>  */}
            {/* banner start */}
            <section className="inner-banner-sec">
                <Container>
                    <div className="heading"><h1><span>Become</span>  a RateShop Broker</h1></div>
                    <p>If you're interested in joining one of Canada's fastest up-and-coming mortgage brokerages, choose a plan below to get started.</p>
                </Container>
            </section>
            {/* leader in start */}
            <section className="zoho-iframe">
                <Container> 
               
                <div id="zf-widget-root-id"></div>
                 </Container> 
    
            </section>
        </>
    )
}

export default Packages;