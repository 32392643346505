import React from 'react';
import { Container } from "@mui/material";
import { NavLink ,Link} from 'react-router-dom';
import {
    whichmortgage,
    Toronto_Star_logo,
    cmp_top_ind_broker,
    top_independent_brokerages_2020,
    cmp,
} from "../../../core/Images";

const PartnerLogo = () => {

    return (
        <section className="cs-partner-logo">
            <Container>
                <div className="partner-logo-wrap">
                    <div className="heading">
                        <h3>As Seen And heard on</h3>
                    </div>
                    <ul className="d-flex align-items-center justify-content-center">
                        <li>
                            <Link target="_blank" to="https://www.whichmortgage.ca/archived/new-comparison-site-includes-credit-score-filter/243963" >
                                <img src={whichmortgage} alt="partner" />
                            </Link>
                        </li>  
                        <li>
                            <Link target="_blank" to="https://www.mpamag.com/ca/news/general/rateshop-mortgages-primed-for-success/286202" >
                                <img src={cmp} alt="partner" />
                            </Link>
                        </li>

                        <li>
                            <Link target="_blank" to="https://www.thestar.com/sponsored-sections/customizing-products-to-suit-each-client-s-specific-needs-rateshop-is-a-mecca-for-all/article_f9186c69-de40-54f3-a284-6d9f2d178e58.html" >
                                <img src={Toronto_Star_logo} alt="partner" />
                            </Link>
                        </li>
                        <li>
                            <Link target="_blank" to="https://www.mpamag.com/ca/best-in-mortgage/top-independent-brokerages-2021/309437" >
                                <img src={cmp_top_ind_broker} alt="partner" />
                            </Link>
                        </li>
                        <li>
                            <Link target="_blank" to="https://www.mpamag.com/ca/best-in-mortgage/rankings/top-independent-brokerages-2020/288831 " >
                                <img src={top_independent_brokerages_2020} alt="partner" />
                            </Link>
                        </li>

                    </ul>
                </div> 
            </Container>
        </section>
    );
}

export default PartnerLogo;