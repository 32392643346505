import React from 'react';
import { Container } from "@mui/material";
import "./link-to-detail.scss";
import { Link } from "react-router-dom";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const LinkToDetail = ({ image, heading, subHeading }) => {
    return (
        <div className="link-to-detail">
            <div className="link-t-d-image">
                <img src={image} alt="" />
            </div>
            <div className="link-t-d-text">
                <h3>{heading}</h3>
                <h6>{subHeading}</h6>
            </div>
            <ChevronRightIcon />
        </div>
    );
}

export default LinkToDetail;