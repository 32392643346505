import CryptoJS from "crypto-js";
import axios from 'axios';

export const isValidNumber = (value = '') => {
    const re = /^[0-9\b]+$/;
    if(value == '' || re.test(value)) {
        return true;
    }
    return false;
}

export const decryptData = async (value) => {
    const res = await axios.post(`https://crm.rateshop.ca/ReactController/decryptFn`, { data: {id: value} })
    return (res.data.status === 200 && res.data.userId) ? res.data.userId : null;
}

export const encryptData = (value) => {
    const data = CryptoJS.AES.encrypt(
        JSON.stringify(value),
        '$b@bl2I@?%%4K*mC6r273~8l3|6@>D'
      ).toString();
    return data;
}

