import React, { useState } from 'react';
import {
    Container,
    Button,
    AppBar,
    Tab
} from "@mui/material";
// import {
//     TabContext,
//     TabList,
//     TabPanel
// } from "@material-ui/lab";
import {
    MortgageSvg,
    CreditCardSvg,
    BankingSvg,
    InsuranceSvg,
    InvestingSvg,
    LoanSvg,
} from "../../../core/ImgsesSvg";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import canadaFlag from "../../../assets/public/images/webp/canadaFlag.webp";


import { Link, useNavigate  } from 'react-router-dom';
function TabPanel(props) {
    const { children, value, index, ...other } = props;
   
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const MortgageRate = () => {
    let navigate = useNavigate(); 
    const routeChange = () =>{ 
      let path = `mortgage-rates`; 
      navigate(path);
    }
    const [value, setValue] = React.useState(0);

    const [insuredThreeYearFixed, setInsuredThreeYearFixed] = React.useState(0);
    const [insuredFourYearFixed, setInsuredFourYearFixed] = React.useState(0);
    const [insuredFiveYearFixed, setInsuredFiveYearFixed] = React.useState(0);
    const [insuredFiveYearVariable, setInsuredFiveYearVariable] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const token = {
        Authorization: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoiQWJoaXNoZWsgUHVyaSIsIm5hbWUiOiJSZWFjdCBSUyIsInBhc3N3b3JkIjpudWxsLCJBUElfVElNRSI6MTY3NjY0MjQyNn0.kcYNItsCPFOtwPIgffGx1Zf-BGDN_Vsx-r0vnGcucOs',
    };
    // const apiData = {...values, ...slugObj};
    // console.log('valuesss', apiData);
    // setShowLoader(true);
    fetch('https://crm.rateshop.ca/ReactController/mortgageRates', {
        method: 'POST',
        headers: {
            Accept: 'application/form-data',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(token),
    })
        .then(res => res.json())
        .then(
            (result) => {

                const insuredThreeYearFixed = Math.min(...JSON.parse(result.rates.insured_three_year_fixed).filter(function (el) {
                    return el != '';
                  }));
                const insuredFourYearFixed = Math.min(...JSON.parse(result.rates.insured_four_year_fixed).filter(function (el) {
                    return el != '';
                  }));
                const insuredFiveYearFixed = Math.min(...JSON.parse(result.rates.insured_five_year_fixed).filter(function (el) {
                    return el != '';
                  }));
                const insuredFiveYearVariable = Math.min(...JSON.parse(result.rates.insured_five_year_variable).filter(function (el) {
                    return el != '';
                  }));

                setInsuredThreeYearFixed(insuredThreeYearFixed);
                setInsuredFourYearFixed(insuredFourYearFixed);
                setInsuredFiveYearFixed(insuredFiveYearFixed)
                setInsuredFiveYearVariable(insuredFiveYearVariable);

            }
        )


    return (
        <section className="mortgage-rates">
            <Container>
                <div className="heading">
                    <h3>
                        <span>Canada's</span> Best Mortgage Rates
                    </h3>
                </div>
                {/* <Grid lg={12}>
                    <div className="mortgage-inn-btn">
                        <NavLink to=""><span><HomeIcon/></span><p>I'm buying a home</p></NavLink>
                        <NavLink to=""><span><LoopIcon/></span><p>I'm renewing/refinancing</p></NavLink>
                    </div>
                </Grid> */}
                <div className="mortgage-tabs">
                    <Box
                        sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', }}
                    >

                        <TabPanel value={value} index={0}>
                            <div className="tab-content">
                                {/* <TabPanel value="1"> */}
                                <div className="mortgage-content">
                                    <img src={canadaFlag} alt='flag'/>
                                    <div className="mortgage-wrap">
                                        
                                        <div className="left-sec">
                                            <h4>
                                                {insuredThreeYearFixed}<span>%</span>
                                            </h4>
                                        </div>
                                        <div className="right-text">
                                            <h5>
                                                Lowest Purchase<span>3-YR Fixed</span>
                                            </h5>
                                        </div>
                                        <div className="mortage-btns">
                                            <Button variant="contained" onClick={routeChange} color="secondary">
                                                Get this rate
                                            </Button>
                                            {/* <Button variant="contained" color="primary">Compare</Button> */}
                                        </div>
                                    </div>
                                    <p>*rates subject to change, High Ratio Mortgage</p>
                                    {/* <div className="mortgage-wrap">
                                        <div className="left-sec">
                                            <h4>
                                                1.45<span>%</span>
                                            </h4>
                                        </div>
                                        <div className="right-text">
                                            <h5>
                                                Lowest Refinance<span>5-YR Variable</span>
                                            </h5>
                                        </div>
                                        <div className="mortage-btns">
                                            <Button variant="contained" color="secondary">
                                                Get this rate
                                            </Button>
                                            <Button variant="contained" color="primary">Compare</Button>
                                        </div>
                                    </div> */}
                                </div>
                            </div>

                        </TabPanel>
                        <TabPanel value={value} index={1}>

                            <div className="tab-content">
                                {/* <TabPanel value="1"> */}
                                <div className="mortgage-content">
                                    <img src={canadaFlag} alt='flag'/>
                                    <div className="mortgage-wrap">
                                        <div className="left-sec">
                                            <h4>
                                                {insuredFourYearFixed}<span>%</span>
                                            </h4>
                                        </div>
                                        <div className="right-text">
                                            <h5>
                                                Lowest Purchase<span>4-YR Fixed</span>
                                            </h5>
                                        </div>
                                        <div className="mortage-btns">
                                            <Button variant="contained" onClick={routeChange} color="secondary">
                                                Get this rate
                                            </Button>
                                            {/* <Button variant="contained" color="primary">Compare</Button> */}
                                        </div>
                                    </div>
                                    <p>*rates subject to change, High Ratio Mortgage</p>
                                    {/* <div className="mortgage-wrap">
                                        <div className="left-sec">
                                            <h4>
                                                1.45<span>%</span>
                                            </h4>
                                        </div>
                                        <div className="right-text">
                                            <h5>
                                                Lowest Refinance<span>5-YR Variable</span>
                                            </h5>
                                        </div>
                                        <div className="mortage-btns">
                                            <Button variant="contained" color="secondary">
                                                Get this rate
                                            </Button>
                                            <Button variant="contained" color="primary">Compare</Button>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={2}>

                            <div className="tab-content">
                                {/* <TabPanel value="1"> */}
                                <div className="mortgage-content">
                                    <img src={canadaFlag} alt='flag'/>
                                    <div className="mortgage-wrap">
                                        <div className="left-sec">
                                            <h4>
                                                {insuredFiveYearFixed}<span>%</span>
                                            </h4>
                                        </div>
                                        <div className="right-text">
                                            <h5>
                                                Lowest Purchase<span>5-YR Fixed</span>
                                            </h5>
                                        </div>
                                        <div className="mortage-btns">
                                            <Button variant="contained" onClick={routeChange} color="secondary">
                                                Get this rate
                                            </Button>
                                            {/* <Button variant="contained" color="primary">Compare</Button> */}
                                        </div>
                                    </div>
                                    <p>*rates subject to change, High Ratio Mortgage</p>
                                    {/* <div className="mortgage-wrap">
                                        <div className="left-sec">
                                            <h4>
                                                {insuredFiveYearVariable}<span>%</span>
                                            </h4>
                                        </div>
                                        <div className="right-text">
                                            <h5>
                                                Lowest Refinance<span>5-YR Variable</span>
                                            </h5>
                                        </div>
                                        <div className="mortage-btns">
                                            <Button variant="contained" color="secondary">
                                                Get this rate
                                            </Button>
                                            <Button variant="contained" color="primary">Compare</Button>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={3}>

                            <div className="tab-content">
                                {/* <TabPanel value="1"> */}
                                <div className="mortgage-content">
                                    <img src={canadaFlag} alt='flag'/>
                                    <div className="mortgage-wrap">
                                        <div className="left-sec">
                                            <h4>
                                                {insuredFiveYearVariable}<span>%</span>
                                            </h4>
                                        </div>
                                        <div className="right-text">
                                            <h5>
                                                Lowest Purchase<span>5-YR Variable</span>
                                            </h5>
                                        </div>
                                        <div className="mortage-btns">
                                            <Button variant="contained" onClick={routeChange} color="secondary">
                                                Get this rate
                                            </Button>
                                            {/* <Button variant="contained" color="primary">Compare</Button> */}
                                        </div>
                                    </div>
                                    <p>*rates subject to change, High Ratio Mortgage</p>
                                    {/* <div className="mortgage-wrap">
                                        <div className="left-sec">
                                            <h4>
                                                1.45<span>%</span>
                                            </h4>
                                        </div>
                                        <div className="right-text">
                                            <h5>
                                                Lowest Refinance<span>5-YR Variable</span>
                                            </h5>
                                        </div>
                                        <div className="mortage-btns">
                                            <Button variant="contained" color="secondary">
                                                Get this rate
                                            </Button>
                                            <Button variant="contained" color="primary">Compare</Button>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={4}>

                            <div className="tab-content">
                                {/* <TabPanel value="1"> */}
                                <div className="mortgage-content">
                                    <img src={canadaFlag} alt='flag'/>
                                    <div className="mortgage-wrap">
                                        <div className="left-sec">
                                            <h4>
                                                11<span>%</span>
                                            </h4>
                                        </div>
                                        <div className="right-text">
                                            <h5>
                                                Lowest Purchase<span>5-YR Variable</span>
                                            </h5>
                                        </div>
                                        <div className="mortage-btns">
                                            <Button variant="contained" onClick={routeChange} color="secondary">
                                                Get this rate
                                            </Button>
                                            {/* <Button variant="contained" color="primary">Compare</Button> */}
                                        </div>
                                    </div>
                                    <div className="mortgage-wrap">
                                        <div className="right-text">
                                            <h5>
                                                Lowest Refinance<span>5-YR Variable</span>
                                            </h5>
                                        </div>
                                        <div className="left-sec">
                                            <h4>
                                                1.45<span>%</span>
                                            </h4>
                                        </div>
                                        <div className="mortage-btns">
                                            <Button variant="contained" onClick={routeChange} color="secondary">
                                                Get this rate
                                            </Button>
                                            {/* <Button variant="contained" color="primary">Compare</Button> */}
                                        </div>
                                    </div>
                                    <p>*rates subject to change, High Ratio Mortgage</p>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={5}>

                            <div className="tab-content">
                                {/* <TabPanel value="1"> */}
                                <div className="mortgage-content">
                                    <img src={canadaFlag} alt='flag'/>
                                    <div className="mortgage-wrap">
                                        <div className="left-sec">
                                            <h4>
                                                11<span>%</span>
                                            </h4>
                                        </div>
                                        <div className="right-text">
                                            <h5>
                                                Lowest Purchase<span>5-YR Variable</span>
                                            </h5>
                                        </div>
                                        <div className="mortage-btns">
                                            <Button variant="contained" onClick={routeChange} color="secondary">
                                                Get this rate
                                            </Button>
                                            {/* <Button variant="contained" color="primary">Compare</Button> */}
                                        </div>
                                    </div>
                                    <div className="mortgage-wrap">
                                        <div className="left-sec">
                                            <h4>
                                                1.45<span>%</span>
                                            </h4>
                                        </div>
                                        <div className="right-text">
                                            <h5>
                                                Lowest Refinance<span>5-YR Variable</span>
                                            </h5>
                                        </div>
                                        <div className="mortage-btns">
                                            <Button variant="contained" onClick={routeChange} color="secondary">
                                                Get this rate
                                            </Button>
                                            {/* <Button variant="contained" color="primary">Compare</Button> */}
                                        </div>
                                    </div>
                                    <p>*rates subject to change, High Ratio Mortgage</p>
                                </div>
                            </div>
                        </TabPanel>
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={value}
                            onChange={handleChange}
                            aria-label="Vertical tabs example"
                            sx={{ borderRight: 1, borderColor: 'divider' }}
                        >
                            <Tab className='three-num' label="Years  Fixed Rate"   {...a11yProps(0)} />
                            {/* <Tab icon={<CreditCardSvg />}
                                label="Refinance"
                                {...a11yProps(1)} /> */}
                            <Tab className='four-num' label="Years  Fixed Rate"   {...a11yProps(2)} />
                            <Tab className='five-num' label="Years  Fixed Rate"    {...a11yProps(3)} />
                            <Tab className='five-num' label="Years Variable Rate"    {...a11yProps(3)} />
                            {/* <Tab icon={<InsuranceSvg />} label="Insurance"  {...a11yProps(4)} />
                            <Tab icon={<LoanSvg />} label="Loans"    {...a11yProps(5)} /> */}
                            {/* <Link to={"/credit-score"} className="see-all-rates" variant="contained">Get Started Free</Link> */}
                            <Button className="see-all-rates" onClick={routeChange} variant="contained">
                                See All Our Rates
                            </Button>
                        </Tabs>
                    </Box>
                </div>
            </Container >
        </section >
    );
}

export default MortgageRate;