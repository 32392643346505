import React, {useEffect, useState} from "react";  
import Navbar from "../navbar/Navbar";
import { NavLink, useNavigate, useParams, useSearchParams, useLocation, Navigate } from 'react-router-dom';
import { Button, Container, Grid, Avatar } from "@mui/material";
import { callImage, searchImage } from '../../../../core/Images'
import './header.scss'
import Logo from "../../../shared/logo/Logo";
// import MenuIcon from '@mui/icons-material/Menu';
import CallIcon from '@mui/icons-material/Call';
import agentImg from '../../../../assets/public/images/agent-1.jpg';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import FaxIcon from '@mui/icons-material/Fax';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import Cookies from 'universal-cookie';
import axios from 'axios';
import notfound from '../../../../assets/public/images/error.png';
import { teamMemberOne,teamMemberTwo,teamMemberThree, defaultProfileImage } from "../../../../core/Images";
const Header = () => {
  const [openCalc1, setOpenCalc1] = React.useState(false); 
  const anchorRefCalc1 = React.useRef(null);
  const [staffDetail, setStaffDetail] = React.useState([]);
  const cookies = new Cookies();
  const {slug} = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const utm_source = searchParams?.get('utm_source');
  const utm_medium = searchParams?.get('utm_medium');
  const utm_campaign = searchParams?.get('utm_campaign');
  const utm_content = searchParams?.get('utm_content');
  const utm_keyword = searchParams?.get('utm_keyword');
  const utm_matchtype = searchParams?.get('utm_matchtype');
  const campaign_id = searchParams?.get('campaign_id');
  const ad_group_id = searchParams?.get('ad_group_id');
  const ad_id = searchParams?.get('ad_id');
  const gclid = searchParams?.get('gclid');
  if(searchParams.size > 0){
   
    cookies.set('utm_source', utm_source, { expires: 0 });
    cookies.set('utm_source', utm_source, { expires: 0 });
    cookies.set('utm_medium', utm_medium, { expires: 0 });
    cookies.set('utm_campaign', utm_campaign, { expires: 0 });
    cookies.set('utm_content', utm_content, { expires: 0 });
    cookies.set('utm_keyword', utm_keyword, { expires: 0 });
    cookies.set('utm_matchtype', utm_matchtype, { expires: 0 });
    cookies.set('campaign_id', campaign_id, { expires: 0 });
    cookies.set('ad_group_id', ad_group_id, { expires: 0 });
    cookies.set('ad_id', ad_id, { expires: 0 });
    cookies.set('gclid', gclid, { expires: 0 });

}
  if(slug != undefined){

    const data = {
      type: 'single',
      Authorization: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoiQWJoaXNoZWsgUHVyaSIsIm5hbWUiOiJSZWFjdCBSUyIsInBhc3N3b3JkIjpudWxsLCJBUElfVElNRSI6MTY3NjY0MjQyNn0.kcYNItsCPFOtwPIgffGx1Zf-BGDN_Vsx-r0vnGcucOs',
      slug: slug
    };


      axios.post(`https://crm.rateshop.ca/ReactController/getStaffSlugs`, { data })
          .then(res => {
          if(res.data.status != 200){
              navigate('/404', { replace: true });
          }else{
            const cookies = new Cookies();
            cookies.set('agentSite', 1, { path: '/' });
            cookies.set('cookieAgentSlug', slug, { path: '/' });
            setStaffDetail(res.data.data)
          }
      })

  
  }

 

  const handleToggleCalc1 = () => {
    setOpenCalc1((prevOpenCalc1) => !prevOpenCalc1);
  };

  const data = {
    type: 'single',
    Authorization: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoiQWJoaXNoZWsgUHVyaSIsIm5hbWUiOiJSZWFjdCBSUyIsInBhc3N3b3JkIjpudWxsLCJBUElfVElNRSI6MTY3NjY0MjQyNn0.kcYNItsCPFOtwPIgffGx1Zf-BGDN_Vsx-r0vnGcucOs',
    slug: cookies.get('cookieAgentSlug')
  };

  useEffect(() => {

    

   
  
   
    if(cookies.get('cookieAgentSlug') != undefined && cookies.get('agentSite')){

        axios.post(`https://crm.rateshop.ca/ReactController/getStaffSlugs`, { data })
            .then(res => {
            if(res.data.status != 200){
                navigate('/404', { replace: true });
            }else{
              setStaffDetail(res.data.data);
            }
        })

    }

  }, []);
  const handleCloseCalc1 = (event) => {
    if (anchorRefCalc1.current && anchorRefCalc1.current.contains(event.target)) {
      return;
    }
  
    setOpenCalc1(false);
  };
  
  const [showClass, setShowClass1] = React.useState('');

  const handleToggle1 = () => {

    setShowClass1((prevClass1) => ((prevClass1 == '') ? 'show' : ''));

  };

  function handleListKeyDownCalc1(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenCalc1(false);
    } else if (event.key === 'Escape') {
      setOpenCalc1(false);
    }
  }
  const clickHandler = () => {

    alert('www');
  }
  const location = useLocation();

  // Check if the URL contains "Manitoba"
  if (location.pathname.includes('manitoba') || location.pathname.includes('winnepeg')|| location.pathname.includes('yukon-territory')|| location.pathname.includes('Nunavut')|| location.pathname.includes('northwest-territories') || location.pathname.includes('newfoundland-and-labrador')) {  
    return <Navigate to="/404" />;
  }
  return (
    <>
      <div className="header-wrap " >
        <Container className="header-wrap" maxWidth={"false"}>
          <header className="main-header">
            <Grid container className="align-items-center">
              {/* top bar */}
              <Grid xs={12} item className="pd-0">
                <div className="menu-wrapper">
                  <div className="logo">
                    <Logo />
                  </div>
                  <Navbar />

                  {/* <CallIcon className="mobile-call"/> */}

                  {/* <Button variant="contained" className="call-btn" color="primary">
                    <Avatar alt="Rateshop" src={callImage} />
                    <div className="contact">
                        <span>Call</span> 
                      <span>1800-725-9946</span>
                    </div>
                  </Button> */}
                  <a href="tel:1800-725-9946" className="call-btn d-flex align-items-center" color="primary">
                    <Avatar alt="Rateshop" src={callImage} />
                    <div className="contact">
                      {/* <span>Call</span> */}
                      <span>1800-725-9946</span>
                    </div>

                  </a>
                  
        {
          (cookies.get('agentSite'))?<MenuList className="no-padd">
          <MenuItem className="head-agent-details">
            <Button
              ref={anchorRefCalc1}
              id="composition-button"
              aria-controls={openCalc1 ? 'composition-menu' : undefined}
              aria-expanded={openCalc1 ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleToggleCalc1} 
              className='has-dropdown'
              title={staffDetail[0]?.firstname}
            >

              {staffDetail.map((agent) => {
                return (
                  <>
                    {(() => {
                      // console.log('agent', agent.staffid);
                      let imagePath = agent.profile_image;
                      if (imagePath != null) {

                          if (imagePath.includes('https://www.rateshop.ca/')) {
                              return (
                                <img src={agent.profile_image} /> 
                               
                               
                              )
                          }

                          else if (agent.profile_image != null) {
                              return (
                                  <img
                                    src={`https://crm.rateshop.ca/uploads/staff_profile_images/${agent.staffid}/thumb_${agent.profile_image}`}
                                    alt={agent.firstname}
                                  />
                                 
                              )
                          } else {
                              return (
                                  <img
                                    className="img"
                                    src={defaultProfileImage}
                                    alt={agent.firstname}
                                  />
                                  
                              )
                          }
                      } else {
                          return (
                            <figure>
                                  <img
                                    className="img"
                                    src={defaultProfileImage}
                                    alt={agent.firstname}
                                  />
                                </figure>
                             
                          )
                      }
                  })()}
                  </>
                );
              })}
            </Button>
            <Popper
              open={openCalc1}
              anchorEl={anchorRefCalc1.current}
              role={undefined}
              placement="bottom-end"
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === 'right-top' ? 'right top' : 'right top',
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleCloseCalc1}>
                      <MenuList
                        autoFocusItem={openCalc1}
                        id="composition-menu"
                        aria-labelledby="composition-button"
                        onKeyDown={handleListKeyDownCalc1}
                      >
                        <p><PersonIcon/><strong>{staffDetail[0].firstname} {staffDetail[0].lastname}</strong></p>
                        <p><EmailIcon/><strong><a href="mailto:{staffDetail[0].email}">{staffDetail[0].email}</a></strong></p>
                        <p><LocalPhoneIcon/><strong><a href="tel:{staffDetail[0].cell_number}">{staffDetail[0].cell_number}</a></strong></p>
                        <p><PhoneAndroidIcon/><strong>{staffDetail[0].phonenumber} Ext:{staffDetail[0].extension}</strong></p>
                        {(staffDetail[0].Fax_Number != '')?
                          <p><FaxIcon/><strong>{staffDetail[0].Fax_Number}</strong></p>
                        :''}
                        <p><DocumentScannerIcon/><strong>{staffDetail[0].lic}</strong></p>
                        <p><VerifiedUserIcon/><strong>{staffDetail[0].desigination}</strong></p> 
                        </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </MenuItem>
        </MenuList>:''

                }

        
                </div>
              </Grid>
              {/* <Grid md={8} lg={8} item className="d-flex justify-content-end align-items-center"> */}
              {/* <Button variant="contained" className="apply-now" color="secondary" >
                  <img alt="Rateshop" src={searchImage} /> 
                Apply Now
              </Button> */}
              {/* <MenuIcon className="mobile-toggle"/> */}

              {/* </Grid> */}
              {/* navbar */}
              {/* <Grid xs={12} item> */}

              {/* </Grid> */}
            </Grid>
          </header>
        </Container>
      </div>
    </>
  );
};

export default Header;
