import React from 'react';
import { 
    Container,
    Grid,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import {
    homevalue,
    cmhcinsurance,
    landtransfer,
    affordabilitycalc,
    paymentcalc,
} from "../../../core/Images";
const Calculator = () => {

    return (
        <section className="calculator">
            <Container>
                <Grid container className="align-items-center">
                    <Grid sm={12} md={12} lg={12} item>
                        <div className="heading">
                            <h3>Calculators</h3>
                        </div>
                        <ul className="cal-inner">
                            {/* data-aos="zoom-in-down" data-aos-delay="300" data-aos-duration="1000" */}
                            <li >
                                <NavLink to="/mortgage-payment-calculator">
                                    <img src={paymentcalc} alt="Mortgage Payment Calculator" widht="90" height="90"/>
                                    <span>Mortgage Payment Calculator</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/affordability-calculator">
                                    <img src={affordabilitycalc} alt="Affordability Calculator"  widht="90" height="90"/>
                                    <span>Affordability Calculator</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/land-transfer-tax-calculator">
                                    <img src={landtransfer} alt="Land Transfer Tax Calculator" widht="90" height="90" />
                                    <span>Land Transfer Tax Calculator</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/cmhc-insurance-calculator">
                                    <img src={cmhcinsurance} alt="CMHC Insurance Calculator"  widht="90" height="90"/>
                                    <span>CMHC Insurance Calculator</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/cost-closing-calculator">
                                    <img src={homevalue} alt="Cost Closing Calculator"  widht="90" height="90"/>
                                    <span>Cost Closing Calculator</span>
                                </NavLink>
                            </li>
                        </ul>
                    </Grid>
                </Grid>
            </Container>
        </section>
    );
}

export default Calculator;