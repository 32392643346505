import React from 'react';
import './info-bar.scss';
import { NavLink } from 'react-router-dom';
import logo from '../../assets/public/images/webp/rateshop-logo.webp';
import logo1 from '../../assets/public/images/apply-now/hands.png';
import logo2 from '../../assets/public/images/apply-now/banner-2.png';
import logo3 from '../../assets/public/images/apply-now/banner-3.png';
import logo4 from '../../assets/public/images/apply-now/banner-1.png';
import agent from '../../assets/public/images/agent-3.png';
const InfoBar = () => {
    return (
        <>
            <div className='info-bar'>
                <div className="agent-contact blue-bg">
                    <div className="agent-left">
                        <img src={logo1} />
                        <h3>You Have Questions <br></br>We have Answers! </h3>
                    </div>
                    <div className="center-img">
                        <img src={logo2} />
                        <img src={logo3} />
                        <img src={logo4} />
                    </div>
                    <div className="agent-right">
                        <div className="agent-contact-outer d-flex">
                            <div className='agent-img'>
                                <img src={agent} />
                                <span className='status-display'></span>
                            </div>
                            <div className='msg-info'>
                                <h5>Sarah P. Mortgage Agent</h5>
                                <p><span className='status'>Away </span><span className='average-respone-time'> Avg. respone time: 1Hour</span></p>
                            </div>
                        </div>
                        <div className='call-now'>
                            <p>Call Now for a Free Rate Quote</p>
                            <a href='tel:1800-725-9946'>1800-725-9946</a>
                        </div>
                    </div>
                </div>
                <div className="terms-policy">
                    <div className='terms'>
                        <NavLink to='/'>Terms</NavLink>
                        <NavLink to='/'>Privacy</NavLink>
                    </div>
                    <div className="powered-logo">
                        <p>Powered By</p>
                        <img src={logo} />
                    </div>
                </div>
                <div className="disclaimer">
                    <p><strong>Disclaimer:</strong> RateShop Inc. is a Mortgage Brokerage offering lowest mortgage rates to Canadians. We are provicially licensed in the following provinces: Mortgage Brokerage Ontario FSRA #12733, British Columbia BCFSA #MB600776, Alberta RECA #00523056P, Saskatchewan FCAA #00511126, PEI #160622, New Brunswick FCNB #88426, Newfoundland/Labrador. Our Quebec Mortgage Transactions are serviced by Orbis Mortgage Group AMF# 181136.</p>
                </div>
            </div >
        </>
    );
}

export default InfoBar;