import React from 'react';
const StarSvg = () => {

	return (
		<>
        <svg width="22" height="20" viewBox="0 0 22 20"  xmlns="http://www.w3.org/2000/svg">
<path d="M10.6802 0.374843C10.9283 -0.121611 11.3418 -0.121611 11.6003 0.374843L14.154 5.4407C14.4021 5.93715 15.0741 6.41334 15.6324 6.4944L21.329 7.30493C21.8873 7.38599 22.0217 7.77099 21.6082 8.156L17.483 12.0972C17.0798 12.4822 16.8213 13.2522 16.9144 13.7994L17.8862 19.3617C17.9793 19.9088 17.6484 20.1519 17.1418 19.8885L12.0449 17.2644C11.5383 17.001 10.7215 17.001 10.2149 17.2644L5.11793 19.8885C4.61133 20.1519 4.28049 19.9088 4.37354 19.3617L5.34538 13.7994C5.43843 13.2522 5.1903 12.4822 4.77675 12.0972L0.672283 8.156C0.269073 7.77099 0.393139 7.38599 0.951429 7.30493L6.64806 6.4944C7.20635 6.41334 7.87836 5.93715 8.12649 5.4407L10.6802 0.374843Z"/>
</svg>
		</>
	)
}

export default StarSvg
