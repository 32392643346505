import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { NavLink } from "react-router-dom";
import { posts } from '../../../../config/posts.js';
import { Button, Container, Grid } from "@mui/material";
import PartnerLogo from '../../../../components/shared/partner-logo/PartnerLogo';
import Partner from '../../../../components/shared/partner/Partner'; 
import { reading , clock,} from "../../../../core/Images";
import Testimonials from "../../../../components/shared/testimonial/Testimonials";
import './Blog-category.scss'; 
import { Oval } from 'react-loader-spinner';
import { useParams as params, useNavigate } from "react-router-dom";
const BlogsCategory = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const itemsPerPage = 8; // Number of posts to display per page
  const [allBlogs, setAllBlogs] = React.useState([]);
  const { cname } = params();
  const navigate = useNavigate();
  
  // const convertToSlug = (Text) => {
  //   return Text.toLowerCase()
  //   .replace(/ /g, "-")
  //   .replace(/[^\w-]+/g, "");
  // }
  // const [allCategories, setAllCategories] = React.useState([]);
  // Calculate the total number of pages based on the posts length
  useEffect(() => {
   
    const token = {
      Authorization: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoiQWJoaXNoZWsgUHVyaSIsIm5hbWUiOiJSZWFjdCBSUyIsInBhc3N3b3JkIjpudWxsLCJBUElfVElNRSI6MTY3NjY0MjQyNn0.kcYNItsCPFOtwPIgffGx1Zf-BGDN_Vsx-r0vnGcucOs',
      cname: cname,
  };
  // const apiData = {...values, ...slugObj};
  // console.log('valuesss', apiData);
  // setShowLoader(true);
 
  fetch('https://crm.rateshop.ca/ReactController/blogsByCategory', {
      method: 'POST',
      headers: {
          Accept: 'application/form-data',
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(token),
  })
      .then(res => res.json())
      .then(
          (result) => {

              // console.log('resukt', result);
              if(result.blogs.length == 0){
                navigate('/404', { replace: true });
              }
              setAllBlogs(result.blogs);
              setTotalPages(Math.ceil(result.blogs.length / itemsPerPage));
              // setAllCategories(result.categories);
              // console.log('cat', result.categories);
          }
      )
  }, []);

  // Function to handle page changes
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  // Get the paginated posts for the current page
  const getPaginatedPosts = () => {
    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return allBlogs.slice(startIndex, endIndex);
  };
  if (allBlogs.length == 0) {
    return <Oval
        height={60}
        width={60}
        color="#FFFFFF"
        wrapperStyle={{}}
        wrapperClass="csLoader"
        visible={true}
        ariaLabel='oval-loading'
        secondaryColor="#EEEEEE"
        strokeWidth={4}
        strokeWidthSecondary={4}
    />;
}
  return (
    <div>
         <section className="top-blog bg-white blog-categories">
        <Container>
            <div className="heading">
                <h3><span>Latest</span> Learning Center</h3>
            </div>
            <Grid container className="top-blog-container align-items-start">  
      {/* Render the paginated posts */}
      {getPaginatedPosts().map((item) => (
        <Grid xs={12} sm={6} md={6} lg={3} item className="blog-col" key={item.id}>
          <div className="blog-inner">
            <NavLink to={`/learning-center/${item.slug}`}>
              <figure>
              <img src={`https://crm.rateshop.ca/assets/blogs/${item.social_media_image}`} alt="blog" />
              </figure>
            </NavLink>
            <div className="blog-content">
              <p className="category">
              {item.categoryname} <span className="view-blog"><img src={clock} alt="View"/> {item.reading_time} Minutes Read  </span>
              </p>
              <NavLink to={`/learning-center/${item.slug}`}>{item.title}</NavLink>
            </div>
          </div>
        </Grid>
      ))}

        {/* Render the pagination component */}
        <ReactPaginate
            pageCount={totalPages}
            pageRangeDisplayed={5}
            marginPagesDisplayed={2}
            onPageChange={handlePageChange}
            containerClassName="cspagination"
            activeClassName="active" 
        />
        </Grid> 
        </Container>
        </section>
        {/* blog top section ends here */} 
        {/* Partner Logo Start*/}
        {/* <PartnerLogo/> */}
        {/* Partner Logo End*/}

        {/* partner logo Start*/}
        {/* <Partner/> */}
        <Testimonials />
        {/* partner logo End*/}
    </div>
  );
};

export default BlogsCategory;