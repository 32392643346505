import React from "react";  
import { Container } from "@mui/material"; 
import './footer.scss'  

const Footer = () => { 
   
  
  return (
    <> 
      <footer className="join-footer">
        <Container>
            <p class="text-center">RateShop Inc. is a Mortgage Brokerage offering lowest mortgage rates to Canadians. We are provincially licensed in the following provinces: Mortgage Brokerage Ontario FSRA #12733, British Columbia BCFSA #MB600776, Alberta RECA #00523056P, Saskatchewan FCAA #00511126, PEI #160622, New Brunswick FCNB #88426, Newfoundland/Labrador. Our Quebec Mortgage Transactions are serviced by Orbis Mortgage Group AMF# 181136.
            
            {/* Copyright 2021 RateShop Inc. All Rights Reserved. RateShop.ca FSCO #12733 */}
            </p>
        </Container>
    </footer> 
    </>
  );
};

export default Footer;