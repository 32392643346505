const banks = [

    {title:"ADS Canadian Bank"},
    {title:"Alterna Bank"},
    {title:"B2B Bank"},
    {title:"Bank of Montreal"},
    {title:"Bank of Nova Scotia"},
    {title:"Bridgewater Bank"},
    {title:"Canadian Imperial Bank of Commerce"},
    {title:"Canadian Tire Bank"},
    {title:"Canadian Western Bank"},
    {title:"Continental Bank of Canada"},
    {title:"Concentra Bank"},
    {title:"DirectCash Bank"},
    {title:"Duo Bank"},
    {title:"Equitable Bank"},
    {title:"Exchange Bank of Canada"},
    {title:"First Nations Bank of Canada"},
    {title:"General Bank of Canada"},
    {title:"Haventree Bank"},
    {title:"Home Bank"},
    {title:"HomeEquity Bank"},
    {title:"Laurentian Bank of Canada"},
    {title:"Manulife Bank of Canada"},
    {title:"Motus Bank"},
    {title:"National Bank of Canada"},
    {title:"Rogers Bank"},
    {title:"Royal Bank of Canada"},
    {title:"Street Capital Bank of Canada"},
    {title:"Tangerine Bank"},
    {title:"Toronto-Dominion Bank"},
    {title:"Vancity Community Investment Bank"},
    {title:"Versa Bank"},
    {title:"Wealth One Bank of Canada"},
    {title:"Zag Bank"},
    {title:"Vancity"},
    {title:"Coast Capital Savings"},
    {title:"Meridian Credit Union"},
    {title:"Servus Credit Union"},
    {title:"First West Credit Union"},
    {title:"Conexus Credit Union"},
    {title:"Steinbach Credit Union"},
    {title:"Affinity Credit Union"},
    {title:"Alterna Savings and Credit Union Limited"},
    {title:"Connect First Credit Union"},
    {title:"Assiniboine Credit Union"},
    {title:"BlueShore Financial Credit Union"},
    {title:"UNI Financial Cooperation"},
    {title:"FirstOntario Credit Union Limited"},
    {title:"DUCA Credit Union"},
    {title:"Libro Credit Union"},
    {title:"Innovation Credit Union"},
    {title:"Access Credit Union"},
    {title:"G&F Financial Group"},
    {title:"Pace Savings & Credit Union"}

];

export default banks;