import React from 'react';
import { Outlet } from "react-router-dom";
import Header from '../components/apply-now/header/Header';

const SweaptakeHead = () => { 
  return ( 
    <>
      <Header />
      <Outlet />
    </>
  )
}
export default SweaptakeHead
