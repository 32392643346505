import React, { useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import {TextField} from "@mui/material";
import { useFormContext,Controller } from 'react-hook-form';
import LockIcon from '@mui/icons-material/Lock';
import Checkbox from '@mui/material/Checkbox';
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

import { styled  } from '@mui/system';  
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup'; 
import Switch from '@mui/material/Switch';
const QuickRadio = (props, ref) => {

    const {
        id,
        label,
        name,
        value,
        image,
        description,
        data_id=null,
        register = () => { },
        clickEventHandler = () => {}
    } = props;

    return (

        <div className="step-radio-outer">
            <input
                type="radio"
                name={name}
                value={value}
                data-id={data_id}
                id={id}
                {...register(name, { required: {value:true,message:'This field is required.'} })}
                onClick={(e) => {clickEventHandler()}}

            />
            <label htmlFor={id} >
                {image && <img src={image} />}
                <h4>{label}</h4>
            </label>
            {description && <span>{description}</span>}
        </div>
    );
}

 

const StepNine = (props) => {
 
const label = { inputProps: { 'aria-label': 'Switch demo' } };
    const {control} = useFormContext();

    const [isRef, setIsRef] = useState(true);
    
    // console.log('isRef', isRef);
    const handleValidate = (value) => {
        const isValid = isValidPhoneNumber(value);
        return isValid
    }
    
    return (

        <>
            <div className="heading">
                <h4>REFERRAL</h4>
                <p>Do you have any Referral?</p>
            </div>
            <div className="checkout-offer-form">
                <div className="quick-com-input">
            <div className="referral-input d-flex flex-column align-items-center justify-content-center">
            <Controller
                             name="has_referral"
                                // value="1"
                                // onClick={(e) => console.log(e.target.value)}
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (

                                    <FormGroup>
                                   
                                    <Typography component="div">
                                      <Grid component="label" container alignItems="center" spacing={1}>
                                        <Grid item>NO</Grid>
                                        <Grid item>
                                        <Switch {...label}  checked={value == '1' ? true : false}
                                        onChange={onChange} 
                                         onClick={(e) => setIsRef(value)}
                                        />
                                        
                                         
                                        </Grid>
                                        <Grid item>Yes</Grid>
                                      </Grid>
                                    </Typography>
                                    
                                  </FormGroup>
                                  
                                  
                                )} 
                            />

                            {!isRef ? 
                            <div>   
                                 <Controller 
                      
                                 name="referral_first_name"
                                 control={control}
                                 defaultValue=""
                                 render={({field:{onChange,value},fieldState:{error}}) => (
           
                                     <TextField
                                         id="first-name"
                                         variant="outlined"
                                         placeholder="Full Name"
                                         fullWidth
                                         margin="normal"
                                         value={value}
                                         onChange={onChange}
                                         error={!!error}
                                         helperText={error ? error.message : null}
                                     />
                                   
                                 )}
                                 rules={{
           
                                      required  : {value:true,message:'Please fill this field.'},
                                      pattern   : {value:/^[A-Za-z -]+$/,message:'Only allow alphabets'},
                                      minLength : {value:2,message:'Minimun length should be greater than 2'},
                                      maxLength : {value:100,message:'Maximum length should be less than 100'}
                                 }}
                              />
                              <Controller 
                      
                                name="referral_last_name"
                                control={control}
                                defaultValue=""
                                render={({field:{onChange,value},fieldState:{error}}) => (
          
                                    <TextField
                                        id="last-name"
                                        variant="outlined"
                                        placeholder="Last Name"
                                        fullWidth
                                        margin="normal"
                                        value={value}
                                        onChange={onChange}
                                        error={!!error}
                                        helperText={error ? error.message : null}
                                    />
                                  
                                )}
                                rules={{
          
                                     required  : {value:true,message:'Please fill this field.'},
                                     pattern   : {value:/^[A-Za-z -]+$/,message:'Only allow alphabets'},
                                     minLength : {value:2,message:'Minimun length should be greater than 2'},
                                     maxLength : {value:100,message:'Maximum length should be less than 100'}
                                }}
                             />
                             </div>
                            :
                                 ''
                            }
                        
                    
            </div> 
            </div> 
            </div> 
        </>
    );
}

export default StepNine;