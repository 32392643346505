import React from 'react';
const TickSvg = () => {

	return (
		<><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.1792 16.0583C11.9917 16.0583 11.8125 15.9875 11.675 15.8625L7.4625 12.0125C7.15833 11.7333 7.1375 11.2583 7.41667 10.9542C7.69583 10.65 8.17083 10.6292 8.475 10.9083L12.1333 14.25L22.6958 2.65C22.975 2.34583 23.45 2.32083 23.7542 2.6C24.0583 2.87917 24.0833 3.35417 23.8042 3.65833L12.7333 15.8125C12.6 15.9583 12.4125 16.0458 12.2125 16.0583C12.2042 16.0583 12.1917 16.0583 12.1792 16.0583ZM21.7583 8.13333C22.2333 9.32917 22.5 10.6333 22.5 12C22.5 17.7917 17.7917 22.5 12 22.5C6.20833 22.5 1.5 17.7917 1.5 12C1.5 6.20833 6.20833 1.5 12 1.5C14.7583 1.5 17.2708 2.57083 19.15 4.31667L20.1583 3.20833C18.0167 1.22083 15.1458 0 12 0C5.38333 0 0 5.38333 0 12C0 18.6167 5.38333 24 12 24C18.6167 24 24 18.6167 24 12C24 10.1833 23.5917 8.4625 22.8667 6.91667L21.7583 8.13333Z" fill="#09318B"/>
</svg>
		</>
	)
}

export default TickSvg
