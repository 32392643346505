import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import {Container,Button} from "@mui/material/";
import './team.scss';
import { teamMemberOne,teamMemberTwo,teamMemberThree, defaultProfileImage } from "../../../core/Images";
import axios from 'axios';
import { Oval } from 'react-loader-spinner';
import { 
  // Route, Routes, 
  useNavigate,
  //  NavLink, 
   Link } from "react-router-dom";
const Teams = () => {
  var options=  {

    className:"owl-theme",
    margin:40,
    responsiveClass: true,
    autoplay:true,
    dots:false,
    loop: true,
    responsive: {
      0: {
          items: 2,
      },
      575: {
          items: 3,
      },
      992: {
          items: 4,
      },
      1400:{
        items: 5,
      }
    }
  }
  const data = {
    type: 'team',
    Authorization: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoiQWJoaXNoZWsgUHVyaSIsIm5hbWUiOiJSZWFjdCBSUyIsInBhc3N3b3JkIjpudWxsLCJBUElfVElNRSI6MTY3NjY0MjQyNn0.kcYNItsCPFOtwPIgffGx1Zf-BGDN_Vsx-r0vnGcucOs',
   
  };
  const [agentDetail, setAgentDetail] = React.useState({});
  const navigate = useNavigate();
  React.useEffect(() => {

        axios.post(`https://crm.rateshop.ca/ReactController/getStaffSlugs`, { data })
            .then(res => {
              // console.log('aa', res.data.data);
            setAgentDetail(res.data.data);
            
        })

  }, []);
  const [showLoader, setShowLoader] = React.useState(false);
  if ((Object.keys(agentDetail).length === 0 && agentDetail.constructor === Object) || showLoader) {
    return <Oval
        height={60}
        width={60}
        color="#FFFFFF"
        wrapperStyle={{}}
        wrapperClass="csLoader"
        visible={true}
        ariaLabel='oval-loading'
        secondaryColor="#EEEEEE"
        strokeWidth={4}
        strokeWidthSecondary={4}
    />;
}
  return (
    <>
      <section className="our-team"> 
      <Container>
          <div className="heading">
            <h3><span>Our</span> Mortgage Experts</h3> 
        </div>
 
          <OwlCarousel
            // items={3}
            // className="owl-theme"
            // loop
            // nav
            // margin={40}
            // autoplay={true}
            // dots={false}
            {...options}
          >
              {agentDetail.map((agent) => {
                return (
                  <div>
                    {(() => {
                      // console.log('agent', agent.staffid);
                      let imagePath = agent.profile_image;
                      if (imagePath != null) {

                          if (imagePath.includes('https://www.rateshop.ca/')) {
                              return (
                                <figure>
                                  <img
                                    className="img"
                                    src={agent.profile_image}
                                    alt="team-member-1"
                                  />
                                </figure>
                               
                              )
                          }

                          else if (agent.profile_image != null) {
                              return (
                                <figure>
                                  <img
                                    className="img"
                                    src={`https://crm.rateshop.ca/uploads/staff_profile_images/${agent.staffid}/thumb_${agent.profile_image}`}
                                    alt="team-member-1"
                                  />
                                </figure>
                                 
                              )
                          } else {
                              return (
                                <figure>
                                  <img
                                    className="img"
                                    src={defaultProfileImage}
                                    alt="team-member-1"
                                  />
                                </figure>
                                  
                              )
                          }
                      } else {
                          return (
                            <figure>
                                  <img
                                    className="img"
                                    src={defaultProfileImage}
                                    alt="team-member-1"
                                  />
                                </figure>
                             
                          )
                      }
                  })()}
                    
                    <div className="info-details">
                      <h4>{agent.firstname} {agent.lastname}</h4>
                      <p>{(agent.desigination == 'Mortgage Agent_l2')?'Mortgage Agent Level 2': agent.desigination}</p>
                    </div>
                  </div>
                );
              })}

          </OwlCarousel>
        <div className="d-flex justify-content-center mt-80">
        <Link to='/apply/'><Button variant="contained">
            Get Started Now
          </Button>
                                    </Link>
         
        </div>
        </Container>
      </section>
    </>
  );
};

export default Teams;
