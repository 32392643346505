import React, {useEffect, useState} from "react";  
import "./apply-now.scss";  
import QuickApp from "../../../application/quick/QuickApp"; 
// import QuickThanks from "../../../components/popup/QuickThanks";
// import QuickFormCompletion from "../../../components/popup/QuickFormCompletion";
import {useParams} from 'react-router-dom';
import { Route, Routes, useNavigate, NavLink } from "react-router-dom";
import axios from 'axios';
import { Oval } from 'react-loader-spinner';
import Helmet from 'react-helmet';
const ApplyNow = () => {
  const {slug} = useParams();
  const [showLoading, setShowLoading] = useState(true);
    // console.log(slug);
    const data = {
      type: 'single',
      Authorization: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoiQWJoaXNoZWsgUHVyaSIsIm5hbWUiOiJSZWFjdCBSUyIsInBhc3N3b3JkIjpudWxsLCJBUElfVElNRSI6MTY3NjY0MjQyNn0.kcYNItsCPFOtwPIgffGx1Zf-BGDN_Vsx-r0vnGcucOs',
      slug: slug
    };
    const navigate = useNavigate();
    useEffect(() => {
      if(slug != undefined){

          axios.post(`https://crm.rateshop.ca/ReactController/getStaffSlugs`, { data })
              .then(res => {
              // console.log(res.data.status);
              if(res.data.status != 200){
                  navigate('/404', { replace: true });
              }else{
                setShowLoading(false);
              }
          })

      }else{
        setShowLoading(false);
      }

    }, []);
    if (showLoading) {
      return <Oval
          height={60}
          width={60}
          color="#09318b"
          wrapperStyle={{}}
          wrapperClass="csLoader"
          visible={true}
          ariaLabel='oval-loading'
          secondaryColor="#2661e7"
          strokeWidth={4}
          strokeWidthSecondary={4}
      />;
  }
  return (
    <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Apply for a Mortgage Online | Fast & Easy Application | RateShop.ca</title>
          <meta name='description' content='Apply for a mortgage online with RateShop.ca. Our fast and easy application process helps you secure the best mortgage rates and terms quickly.' />
          <meta name='keywords' content=" apply for a mortgage, online mortgage application, fast mortgage application, easy mortgage process, best mortgage rates, RateShop, mortgage application, secure mortgage rates, mortgage terms, mortgage application process, quick mortgage application, mortgage approval, mortgage application Canada, mortgage application guide, mortgage application online, mortgage application tips, mortgage application help, mortgage application form, mortgage application steps, mortgage application checklist" />
        </Helmet>
    {/* <QuickFormCompletion/>
    <QuickThanks/>  */} 
    {/* Quick App Start*/}
        <QuickApp/>
    {/* Quick App End*/}
 
    </>
  );
};

export default ApplyNow;
