// import React from "react";
// import ReactDOM from "react-dom";
// import App from "../src/app/App";
// import { BrowserRouter } from "react-router-dom";
import ScrollToTop from '../src/app/ScrollToTop';
// const app = (
//   <BrowserRouter>
//   <ScrollToTop/>
//     <App />
//   </BrowserRouter>
// );
// ReactDOM.render(app, document.getElementById("root"));

import React from "react";
// import ReactDOM from "react-dom/client";
// import "./index.css";
// import App from "../src/app/App";
// import reportWebVitals from "./reportWebVitals";

import {createRoot} from 'react-dom/client';
import App from "../src/app/App";
import {BrowserRouter as Router} from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

//  wrap App in Router

root.render(
  <Router>
    <ScrollToTop></ScrollToTop>
    <CookiesProvider>
      <App />
    </CookiesProvider>
  </Router>
);