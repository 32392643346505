import React from 'react';
const EncryptedSvg = () => {
    return (
        <>
        <svg width="24" height="29" viewBox="0 0 24 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.24413 2.9378C3.12525 3.08531 4.91884 2.90891 6.63411 2.44509C8.35706 1.97975 10.014 1.2194 11.6125 0.202039L11.8705 0.0378016C11.9519 -0.0139026 12.0487 -0.0139026 12.13 0.0378016L12.388 0.202039C13.9866 1.2194 15.6435 1.97823 17.3665 2.44509C19.0833 2.90891 20.8769 3.08531 22.7564 2.9378L23.2094 2.90283C23.3369 2.89218 23.4505 2.98342 23.4659 3.10964L23.5242 3.55977C24.1492 8.4291 24.3535 13.1905 22.91 17.523C21.0811 23.0082 17.1561 26.3826 12.1116 28.9739C12.041 29.0104 11.9627 29.0104 11.8905 28.9739C6.84602 26.3811 2.91948 23.0082 1.09057 17.523C-0.3529 13.1889 -0.148664 8.42758 0.476327 3.55825L0.534681 3.10812C0.551572 2.9819 0.663671 2.89218 0.791127 2.9013L1.24413 2.9378ZM7.01033 3.81525C5.33959 4.2669 3.6105 4.46916 1.81538 4.39769C1.28099 8.85186 1.15814 13.1813 2.45726 17.0774C3.77788 21.0389 6.59572 24.5897 12.0011 27.4289C17.4064 24.5912 20.2242 21.0404 21.5449 17.0774C22.8424 13.1828 22.7196 8.85338 22.1852 4.39769C20.3901 4.46916 18.661 4.2669 16.9902 3.81525C15.2565 3.34687 13.5966 2.61237 12.0011 1.64215C10.4056 2.61085 8.74557 3.34535 7.01033 3.81525Z" fill="white"/>
            <path  d="M9.72537 12.1946H14.2754V9.87097C14.2754 8.93573 13.7057 8.33809 12.9763 8.075C12.6691 7.96399 12.3359 7.90925 12.0027 7.90925C11.6694 7.90925 11.3362 7.96399 11.0291 8.075C10.2982 8.33809 9.72537 8.93725 9.72537 9.87097V12.1946ZM15.53 12.2554C16.2072 12.4471 16.7062 13.0675 16.7062 13.7959V18.1877C16.7062 19.0682 15.9784 19.7906 15.0877 19.7906H8.9115C8.02238 19.7906 7.29297 19.0698 7.29297 18.1877V13.7974C7.29297 13.0675 7.79204 12.4471 8.46924 12.257V9.87097C8.46924 8.32896 9.40443 7.34202 10.6007 6.91014C11.0429 6.75046 11.522 6.67139 12.0011 6.67139C12.4802 6.67139 12.9594 6.75046 13.4016 6.91014C14.5963 7.3405 15.5284 8.32592 15.5284 9.87097V12.2554H15.53ZM11.9996 14.059C12.6092 14.059 13.1022 14.5487 13.1022 15.1509C13.1022 15.5858 12.8457 15.9614 12.4726 16.1378L12.8995 17.9262H11.0997L11.5266 16.1378C11.155 15.9629 10.897 15.5858 10.897 15.1509C10.897 14.5487 11.3915 14.059 11.9996 14.059Z" fill="white"/>
        </svg>
    </>
    )
}
export default EncryptedSvg      
