import React, { useEffect } from "react";
import {
    Button,
    Container, Grid
} from "@mui/material"; 
import "aos/dist/aos.css"; 
import { blogsDatas } from "../../../../config/blog";
import { Markup } from 'react-render-markup';
import Testimonials from "../../../../components/shared/testimonial/Testimonials";
import Helmet from 'react-helmet'; 
import './Blog-detail.scss';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LayersIcon from '@mui/icons-material/Layers';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'; 
import { useNavigate, Link } from "react-router-dom";
import Typography from '@mui/material/Typography'; 
import Breadcrumbs from '@mui/material/Breadcrumbs';
const BlogDetails = function () { 
    const [staticSlug, setStaticSlug] = React.useState(window.location.pathname.replace('/',""));  
    const validPosts = blogsDatas.filter(blogsData => blogsData !== undefined);
    const url = staticSlug;
    const path = `${url.split('/').pop()}`;  
    const postFiltered = validPosts.find(blogsData => String(blogsData.blog_slug) === path); 
    const [insuredFiveYearFixed, setInsuredFiveYearFixed] = React.useState([]); 
    const navigate = useNavigate();    
    useEffect(() => {
        const token = {
            Authorization: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoiQWJoaXNoZWsgUHVyaSIsIm5hbWUiOiJSZWFjdCBSUyIsInBhc3N3b3JkIjpudWxsLCJBUElfVElNRSI6MTY3NjY0MjQyNn0.kcYNItsCPFOtwPIgffGx1Zf-BGDN_Vsx-r0vnGcucOs',
        };
        fetch('https://crm.rateshop.ca/ReactController/mortgageRates', {
            method: 'POST',
            headers: {
                Accept: 'application/form-data',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(token),
        })
            .then(res => res.json())
            .then(
                (result) => {
                    const insuredFiveYearFixed = Math.min(...JSON.parse(result.rates.insured_five_year_fixed).filter(function (el) {
                        return el != '';
                    }));
                    const insuredFiveYearVariable = Math.min(...JSON.parse(result.rates.insured_five_year_variable).filter(function (el) {
                        return el != '';
                    }));
                    setInsuredFiveYearFixed(insuredFiveYearFixed) 

                }
            ) 
    }, []);
 

    return (<>
        {/* blog banner section start here */}
        <Helmet>
            <meta charSet="utf-8" />
                <title>{postFiltered?.meta_title}</title>
                <meta name='description' content={postFiltered?.meta_description} />
        </Helmet>
        <section className="common-image-bg blog-banner detail-banner">
            <Container>
                <Grid container>
                    <Grid xs={12} item>
                        <div className="banner-content">
                            <div className="heading">
                                <h1>{postFiltered.title}</h1>
                                <p className="text-center">{postFiltered.categoryname}</p>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </section>
        {/* blog banner section end here */}
        <Container>
            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" to="/">
                    Home
                </Link>
                <Typography color="text.primary">{postFiltered.title}</Typography>
            </Breadcrumbs>
        </Container>
        {/* latest blog section start here */}
        <section className="latest-blog">
            <Container>
                <Grid container>
                    <Grid sm={12} md={12} lg={8} item>
                        <div className="latest-blog-left blog-detail-left pr-0">
                            <Grid container>
                                <Grid xs={12} item>
                                    <figure>
                                        <img src={postFiltered.img} alt="blog" />
                                    </figure>
                                    <div className="blog-text-inner">
                                        <p className="category">
                                            <span><PersonOutlineIcon /> {postFiltered.author}</span>
                                            <span><CalendarMonthIcon /> {postFiltered.created_at}</span>
                                            <span><LayersIcon /> {postFiltered.categoryname}</span>
                                            <span><AccessTimeIcon /> {postFiltered.reading_time} Minutes Read</span>
                                        </p>
                                        <Markup markup={postFiltered.description} />
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid sm={12} md={12} lg={4} item>
                        <div className="latest-blog-right">
                            <div className="blog-content-wrap blog-side-nw">
                                <div className="blog-sidebar w-100">
                                    <div className="get-best-rate">
                                        <h6>5 Year - Fixed Term from</h6>
                                        <h2>{insuredFiveYearFixed}%</h2>
                                        <h4>Apply for your Best Rate in minutes.</h4>
                                        <p className="small-text">Everyone's rate is unique. What's yours?</p>
                                        <p>It is our job to get your lowest possible rate. Your rate qualification depends on certain factors, such as credit score and home equity as per regulations.</p>
                                        <Button onClick={() => navigate('/apply')} variant="contained">Get your Best Rate</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </section>  
        {/* <Partner/> */}
        <Testimonials />
        {/* partner logo End*/}
    </>)

}
export default BlogDetails;