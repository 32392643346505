import React, {
     useState, 
     useEffect } from "react";
import './applyForm.scss'
import {
    Container,
    Grid,
} from "@mui/material";
// import TextField from '@mui/material/TextField';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import * as Yup from "yup";
import { useFormik } from "formik";


  
import Checkbox from '@mui/joy/Checkbox';  
import Input from '@mui/joy/Input';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import swal from 'sweetalert';
import { Oval } from 'react-loader-spinner';
const ApplyForm = (props) => {
   
    const initialValues = {
        f_name: "",
        number: "",
        email: "",
        morge_type: "",
        termsCheck: false,
      };
      const [showLoader, setShowLoader] = useState(false);
      const handleFormSubmit = (values) => {
        // console.log('valuesss', values);
       
        const slugObj = {
            slug: props.props.Slug,
            Authorization: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoiQWJoaXNoZWsgUHVyaSIsIm5hbWUiOiJSZWFjdCBSUyIsInBhc3N3b3JkIjpudWxsLCJBUElfVElNRSI6MTY3NjY0MjQyNn0.kcYNItsCPFOtwPIgffGx1Zf-BGDN_Vsx-r0vnGcucOs',
            l_name: '',
            lead_sourcef1: 4,
            country: 'Canada',
            zip: '',
            city: '',
            state: '',
            address: '',
            status_type: 1,
            preferred_agent: props.props.staffid,
            isRoundRobin: 0,
        };
        const apiData = {...values, ...slugObj};
        // console.log('valuesss', apiData);
        setShowLoader(true);
        fetch('https://crm.rateshop.ca/ReactController/shortFormLeads', {
        method: 'POST',
        headers: {
            Accept: 'application/form-data',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(apiData),
        })
        .then(res => res.json())
        .then(
            (result) => {
            
            setShowLoader(false);
            // alert(result['message'])
            // if (result['status'] === 'ok') {
            //   window.location.href = '/';
            // }
            swal("Good job!", "Message Sent!", "success");
            
            }
        )
      }

      const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

      const formSchema = Yup.object({
        number: Yup.string()
        .required("This is required field").matches(phoneRegExp, 'Phone number is not valid'),
        termsCheck: Yup.boolean().oneOf([true],'This field is required.'),
        f_name: Yup.string()
          .required("This field is required."),
          email: Yup.string().email()
          .required("This field is required."),
        //   morge_type: Yup.string()
        //   .required("This field is required."),
          amount_request: Yup.number().typeError('Invalid Amount')
          .required("This field is required."),
        
      });
      const { values, errors, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: formSchema,
        onSubmit: (values) => {
          handleFormSubmit(values);
        },
      });
      if (showLoader) {
        return <Oval
            height={60}
            width={60}
            color="#ffffff"
            wrapperStyle={{}}
            wrapperClass="csLoader"
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor="#eeeeee"
            strokeWidth={4}
            strokeWidthSecondary={4}
        />;
    }
    return (
        <section className="apply-form">
            <Container>
                <Grid container className="align-items-center">
                    <Grid>
                        <div className="heading">
                            <h3>APPLY ONLINE</h3>
                            <p>We'll search 100's of Mortgages to get you the best deal, every time!</p>
                        </div>
                        <form className=""   autoComplete="off" onSubmit={handleSubmit}>
                        <div className='form-outer'>
                                <Input placeholder="Name"  name='f_name' onChange={handleChange} />
                                <p className="cs-error">{errors.f_name}</p>
                                <Input placeholder="Phone"  name='number' onChange={handleChange} />
                                <p className="cs-error" >{errors.number}</p>
                                <Input placeholder="Email" name='email' onChange={handleChange} />
                                <p className="cs-error" >{errors.email}</p>  
                                <Select placeholder="Mortgage request"  name="morge_type"
                                        onChange={handleChange}>
                                    <Option value="purchage">Purchase </Option>
                                    <Option value="refinance">Refinance</Option>
                                    <Option value="heloc">Heloc</Option>
                                    <Option value="renewal">Switch</Option>
                                    <Option value="privateFirst">Private 1st</Option>
                                    <Option value="privateSecond">Private 2nd</Option>
                                </Select> 
                                <p className="cs-error">{errors.morge_type}</p>
                                <Input name='amount_request'
                startDecorator="$" placeholder="Amount Requested" label="Amount Requested" onChange={handleChange} />
                                <p className="cs-error">{errors.amount_request}</p>  
                                {/* <Select placeholder="Amount Requested" 
                                        
                                        onChange={handleChange}
                                        name="best_time">
                                    <Option value="1">Option 1</Option>
                                    <Option value="2">Option 2</Option>
                                    <Option value="3">Option 3</Option>
                                </Select> */}
                                {/* <p className="cs-error" >{errors.best_time}</p> */}
                                <Checkbox  
                                 onChange={handleChange} 
                             
                                            name="termsCheck" label="I agree with terms & conditions and privacy policy" /> 
                                
                                <p className="cs-error" >{errors.termsCheck}</p>
                                <Button variant="contained" color="primary" type="submit">
                                    Submit
                                </Button>
                            </div>

                            {/* <div className='form-outer'>
                                <TextField id="" label="Name" name='fullName' onChange={handleChange} />
                                <p className="cs-error">{errors.fullName}</p>
                                <TextField id="" label="Phone" name='phone' onChange={handleChange} />
                                <p className="cs-error" >{errors.phone}</p>
                                <TextField id="" label="Email" name='email' onChange={handleChange} />
                                <p className="cs-error" >{errors.email}</p>
                                <FormControl className="">
                                    <InputLabel id="">Mortgage request</InputLabel>
                                    <Select
                                        labelId=""
                                        id=""
                                        value=""
                                        name="mortgage_type"
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={10}>Option 1</MenuItem>
                                        <MenuItem value={20}>Option 2</MenuItem>
                                        <MenuItem value={30}>Option 3</MenuItem>
                                    </Select>
                                </FormControl>
                                <p className="cs-error">{errors.mortgage_type}</p>
                                <TextField id="" name='amount_request' label="Amount Requested" onChange={handleChange} />
                                <p className="cs-error">{errors.amount_request}</p>
                                <FormControl className="">
                                    <InputLabel id="">Best time to Contact</InputLabel>
                                    <Select
                                        labelId=""
                                        id=""
                                        value=""
                                        onChange={handleChange}
                                        name="best_time"
                                    >
                                        <MenuItem value={10}>Option 1</MenuItem>
                                        <MenuItem value={20}>Option 2</MenuItem>
                                        <MenuItem value={30}>Option 3</MenuItem>
                                    </Select>
                                </FormControl>
                                <p className="cs-error" >{errors.best_time}</p>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={false}
                                            name="termsCheck"
                                            color="primary"
                                        />
                                    }
                                    label="I agree with terms & conditions and privacy policy"
                                />
                                <p className="cs-error" >{errors.termsCheck}</p>
                                <Button variant="contained" color="primary" type="submit">
                                    Submit
                                </Button>
                            </div> */}
                        </form>
                    </Grid>
                </Grid>
            </Container>
        </section>
    );
}

export default ApplyForm;



 