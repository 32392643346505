import React from 'react';
import './credit-score.scss';
import { Container, Grid} from "@mui/material";
import { Link } from 'react-router-dom';
import Partner from "../../../../components/shared/partner/Partner";
import Button from '@mui/material/Button';


import marble from '../../../../assets/public/images/landing-popup/marble.png'; 
import CreditScoreIMg from '../../../../assets/public/images/mockup-graphic-01-600x797.png';
import improving from '../../../../assets/public/images/mockup-graphic-01-600x797.png';
import personalized1 from '../../../../assets/public/images/small-mockups-01-300x300.png';
import personalized2 from '../../../../assets/public/images/small-mockups-02-300x300.png';
import personalized3 from '../../../../assets/public/images/small-mockups-03-300x300.png';
import mymarbleImg from '../../../../assets/public/images/graphic-3-desktop.png'; 
import { useNavigate } from "react-router-dom";
import Helmet from 'react-helmet';
const CreditScore = () => {
    const navigate = useNavigate();
    const routeChange = () =>{ 
        let path = `/apply`; 
        navigate(path);
      }
    return (
    <> 
        <Helmet>
          <meta charSet="utf-8" />
          <title>Check your Transunion Credit Score for Free and Download our credit report online</title>
          <meta name='description' content='Check the credit score with AI-driven personal finance trainer from Rate Shop with MyMarble and get the accurate result. We anylyze every scenario before providing the results. ' />
          <meta name="keywords" content="credit score, credit score canada, what is a good credit score, how to check credit score, credit score check"/>
        </Helmet>
        {/* credit score banner */}
         <section className='credit-card-banner credit-score'>
            <Container> 
                <Grid container className='align-items-center'>
                    <Grid xs={12} sm={12} md={6} lg={6} item >
                        <div className='banner-content'>
                            <div className="heading mb-10">
                                <h1 className='credit-head'>Get Your Credit Score in minutes!</h1>
                                </div>
                            <figure className='mb-4'>
                                <img src={marble} alt=""/>
                            </figure>
                            <div className="heading">
                                <h2><span>Get Your</span>  Free Credit Report</h2>  
                                <p className='cs-text-font mb-20'>RateShop and MyMarble have created an AI-driven personal finance trainer that offers free credit score insights, real-time plans and recommendations to make a positive impact on your overall finances.</p>
                                
                                <p className='cs-text-font mb-20'>We recognize the significance of your credit score in Canada, which is why we employ state-of-the-art simulators to assist you in enhancing it. Moreover, MyMarble analyzed millions of scenarios and designed a personalized debt relief plan specifically tailored to your needs. </p>
                                <p className='cs-text-font mb-20'>Together, the MyMarble and RateShop partnership aims to empower individuals to take control of their finances, offering support, guidance, and resources to help them achieve financial freedom and build a solid foundation for your future.</p>
                                <Button onClick={routeChange} variant="contained">Start a Mortgage Quote & Get a Free Credit Score</Button>
                            </div>
                        </div>
                    </Grid>
                    <Grid xs={12} sm={12} md={6} lg={6} item >
                        <figure>
                            <img src={CreditScoreIMg} alt=''/>
                        </figure>
                    </Grid>
                </Grid>
            </Container>
        </section>

        <section className='credit-score-content pt-0 pb-0'>
            <Container>
            <div className="cs-content"><div className="cs-content-inner">
<h2><span>Credit</span>  Score</h2>
<p>Unlocking the secrets of your credit score can be a game-changer when it comes to financial freedom. Whether you're dreaming of buying a new car, getting approved for that dream mortgage, or simply wanting to improve your overall financial health, understanding your credit score is crucial. In Canada, this magic number holds the key to unlocking countless opportunities and shaping your financial future. So, if you're ready to dive into the world of credit scores and discover how they impact your life in the Great White North, you've come to the right place! Welcome to our comprehensive guide on everything you need to know about credit scores in Canada. Get ready to take control of your finances like never before!</p>
</div>
<div className="cs-content-inner">
<h2><span>How to get</span> your Credit Report in Canada</h2>
<p>Getting your credit report in Canada is easier than you might think! Thanks to the Credit Reporting Act, every Canadian citizen is entitled to one free credit report per year from each of the major credit bureaus: Equifax and TransUnion. Now, let's dive into the steps you need to take to access this valuable financial information.</p>

<p>Start by visiting the official websites of both Equifax and TransUnion. These are trusted sources that will provide accurate and up-to-date information about your credit history. Look for their designated sections on obtaining a free credit report.</p>

<p>Next, fill out the necessary forms with your personal details such as name, address, social insurance number (SIN), and other relevant identification information. Make sure all the provided details are accurate and match what's on record.</p>

<p>Once you've completed these steps, submit your request online or through mail. It may take a few weeks for them to process your request and send you a copy of your credit report.</p>

<p>Remember to review both reports carefully when they arrive. Keep an eye out for any discrepancies or errors that could negatively impact your score. If there are inaccuracies found, don't panic - contact the respective bureau immediately to rectify those issues.</p>
</div>
</div></Container></section>

        {/* Improving*/}
        <section className='improving'> 
            <Container>
                <Grid container className='align-items-center'>
                    <Grid xs={12} sm={12} md={6} lg={6} item >
                        <figure>
                            <img src={improving} alt=''/>
                        </figure>
                    </Grid>
                    <Grid xs={12} sm={12} md={6} lg={6} item >
                        <div className='heading'>
                            <h3>Improving your overall financial wellness can help you get approved for lower interest rates!</h3>
                        </div>
                        <p>MyMarble makes sure you’re in the best financial position and that know before you go to apply for a financial product and service.</p>
                        <ul>
                            <li><p><strong>✓&nbsp; &nbsp;</strong> IMPROVE YOUR BUDGET</p></li>
                            <li><p><strong>✓&nbsp; &nbsp;</strong> INCREASE YOUR CREDIT SCORE</p></li>
                            <li><p><strong>✓&nbsp; &nbsp;</strong> ELIMINATE YOUR DEBT</p></li>
                            <li><p><strong>✓&nbsp; &nbsp;</strong> BUILD YOUR FINANCIAL LITERACY</p></li>
                        </ul>
                        <Button onClick={routeChange} variant="contained">Start a Mortgage Quote & Get a Free Credit Score</Button> 
                    </Grid>
                </Grid> 
            </Container>
        </section>

        {/* personalized */}
        <section className='personalized'>
            <Container>
                <Grid container>
                    <Grid xs={12} sm={12} md={6} lg={4} item >
                        <div className='personalized-inner'> 
                            <figure>
                                <img src={personalized1} alt=''/>
                            </figure>
                            <h4>Personalized Recommendations</h4>
                            <p>Whether you’re looking for a loan or seeking a mortgage, MyMarble has the right automated tools to improve your finances to help you achieve a lower cost of credit.</p>
                        </div>
                    </Grid>
                    <Grid xs={12} sm={12} md={6} lg={4} item >
                        <div className='personalized-inner'> 
                            <figure>
                                <img src={personalized2} alt=''/>
                            </figure>
                            <h4>Live and Actionable Insights</h4>
                            <p>We take every facet of your financial profile to provide you most accurate and up-to-date information to create the most personalized and seamless experience</p>
                        </div>
                    </Grid>
                    <Grid xs={12} sm={12} md={6} lg={4} item >
                        <div className='personalized-inner'>
                            <figure>
                                <img src={personalized3} alt=''/>
                            </figure>
                            <h4>Expert Curated Financial Literacy</h4>
                            <p>We take every facet of your financial profile to provide you most accurate and up-to-date information to create the most personalized and seamless experience</p>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </section>

        {/* my marble */}
        <section className='my-marble'>
            <Container>
                <Grid container className='align-items-center'>
                    <Grid xs={12} sm={12} md={6} lg={6} item >
                        <figure>
                            <img src={mymarbleImg} alt=''/>
                        </figure>
                    </Grid>
                    <Grid xs={12} sm={12} md={6} lg={6} item >
                        <div className='heading'>
                            <h3>Your journey to better credit starts with MyMarble! </h3>
                        </div>
                        <p>Think of MyMarble as your sidekick, here to give you tips and tricks to help support you on your journey to financial fitness. Complete your profile, access your personalized journeys and begin receiving recommendations using your data to improve your bottom line. The Marble Community is here whenever you need us.</p>
                        <Button onClick={routeChange} variant="contained">Start a Mortgage Quote & Get a Free Credit Score</Button>
                    </Grid>
                </Grid>
            </Container>
        </section>

     

        <section className='credit-score-content'>
            <Container>
            <div className="cs-content"> 
<div className="cs-content-inner">


<h2><span>Why Choose Us</span> for Credit Report</h2>
<p>When it comes to accessing your credit report in Canada, there are numerous options available. However, choosing the right provider is crucial to ensure accuracy and reliability of the information you receive.</p>

<p>At RateShop Inc. we pride ourselves on being a trusted and reputable source for obtaining your credit report. Here's why you should choose us:</p>

<p>1. Comprehensive Information: Our credit reports provide you with a detailed overview of your financial history, including your credit score, payment history, outstanding debts, and any derogatory marks or accounts in collections. We believe in providing you with all the necessary information so that you can make informed decisions about your financial health.</p>

<p>2. Accuracy and Timeliness: We understand the importance of accurate and up-to-date information when it comes to making financial decisions. That's why we work diligently to ensure that our credit reports are reliable and delivered promptly.</p>

<p>3. Easy Access: With our user-friendly platform, accessing your credit report has never been easier! Simply sign up on our website, answer a few simple questions to verify your identity, and gain instant access to your free credit report.</p>

<p>4. Protection of Personal Information: Safeguarding personal data is one of our top priorities. Rest assured that all the information you provide is treated with utmost confidentiality and adheres strictly to privacy laws.</p>
<p>5. Educational Resources: Understanding how credit scores work can be overwhelming for many individuals. That's why we go beyond simply providing a credit report by offering educational resources such as articles and guides to help you better comprehend how factors like payment history or utilization ratios affect your score.</p>

<p>Remember that regularly monitoring your credit report is essential for detecting any errors or signs of identity theft early on so they can be addressed promptly.</p>
</div></div>

            </Container>
        </section>
   {/* Access my marble */}
   <section className='access-my-marble'>
            <Container>
                <div className='text-center'>
                    <div className='heading'>
                        <h3>Already have access to MyMarble?</h3>
                    </div>
                    <p>Let’s achieve your financial goals, together.</p>
                    <Link to="https://rateshop.mymarble.ca/" className='yllow-btn-cs'>Login</Link>
                    {/* <Button  variant="contained">Login</Button>  */}
                </div>
            </Container>
        </section>
        {/* partner logo Start*/}
            <Partner/>
        {/* partner logo End*/}
    </>
    )
}

export default CreditScore