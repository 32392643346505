import React, { useEffect ,useState} from "react";
import './new-blog-detail.scss';
import { useParams as params, Navigate } from 'react-router-dom';
import { posts, locations } from "../../../../config/posts-2";
import { metaTags } from "../../../../config/metaTags";
import { Container, Grid } from "@mui/material";
import { Button } from "@mui/material";
import { Markup } from 'react-render-markup';
import { useNavigate, Link } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import get500 from "../../../../assets/public/images/get-500.png";
import ApplyNowWidget from '../../../public/apply-now-widget/apply-now';
import bannerLogo from "../../../../assets/public/images/webp/rateshop-logo.webp";
import Helmet from 'react-helmet';
import bannerInnerImg from "../../../../assets/public/images/lender-bg.jpg";
import personImg from "../../../../assets/public/images/Person-image-7.png";
import CalenderPopup from '../../../../components/shared/calender-popup/calender';
import FormPopup from '../../../../components/shared/calender-popup/form';
import getstartednow from "../../../../assets/public/images/get-started-now.png";
// import bannerInnerImg from "../../../../assets/public/images/lender-bg.jpg";

const NewBlogDetail2 = function () {
    const [openForm, setOpen1] = useState(false);
    const handleClickOpenForm = () => {
        setOpen1(true);
    };
    const handleCloseForm = () => {
        setOpen1(false);
    };
 
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const { post_slug } = params();
    const validPosts = posts.filter(post => post !== undefined);
    // const postFiltered = validPosts.find(post => String(post.slug) === staticSlug);
    const postFiltered = validPosts.find(post => String(post.slug) === post_slug);
    // const postFiltered = posts.find(post => String(post.slug) === post_slug);

    const [insuredFiveYearFixed, setInsuredFiveYearFixed] = React.useState([]);
    const [insuredFiveYearVariable, setInsuredFiveYearVariable] = React.useState([]);
    const navigate = useNavigate();
    const routeChange = () => {
        let path = `/apply`;
        navigate(path);
    }

    Date.prototype.today = function () { 
        return ((this.getDate() < 10)?"0":"") + this.getDate() +"/"+(((this.getMonth()+1) < 10)?"0":"") + (this.getMonth()+1) +"/"+ this.getFullYear();
        }
        Date.prototype.timeNow = function () {
            return ((this.getHours() < 10)?"0":"") + this.getHours() +":"+ ((this.getMinutes() < 10)?"0":"") + this.getMinutes() +":"+ ((this.getSeconds() < 10)?"0":"") + this.getSeconds();
        }
        const currentDate = new Date();
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
      
        const formattedYesterday = yesterday.toLocaleDateString('en-US', {
          year: 'numeric',
          day: 'numeric',
          month: 'numeric',
          
        });
        const timeToMins = (time) => {
            var b = time.split(/[:\s]/);
            return b[0]%12*60 + +b[1] + (/pm\s*$/i.test(time)? 720 : 0);
        }
    
        const formatAMPM = (date) => {
            var hours = date.getHours();
            var minutes = date.getMinutes();
            var ampm = hours >= 12 ? 'pm' : 'am';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            minutes = minutes < 10 ? '0'+minutes : minutes;
            var strTime = hours + ':' + minutes + ' ' + ampm;
            return strTime;
        }

        const [showDate, setShowDate] = React.useState('');
    useEffect(() => {
        const token = {
            Authorization: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoiQWJoaXNoZWsgUHVyaSIsIm5hbWUiOiJSZWFjdCBSUyIsInBhc3N3b3JkIjpudWxsLCJBUElfVElNRSI6MTY3NjY0MjQyNn0.kcYNItsCPFOtwPIgffGx1Zf-BGDN_Vsx-r0vnGcucOs',
        };
        fetch('https://crm.rateshop.ca/ReactController/mortgageRates', {
            method: 'POST',
            headers: {
                Accept: 'application/form-data',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(token),
        })
            .then(res => res.json())
            .then(
                (result) => {
                    const insuredFiveYearFixed = Math.min(...JSON.parse(result.rates.insured_five_year_fixed).filter(function (el) {
                        return el != '';
                    }));
                    const insuredFiveYearVariable = Math.min(...JSON.parse(result.rates.insured_five_year_variable).filter(function (el) {
                        return el != '';
                    }));
                    setInsuredFiveYearFixed(insuredFiveYearFixed)
                    setInsuredFiveYearVariable(insuredFiveYearVariable);

                }
            )
            if(timeToMins(formatAMPM(currentDate)) > timeToMins('10:00 am')){
                setShowDate(currentDate.today());
    
            }else if(timeToMins(formatAMPM(currentDate)) > timeToMins('12:00 am') && timeToMins(formatAMPM(currentDate)) < timeToMins('10:00 am')){   
                setShowDate(formattedYesterday);
            }else{
                setShowDate(currentDate.today());
            }

    }, []);
    const formatText = (text) => {
        return text
            .split('-')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };


    const formattedPostSlug = formatText(post_slug);
    const { state, city } = params();
    
    if (!(state in locations) || !locations[state].includes(city) || !locations['single-post'].includes(post_slug)) {
        return <Navigate to="/404" replace />;
    }

    const metaTagValue = metaTags.find(metaTag => (String(metaTag.city) == city && String(metaTag.post_slug) == post_slug  && String(metaTag.province) == state));

    return (
        <>

            <Helmet>
                <meta charSet="utf-8" />
                <title>{metaTagValue?.meta_title}</title>
                <meta name='description' content={metaTagValue?.meta_description} />
                <meta name='keywords' content={metaTagValue?.keywords} />
            </Helmet>

            <div className="mortage-new"> 
                {postFiltered.bannerLayoutImage ? (
                    <section className="banner-inner cs-nw-banner cs-location-banner" style={{backgroundImage: `url(${bannerInnerImg})`}}>
                        <div className='person-img'>
                            <img src={personImg} />
                        </div> 
                        <div className='b-i-overlay'>
                            <Container>
                            <div className='banner-layers'> 
                                <div className="banner-content">
                                      { postFiltered && postFiltered.slug === "private-mortgage" ? (
                                       <h1 className="mb-30">Private Mortgages Across {formatText(state)}</h1>
                                    ) : (
                                        <h1 className="mb-30">{postFiltered.title1}</h1>
                                    )
                                    }
                                    <Button  onClick={handleClickOpenForm}  variant="contained">Get Started Now</Button> 
                                </div> 
                                    {/**/}

                                    {/* <div className='s-h-n-logo'>
                                        <img src={bannerLogo} /> 
                                    </div> */}
                                </div>
                            </Container>
                        </div>
                    </section>
                ) : (
                    <Container className="investing-mortgage">
                    <h1 >{postFiltered.title1}</h1> 
                        <ApplyNowWidget />
                    </Container>
                )}
 
                {/* get your best rates */}
                <section className="get-best-rates get-mortgage-rates-cs">
                    <Container >
                        <Grid container>
                            <Grid item xs={12} className="d-flex align-items-center flex-wrap justify-content-between bes-rates-inner" >
                                <p className="w-70">RateShop.ca Lowest Mortgage Rate offers last update: {showDate} 10:00 AM EST</p>
                                <img className="get500 w-30" src={get500} alt="" />
                            </Grid>
                        </Grid>
                    </Container>
                </section>
                {/* Discover the best rates */}

                <Container>
                    <Breadcrumbs aria-label="breadcrumb" className="equity-breadcrumb">
                        <Link underline="hover" color="inherit" to="/"> Home </Link>
                        <Typography color="text.primary">{state}</Typography>
                        <Typography color="text.primary">{city}</Typography>
                        <Typography color="text.primary">{formattedPostSlug}</Typography>
                    </Breadcrumbs>
                </Container>
            </div>
            <section className="blog-content blog-v2">
                <Container>
                    <div className="blog-content-wrap sec-padding location-detials-sec">
                        <div className="blog-text">
                            <div className="blog-imgVideo-nw">
                                {postFiltered.video && (
                                    <video muted loop autoPlay playsInline>
                                        <source src={postFiltered.video} type="video/mp4"></source>
                                    </video>
                                )}
                                {postFiltered.bannerImage && (
                                    <img src={postFiltered.bannerImage} alt="Banner" />
                                )}
                            </div>
                            <div className="heading"><h2>{postFiltered.title}</h2></div>
                            <Markup markup={postFiltered.description} />
                        </div>
                        <div className="blog-sidebar">
                            <div className="get-best-rate">
                                <h6>5 Year - Fixed Term from</h6>
                                <h2>{insuredFiveYearFixed}%</h2>
                                <h4>Apply for your Best Rate in minutes.</h4>
                                <p className="small-text">Everyone's rate is unique.What's yours?</p>
                                <p>It is our job to get your lowest possible rate. Your rate qualification depends on certain factors, such as credit score and home equity as per regulations.</p>
                                <Button onClick={routeChange} variant="contained">Get your Best Rate</Button>
                            </div>
                            <div className="mortgage-calculators">
                                <img src={postFiltered.logo} alt="" />
                                <ul>
                                    <li><Link to="/mortgage-payment-calculator">Mortgage Payment Calculator</Link></li>
                                    <li><Link to="/cost-closing-calculator">Closing Cost Calculator</Link></li>
                                    <li><Link to="/affordability-calculator">Affordability Calculator</Link></li>
                                    <li><Link to="/cmhc-insurance-calculator">CMHC Calculator</Link></li>
                                    <li><Link to="/land-transfer-tax-calculator">Land transfer tax Calculator</Link></li>
                                </ul>
                            </div>
                            <div className="cta-button">
                                 
                            <Button  onClick={handleClickOpen}  variant="contained">
                                <img src={getstartednow} alt="Get Started Now"></img> 
                                </Button> 
                            
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
            <CalenderPopup
                open={open}
                handleClose={handleClose}
            />
            <FormPopup 
                openForm={openForm}
                handleCloseForm={handleCloseForm}
            />
        </>
    )
}

export default NewBlogDetail2;