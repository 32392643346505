import React from 'react';
import { Outlet } from "react-router-dom"; 
import Header from '../components/join-us/header/header';
import Footer from '../components/join-us/footer/footer';

const JoinHead = () => { 
  return ( 
    <>
      <Header /> 
      <Outlet />
      <Footer/>
    </>
  )
}
export default JoinHead