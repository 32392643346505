import React, { useRef,useEffect } from "react";
import './calculators.scss';
import { Container } from "@mui/material";
import Helmet from 'react-helmet';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import {Link} from 'react-router-dom'
const RequiredCalculator = function () {
    const calculatorRef2 = useRef(null);
  
    useEffect(() => {
      // Create the script element
      const script = document.createElement('script');
      script.src = 'https://tools.bendigi.com/assets/calculators.js';
      script.async = true;
  
      // Append the script to the document body or target element
      document.body.appendChild(script);
  
      // Cleanup script when the component is unmounted
      return () => {
        document.body.removeChild(script);
      };
    }, []);
    return (
        <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Required Income Calculator</title>
          <meta name='description' content="Use RateShop's mortgage payment calculator to see how mortgage amount, interest rate, and other factors can affect your payment." />
          <meta name="keywords" content="Mortgage Payment Calculator, Monthly Mortgage Payments, RateShop.ca, Mortgage Rates, Budget Planning, Best Mortgage Rates, Mortgage Calculator, Estimate Mortgage Payments"/>
        </Helmet>
            <section className="m-p-calc sec-padding">
           
                <Container>
                <div className="cs-content cs-max-w pb-10">
                    <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" to="/">
                    Home
                </Link> 
                <Typography color="text.primary">Required Income Calculator</Typography>
            </Breadcrumbs>
            </div>
                    {/* <iframe src="https://calc.rateshop.ca/index.html" width="100%"  title="Mortgage Payment Calculator"></iframe> */}
                    <div class="bendigi-calculators" ref={calculatorRef2} apikey="096c437989677939:_1lBxDjjxU8OxTd2avjJI8N7x-Q1xNHm" terms="https://canadianmortgageapp.com/terms" navPositionTop="0px" navPositionTopMobile="0px" tools="minimum-income"></div>
                    
                </Container>
            </section>
        </>
    )
}

export default RequiredCalculator;