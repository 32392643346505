import React, { useEffect } from "react"; 

const Webmail = () => {
    useEffect(() => {
        window.location.replace('https://svr.rateshop.ca/webmail/');
    }, []);
    return (<>
 
    </>)
}

export default Webmail