import React from 'react'; 
import "./sidebar-blogs.scss";
import { Link } from 'react-router-dom';
import { blogsDatas } from '../../../config/blog';

const SidebarBlogs = ({ posts }) => {
    return (
        <section className="sidebar-blogs">
            <div className="heading text-center">
                <h3><span>Mortgage </span> Blogs</h3>
            </div>
            <div className="sidebar-blogs__wrapper">
                {blogsDatas.slice(0, posts).map(blogsDatas => (
                    <div key={blogsDatas.id} className="blogs-outer d-flex align-items-center">
                        <img src={blogsDatas.img} alt={blogsDatas.title} />
                        <h2><Link to={'/mortgage-blogs/' + blogsDatas.blog_slug}>{blogsDatas.title}</Link></h2>
                    </div>
                ))}
            </div>
        </section>
    );
}

export default SidebarBlogs;