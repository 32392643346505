import React from 'react';
import { Container } from "@mui/material";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import "./blogs.scss";
import { Link } from 'react-router-dom';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { blogsDatas } from '../../../config/blog';

const BlogCarousel = () => {
    var option3 = {
        items: 4,
        responsiveClass: true,
        dots: false,
        autoplay: true,
        nav: false,
        autoWidth: false,
        loop: true,
        margin: 20,
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 2,
            },
            992: {
                items: 3,
            },
            1199: {
                items: 4,
            }
        }
    }
    return (
        <section className="location-carousel blog-carousel pb-0">
            <Container>
                <div className="heading text-center">
                    <h3><span>Latest</span> Blogs</h3>
                </div>
                <div className="location-carousel-inner"> 
                    <OwlCarousel
                        {...option3}
                    >
                        {blogsDatas.map(blogsDatas => (
                            <div className="item">
                                <div key={blogsDatas.id}>
                                    <img src={blogsDatas.img} alt={blogsDatas.title} /> 
                                    <p className="category">
                                        <span><PersonOutlineIcon /> {blogsDatas.author}</span>
                                        <span><AccessTimeIcon /> {blogsDatas.reading_time} Minutes Read</span>
                                    </p>
                                    <h2><Link to={'/mortgage-blogs/'+ blogsDatas.blog_slug}>{blogsDatas.title}</Link></h2>
                                </div>
                            </div>
                        ))} 
                    </OwlCarousel>
                </div>
            </Container>
        </section>
    );
}

export default BlogCarousel;