import React from 'react';
import { Container } from "@mui/material";
import "./banner-inner.scss";
import bannerLogo from "../../../assets/public/images/webp/rateshop-logo.webp";

const BannerInner = ({ image, heading, subHeading, logo,personImg }) => {

    return (
        <section className="banner-inner">
        <div className='person-img'>
            <img src={personImg} />
        </div>
            <img src={image} alt="" />
            <div className='b-i-overlay'>
                <Container>
                    <div className='banner-layer'>
                    <h1>{heading}</h1> 
                    <img src={logo} alt="" className='cs-logo-inner' />
                    <div className='s-h-n-logo'>
                        {/* <h2>{subHeading}</h2> */}
                        <img src={bannerLogo} />
                        <h4>Our Trusted lender Partners</h4>
                    </div>
                    </div>
                </Container>
            </div>
        </section>
    );
}

export default BannerInner;