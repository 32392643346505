import React from 'react';
import LinearStepper from './LinearStepper';
import { CssBaseline, Container, Paper, Box, Grid } from "@mui/material";
import './QuickApp.scss';
// import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import greyBulb from "../../assets/public/images/grey-bulb.png";
import bulb from "../../assets/public/images/bulb.png";
import InfoBar from '../info-bar/InfoBar';
import ApplyLanding from '../../components/popup/ApplyLanding';
import {   Pagination } from 'swiper';
import { Swiper, SwiperSlide    } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const QuickAppEmbed = () => {
  const [expandedInfo1, setExpandedInfo1] = React.useState('');
  const handleChangeInfo1 = (panelInfo1) => (event, newExpandedInfo1) => {
    setExpandedInfo1(newExpandedInfo1 ? panelInfo1 : false);
  };


  const [expandedInfoA, setExpandedInfoA] = React.useState('');
  const handleChangeInfoA = (panelInfoA) => (event, newExpandedInfoA) => {
    setExpandedInfoA(newExpandedInfoA ? panelInfoA : false);
  };

  const [expandedInfo101, setExpandedInfo101] = React.useState('');
  const handleChangeInfo101 = (panelInfo101) => (event, newExpandedInfo101) => {
    setExpandedInfo101(newExpandedInfo101 ? panelInfo101 : false);
  };

  const [expandedInfo201, setExpandedInfo201] = React.useState('');
  const handleChangeInfo201 = (panelInfo201) => (event, newExpandedInfo201) => {
    setExpandedInfo201(newExpandedInfo201 ? panelInfo201 : false);
  };

  const [expandedInfo301, setExpandedInfo301] = React.useState('');
  const handleChangeInfo301 = (panelInfo301) => (event, newExpandedInfo301) => {
    setExpandedInfo301(newExpandedInfo301 ? panelInfo301 : false);
  };

  return (
    <>
      <section className='quick-com-parent'>
        <div className="quick-container"> 
          <div className="q-i-wrap"> 
            <div className='quick-inner'>
              <Grid container>
                <Grid xs={12} sm={12} md={12} lg={12} item className="cs-p0">
                  <LinearStepper />
                </Grid>
              </Grid>
            </div>
          </div> 
        </div>
      </section>
      {/* <ApplyLanding /> */}
    </>
  );
}

export default QuickAppEmbed;