import * as React from 'react';
import "./calender.scss"; 
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog'; 
import DialogContent from '@mui/material/DialogContent'; 
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close'; 

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function CalenderPopup2({ open, handleClose }) {
    return (
        <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            maxWidth="lg"
            className='broker-form'
        >
            <DialogContent dividers>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <iframe src="https://api.leadconnectorhq.com/widget/booking/ujp49IWFKs0fWtDlfXpW" scrolling="yes" id="ujp49IWFKs0fWtDlfXpW_1717793458421"></iframe><script src="https://link.msgsndr.com/js/form_embed.js" type="text/javascript"></script>
               </DialogContent>
        </BootstrapDialog>
    );
}
