import React, { useEffect } from "react"; 
import "./not-found.scss";
import { NavLink } from 'react-router-dom';
import { Container } from "@mui/material";
import notfound from '../../../assets/public/images/error.png'

export default function NotFound() {
 return (
    <>
        <section className="not-found">
            <Container>
                <figure>
                    <img src={notfound} alt="Not Found" />
                </figure>
                {/* <h1>Page Not Found</h1> */}
                <p>Trying to get the lowest mortgage rates?</p>
                <p> We've got your back, but looks like this page was moved. </p>
                <p>Try our other pages below in our footer to get some amazing rates!</p>
                {/* <p>Go to <NavLink to='/'>Home</NavLink> page</p> */}
            </Container>
        </section>
    </> 
)
}