import { Container, Grid } from '@mui/material'
import React from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import './footer.scss'
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
// import RssFeedIcon from '@mui/icons-material/RssFeed';
import PinterestIcon from '@mui/icons-material/Pinterest';
// import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import StarIcon from '@mui/icons-material/Star';
import YouTubeIcon from '@mui/icons-material/YouTube';
import shape1 from "../../../../assets/public/images/shape3.png";
import shape2 from "../../../../assets/public/images/shape4.png";
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import logo from "../../../shared/logo/Logo";
import logo2 from '../../../../assets/public/images/rateshop-home.svg';
import Cookies from 'universal-cookie';
import axios from 'axios';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import CookieConsent from "react-cookie-consent";
import PartnerLogo from '../../../shared/partner-logo/PartnerLogo';

import { posts } from '../../../../config/posts-2';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({}));
const AccordionSummary = styled((props) => (
    <MuiAccordionSummary    {...props}
    />
))(({ theme }) => ({}));
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({}));


const Footer = () => {
    const [staffDetail, setStaffDetail] = React.useState([]);
    const [baseUrl, setBaseUrl] = React.useState('/');
    const cookies = new Cookies();
    const {slug} = useParams();
    const navigate = useNavigate();
  
    if(slug != undefined){
  
      const data = {
        type: 'single',
        Authorization: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoiQWJoaXNoZWsgUHVyaSIsIm5hbWUiOiJSZWFjdCBSUyIsInBhc3N3b3JkIjpudWxsLCJBUElfVElNRSI6MTY3NjY0MjQyNn0.kcYNItsCPFOtwPIgffGx1Zf-BGDN_Vsx-r0vnGcucOs',
        slug: slug
      };
  
  
        axios.post(`https://crm.rateshop.ca/ReactController/getStaffSlugs`, { data })
            .then(res => {
            if(res.data.status != 200){
                navigate('/404', { replace: true });
            }else{
              setBaseUrl('/'+cookies.get('cookieAgentSlug')+'/');
            }
        })
  
    
    }
    const [expanded, setExpanded] = React.useState('');
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
 
   
    const [expandedLenders, setExpandedLenders] = React.useState('panel1');
    const handleChangeLenders = (panelLenders) => (event, newExpandedLenders) => {
        setExpandedLenders(newExpandedLenders ? panelLenders : false);
    };

    const accordionProvinceBtns = document.querySelectorAll(".locAtions-wrap h3 span");
    let activeProvinceBtn = "";
    accordionProvinceBtns.forEach(function (btn) {
        btn.addEventListener("click", function (e) {
            if (activeProvinceBtn !== this) {
                document.querySelector('.locAtions-wrap li.active')?.classList.remove('active');
            }
            this.closest("li").classList.toggle("active");
            activeProvinceBtn = e.target;
        })
    })
    return (
        <>
        <PartnerLogo/>
        <footer>
            <Container>
            <CookieConsent location="bottom"
                buttonText="Accept"
                cookieName="myAwesomeCookieName2"
                style={{ background: "#09318b" }}
                buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                expires={150}>This website uses cookies to enhance the user experience.</CookieConsent>

                <Accordion onChange={handleChange('locations')}>
                    <AccordionSummary aria-controls="locations-content" id="locations-header">
                        <Typography>Locations <ExpandMoreIcon /></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {/* <Grid container className='w-100'>
                            <Grid item sm={6} md={5} lg={6} className="footer-location-links">
                                <h2>Mortgage Rates by City</h2>
                                <ul>
                                    <li><NavLink to='/'>Best Mortgage Rates In Brampton</NavLink> </li>
                                    <li><NavLink to='/'>Best Mortgage Rates In Burlington</NavLink> </li>
                                    <li><NavLink to='/'>Best Mortgage Rates In Milton</NavLink> </li>
                                    <li><NavLink to='/'>Best Mortgage Rates In Mississauga</NavLink> </li>
                                    <li><NavLink to='/'>Best Mortgage Rates In Ottawa</NavLink> </li>
                                    <li><NavLink to='/'>Best Mortgage Rates In Toronto</NavLink> </li>
                                </ul>
                                <h2>Best Credit Cards</h2>
                                <ul>
                                    <li><NavLink to='/'>Best Balance Transfer Cards</NavLink> </li>
                                    <li><NavLink to='/'>Best Canadian Credit Cards</NavLink> </li>
                                    <li><NavLink to='/'>Best Cash Back Cards</NavLink> </li>
                                    <li><NavLink to='/'>Best Low Interest Cards</NavLink> </li>
                                </ul>
                                <h2>Featured Content</h2>
                                <ul>
                                    <li><NavLink to='/'>Banks vs Mortgage Bokers</NavLink> </li>
                                    <li><NavLink to='/'>CMLS</NavLink> </li>
                                    <li><NavLink to='/'>Minimum Down Payment in Canada</NavLink> </li>
                                    <li><NavLink to='/'>Mortgage Prepayment</NavLink> </li>
                                    <li><NavLink to='/'>Term vs Amortization</NavLink> </li>
                                    <li><NavLink to='/'>Desjardins</NavLink> </li>
                                    <li><NavLink to='/'>MCAP</NavLink> </li>
                                    <li><NavLink to='/'>Merix</NavLink> </li>
                                    <li><NavLink to='/'>RMG</NavLink> </li>
                                    <li><NavLink to='/'>Street Capital</NavLink> </li>
                                    <li><NavLink to='/'>Xceed</NavLink> </li>
                                </ul>
                                <h2>Best Private Mortgages Canada</h2>
                                <ul>
                                    <li><NavLink to='/'>Private Second Mortgages</NavLink> </li>
                                    <li><NavLink to='/'>Private Mortgages Same Day Approvals</NavLink> </li>
                                </ul>
                            </Grid>
                            <Grid item sm={6} md={5} lg={6} className="footer-location-links">
                                <h2>Mortgage Rates by Type</h2>
                                <ul>
                                    <li><NavLink to='/'>Best 1-Year Fixed Mortgage Rates</NavLink> </li>
                                    <li><NavLink to='/'>Best 2-Year Fixed Mortgage Rates</NavLink> </li>
                                    <li><NavLink to='/'>Best 3-Year Fixed Mortgage Rates</NavLink> </li>
                                    <li><NavLink to='/'>Best 3-Year Variable Mortgage Rates</NavLink> </li>
                                    <li><NavLink to='/'>Best 4-Year Fixed Mortgage Rates</NavLink> </li>
                                    <li><NavLink to='/'>Best 5-Year Fixed Mortgage Rates</NavLink> </li>
                                    <li><NavLink to='/'>Best 5-Year Variable Mortgage Rates</NavLink> </li>
                                    <li><NavLink to='/'>Best 7-Year Fixed Mortgage Rates</NavLink> </li>
                                    <li><NavLink to='/'>Best 10-Year Fixed Mortgage Rates</NavLink> </li>
                                </ul>
                                <h2>Mortgage Calculators</h2>
                                <ul>
                                    <li><NavLink to='/'>Payment Calculator</NavLink> </li>
                                    <li><NavLink to='/'>CMHC Insurance Calculator</NavLink> </li>
                                    <li><NavLink to='/'>Land Transfer Tax Calculator</NavLink> </li>
                                    <li><NavLink to='/'>Affordability Calculator</NavLink> </li>
                                </ul>
                                <h2>Legal</h2>
                                <ul>
                                    <li><NavLink to='/'>Disclaimer</NavLink> </li>
                                    <li><NavLink to='/'>Privacy Policy</NavLink> </li>
                                    <li><NavLink to='/'>Cookie Policy</NavLink> </li>
                                    <li><NavLink to='/'>Terms of Service</NavLink> </li>
                                    <li><NavLink to='/'>Comment Policy</NavLink> </li>
                                </ul>
                                <h2>Blog</h2>
                                <ul>
                                    <li><NavLink to='/'>Self-Employed Canadians Will Have an Easier Time with CMHC in 2018</NavLink> </li>
                                    <li><NavLink to='/'>More Blogs</NavLink> </li>
                                </ul>
                            </Grid>
                        </Grid> */}
                            <div className='locAtions-wrap'>
                                <ul>
                                    <li>
                                        <h3><a href={`${baseUrl}mortgage-rates/alberta/`}>Alberta</a><span>Cities</span> <ExpandMoreIcon /></h3>
                                        <ul>
                                        <li><a href={`${baseUrl}mortgage-rates/alberta/calgary`}>Calgary</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/alberta/edmonton`}>Edmonton</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/alberta/red-deer`}>Red Deer</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/alberta/lethbridge`}>Lethbridge</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/alberta/airdrie`}>Airdrie</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/alberta/fort-mcMurray`}>Fort McMurray</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/alberta/medicine-hat`}>Medicine Hat</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/alberta/grande-prairie`}>Grande Prairie</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/alberta/spruce-grove`}>Spruce Grove</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/alberta/leduc`}>Leduc</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/alberta/cochrane`}>Cochrane</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/alberta/okotoks`}>Okotoks</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/alberta/fort-saskatchewan`}>Fort Saskatchewan</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/alberta/lloydminster`}>Lloydminster</a></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <h3><a href={`${baseUrl}mortgage-rates/british-columbia/`}>British Columbia</a><span>Cities</span> <ExpandMoreIcon /></h3>
                                        <ul>
                                        <li><a href={`${baseUrl}mortgage-rates/british-columbia/`}>Vancouver</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/british-columbia/victoria`}>Victoria</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/british-columbia/kelowna`}>Kelowna</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/british-columbia/abbotsford`}>Abbotsford</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/british-columbia/white-rock`}>White Rock</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/british-columbia/nanaimo`}>Nanaimo</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/british-columbia/kamloops`}>Kamloops</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/british-columbia/chilliwack`}>Chilliwack</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/british-columbia/prince-george`}>Prince George</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/british-columbia/vernon`}>Vernon</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/british-columbia/courtenay`}>Courtenay</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/british-columbia/campbell-river`}>Campbell River</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/british-columbia/penticton`}>Penticton</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/british-columbia/mission`}>Mission</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/british-columbia/parksville`}>Parksville</a></li>
                                        </ul>
                                    </li>
                                    {/* <li>
                                        <h3><Link to={`${baseUrl}mortgage-rates/manitoba/`}>Manitoba</Link> <span>Cities</span> <ExpandMoreIcon /></h3>
                                        <ul>
                                            <li><Link to={`${baseUrl}mortgage-rates/manitoba/winnipeg`}>Winnipeg</Link></li>
                                            <li><Link to={`${baseUrl}mortgage-rates/manitoba/brandon`}>Brandon</Link></li>
                                        </ul>
                                    </li> */}
                                    <li>
                                        <h3><a href={`${baseUrl}mortgage-rates/new-brunswick/`}>New Brunswick</a> <span>Cities</span> <ExpandMoreIcon /></h3>
                                        <ul>
                                        <li><a href={`${baseUrl}mortgage-rates/new-brunswick/moncton`}>Moncton</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/new-brunswick/fredericton`}>Fredericton</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/new-brunswick/saint-john`}>Saint John</a></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <h3><a href={`${baseUrl}mortgage-rates/nova-scotia/`}>Nova Scotia</a> <span>Cities</span> <ExpandMoreIcon /></h3>
                                        <ul>
                                        <li><a href={`${baseUrl}mortgage-rates/nova-scotia/halifax`}>Halifax</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/nova-scotia/cape-breton`}>Cape Breton</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/nova-scotia/sydney`}>Sydney</a></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <h3><a href={`${baseUrl}mortgage-rates/ontario/`}>Ontario</a><span>Cities</span> <ExpandMoreIcon /></h3>
                                        <ul>
                                        <li><a href={`${baseUrl}mortgage-rates/ontario/toronto`}>Toronto</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/ontario/hamilton`}>Hamilton</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/ontario/kitchener`}>Kitchener</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/ontario/london`}>London</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/ontario/oshawa`}>Oshawa</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/ontario/windsor`}>Windsor</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/ontario/st.-catharines`}>St. Catharines - Niagara Falls</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/ontario/barrie`}>Barrie</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/ontario/guelph`}>Guelph</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/ontario/kanata`}>Kanata</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/ontario/kingston`}>Kingston</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/ontario/milton`}>Milton</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/ontario/brantford`}>Brantford</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/ontario/thunder`}>Thunder Bay</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/ontario/sudbury`}>Sudbury</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/ontario/peterborough`}>Peterborough</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/ontario/belleville`}>Belleville</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/ontario/sarnia`}>Sarnia</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/ontario/welland-pelham`}>Welland - Pelham</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/ontario/sault-ste.-marie`}>Sault Ste. Marie</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/ontario/bowmanville-newcastle`}>Bowmanville - Newcastle</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/ontario/north-bay`}>North Bay</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/ontario/cornwall`}>Cornwall</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/ontario/woodstock`}>Woodstock</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/ontario/st.-thomas`}>St. Thomas</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/ontario/chatham`}>Chatham</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/ontario/georgetown`}>Georgetown</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/ontario/bradford`}>Bradford</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/ontario/stouffville`}>Stouffville</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/ontario/leamington`}>Leamington</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/ontario/orangeville`}>Orangeville</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/ontario/orillia`}>Orillia</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/ontario/stratford`}>Stratford</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/ontario/innisfil`}>Innisfil</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/ontario/timmins`}>Timmins</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/ontario/keswick-elmhurst-beach`}>Keswick - Elmhurst Beach</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/ontario/bolton`}>Bolton</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/ontario/midland`}>Midland</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/ontario/ottawa–gatineau`}>Ottawa–Gatineau</a></li>

                                        </ul>
                                    </li>
                                    <li>
                                        <h3><a href={`${baseUrl}mortgage-rates/prince-edward-island/`}>Prince Edward Island</a> <span>Cities</span><ExpandMoreIcon /></h3>
                                        <ul>
                                            <li><a href={`${baseUrl}mortgage-rates/prince-edward-island/charlottetown`}>Charlottetown</a></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <h3><a href={`${baseUrl}mortgage-rates/quebec/`}>Quebec</a> <span>Cities</span> <ExpandMoreIcon /></h3>
                                        <ul>
                                        <li><a href={`${baseUrl}mortgage-rates/quebec/montreal`}>Montreal</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/quebec/quebec-city`}>Quebec City</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/quebec/sherbrooke`}>Sherbrooke</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/quebec/trois-rivières`}>Trois-Rivières</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/quebec/chicoutimi-jonquière`}>Chicoutimi - Jonquière</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/quebec/saint-jérôme`}>Saint-Jérôme</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/quebec/saint-jean-sur-richelieu`}>Saint-Jean-sur-Richelieu</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/quebec/châteauguay`}>Châteauguay</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/quebec/drummondville`}>Drummondville</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/quebec/granby`}>Granby</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/quebec/beloeil`}>Beloeil</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/quebec/saint-hyacinthe`}>Saint-Hyacinthe</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/quebec/joliette`}>Joliette</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/quebec/victoriaville`}>Victoriaville</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/quebec/salaberry-de-valleyfield`}>Salaberry-de-Valleyfield</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/quebec/shawinigan`}>Shawinigan</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/quebec/rimouski`}>Rimouski</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/quebec/sorel`}>Sorel</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/quebec/saint-georges`}>Saint-Georges</a></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <h3><a href={`${baseUrl}mortgage-rates/saskatchewan/`}>Saskatchewan</a> <span>Cities</span> <ExpandMoreIcon /></h3>
                                        <ul>
                                        <li><a href={`${baseUrl}mortgage-rates/saskatchewan/saskatoon`}>Saskatoon</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/saskatchewan/regina`}>Regina</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/saskatchewan/prince-albert`}>Prince Albert</a></li>
                                        <li><a href={`${baseUrl}mortgage-rates/saskatchewan/moose-jaw`}>Moose Jaw</a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion onChange={handleChangeLenders('lenders')}>
                    <AccordionSummary aria-controls="locations-content" id="locations-header">
                        <Typography>Lenders <ExpandMoreIcon /></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <div className="lenders">
                                <ul>
                                    <li><Link to={`${baseUrl}mortgage-lender/parama-credit-union`}>Parama Credit Union</Link></li>
                                    <li><Link to={`${baseUrl}mortgage-lender/lighthouse-credit-union`}>Lighthouse Credit Union</Link></li>
                                    <li><Link to={`${baseUrl}mortgage-lender/vancouver-city`}>Vancouver City</Link></li>
                                    <li><Link to={`${baseUrl}mortgage-lender/cmls-financials`}>CMLS Financials</Link></li>
                                    <li><Link to={`${baseUrl}mortgage-lender/rmg`}>RMG</Link></li>
                                    <li><Link to={`${baseUrl}mortgage-lender/scotiabank`}>Scotiabank</Link></li>
                                    <li><Link to={`${baseUrl}mortgage-lender/equitable-bank`}>Equitable Bank</Link></li>
                                    <li><Link to={`${baseUrl}mortgage-lender/home-trust`}>Home Trust</Link></li>
                                    <li><Link to={`${baseUrl}mortgage-lender/mcap`}>MCAP</Link></li>
                                    <li><Link to={`${baseUrl}mortgage-lender/community-trust`}>Community Trust</Link></li>
                                    <li><Link to={`${baseUrl}mortgage-lender/first-national`}>First National</Link></li>
                                    <li><Link to={`${baseUrl}mortgage-lender/canadian-western-bank`}>Canadian Western Bank (CWB)</Link></li>
                                    <li><Link to={`${baseUrl}mortgage-lender/aveo`}>Aveo</Link></li>
                                    <li><Link to={`${baseUrl}mortgage-lender/strive-bank`}>Strive Bank</Link></li>
                                    <li><Link to={`${baseUrl}mortgage-lender/blueshore`}>Blueshore</Link></li>
                                    <li><Link to={`${baseUrl}mortgage-lender/icici`}>ICICI</Link></li>
                                    <li><Link to={`${baseUrl}mortgage-lender/manulife`}>Manulife</Link></li>
                                    <li><Link to={`${baseUrl}mortgage-lender/b2b-bank`}>B2B bank</Link></li>
                                    <li><Link to={`${baseUrl}mortgage-lender/hsbc`}>HSBC</Link></li>
                                    <li><Link to={`${baseUrl}mortgage-lender/shinhan-bank`}>Shinhan Bank</Link></li>
                                    <li><Link to={`${baseUrl}mortgage-lender/duca`}>DUCA</Link></li>
                                    <li><Link to={`${baseUrl}mortgage-lender/desjardins`}>Desjardins</Link></li>
                                    <li><Link to={`${baseUrl}mortgage-lender/servus`}>Servus</Link></li>
                                    <li><Link to={`${baseUrl}mortgage-lender/atb`}>ATB</Link></li>
                                    <li><Link to={`${baseUrl}mortgage-lender/sbi`}>SBI</Link></li>
                                    <li><Link to={`${baseUrl}mortgage-lender/marathon-mortgage`}>Marathon Mortgage</Link></li>
                                    <li><Link to={`${baseUrl}mortgage-lender/wealthone`}>WealthOne</Link></li>
                                    <li><Link to={`${baseUrl}mortgage-lender/bridgewater-bank`}>Bridgewater Bank</Link></li>
                                    <li><Link to={`${baseUrl}mortgage-lender/home-equity-bank`}>Home Equity Bank<span>114</span></Link></li>
                                </ul>
                            </div>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion onChange={handleChangeLenders('lenders')}>
                    <AccordionSummary aria-controls="locations-content" id="locations-header">
                        <Typography>Explore Our Mortgages <ExpandMoreIcon /></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <div className="lenders">
                                <ul>
                                {posts.map(post => ( 
                                        <li><a href={`${baseUrl + post.slug}`}>{post.title1}</a></li> 
                                ))} 
                                </ul>
                            </div>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <div className='footer-shapes'>
                    <img src={shape1} alt="" className='shape-1' />
                    <img src={shape2} alt="" className='shape-2' />
                </div>
                <Grid container>
                    <Grid item sm={6} md={12} lg={3}>
                            <div className="footer-logo">
                            <img src={logo2} alt="logo" />
                        </div>
                        <div className='social-icons'>
                            <ul className='d-flex align-items-center'>
                                <li>
                                    <NavLink to="https://www.facebook.com/RateShopCanada" aria-label="Facebook">
                                        <FacebookIcon />
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="https://twitter.com/rateshopca" aria-label="Twitter">
                                        <TwitterIcon />
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="https://www.instagram.com/rateshopca/" aria-label="Instagram">
                                        <InstagramIcon />
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="https://www.pinterest.com/RateShopCanada/" aria-label="Pininterest">
                                        <PinterestIcon />
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="https://www.youtube.com/@rateshopinc.380" aria-label="Youtube">
                                        <YouTubeIcon />
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="https://ca.trustpilot.com/review/rateshop.ca" aria-label="Trustpilot">
                                        <StarIcon />
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </Grid>
                    <Grid item sm={6} md={4} lg={3}>
                        <div className='links-title'>
                            <h4>Quick Links</h4>
                        </div>
                        <ul className='footer-links'>
                            <li><NavLink to='/'>Home</NavLink> </li>
                            <li><NavLink to="/mortgage-rates">Mortgages Rates</NavLink></li>
                            {/* <li><NavLink to="/learning-center">Learning Center</NavLink></li> */}
                            {/* <li><NavLink to="/our-agents">Our Agents</NavLink></li> */}
                            {/* <li><NavLink to="/credit-score">Credit Score</NavLink></li> */}
                            <li><NavLink to="/contact">Contact</NavLink></li>
                            <li><NavLink to="https://ref.rateshop.ca/" target='_blank'>Partners</NavLink></li>
                            <li><NavLink to="/privacy-policy">Privacy Policy</NavLink></li>
                            {/* <li>
                                <NavLink to="">Get Pre-qualified in 60 Sec </NavLink>
                            </li>
                            <li>
                                <NavLink to=""> Commercial Mortgages</NavLink>
                            </li>
                            <li>
                                <NavLink to="">Get a Home Equity Loan </NavLink>
                            </li>
                            <li>
                                <NavLink to="">Get a Home Equity Line of Credit (HELOC) </NavLink>
                            </li>
                            <li>
                                <NavLink to=""> Low or Bad Credit Mortgage Approvals</NavLink>
                            </li>
                            <li>
                                <NavLink to="">Get a Mortgage After Consumer Proposal </NavLink>
                            </li>
                            <li>
                                <NavLink to="">Mortgages For Self Employed </NavLink>
                            </li>
                            <li>
                                <NavLink to=""> Residential Construction Mortgages</NavLink>
                            </li>
                            <li>
                                <NavLink to="">Private Mortgages Toronto </NavLink>
                            </li>
                            <li>
                                <NavLink to="">Widgets</NavLink>
                            </li>
                            <li>
                                <NavLink to="">Home Value</NavLink>
                            </li>
                            <li>
                                <NavLink to="/agent-directory">Agents</NavLink>
                            </li> */}
                        </ul>
                    </Grid>
                    <Grid item sm={6} md={4} lg={3}>
                        <div className='links-title'>
                            <h4><NavLink to={`${baseUrl}calculators`}>Calculators</NavLink></h4>
                        </div>
                        <ul className='footer-links'>
                            {/* <li><NavLink to={`${baseUrl}mortgage-payment-calculator`}>Mortgage Payment Calculator</NavLink> </li>
                            <li><NavLink to={`${baseUrl}affordability-calculator`}>Affordability Calculator</NavLink></li> 
                            <li><NavLink to={`${baseUrl}land-transfer-tax-calculator`}>Land Transfer Tax Calculator</NavLink></li>
                            <li><NavLink to={`${baseUrl}cmhc-insurance-calculator`}>CMHC Insurance Calculator</NavLink></li>
                            <li><NavLink to={`${baseUrl}cost-closing-calculator`}>Closing Cost Calculator</NavLink></li>  */}
                            {/* <li><NavLink to={`${baseUrl}calculators`}>Calculators</NavLink></li> */}
                            <li><NavLink to={`${baseUrl}mortgage-payment-calculator`}>Mortgage Payment Calculator</NavLink></li>
                        <li><NavLink to={`${baseUrl}affordability-calculator`}>Affordability Calculator</NavLink></li>
                        <li><NavLink to={`${baseUrl}land-transfer-tax-calculator`}>Land Transfer Tax Calculator</NavLink></li>
                        <li><NavLink to={`${baseUrl}cmhc-insurance-calculator`}>CMHC Insurance Calculator</NavLink></li>
                        <li><NavLink to={`${baseUrl}cost-closing-calculator`}>Closing Cost Calculator</NavLink></li>
                        <li><NavLink to={`${baseUrl}purchase-calculator`}>Purchase Calculator</NavLink></li>
                        <li><NavLink to={`${baseUrl}maximum-mortgage-calculator`}>Maximum Mortgage Calculator</NavLink></li>
                        <li><NavLink to={`${baseUrl}required-income-calculator`}>Required Income Calculator</NavLink></li>
                        <li><NavLink to={`${baseUrl}debt-service-calculator`}>Debt Service Calculator</NavLink></li>
                        <li><NavLink to={`${baseUrl}compare-side-by-side-calculator`}>Compare Side-by-side</NavLink></li>
                        </ul>
                    </Grid>
                    <Grid item sm={6} md={4} lg={3}>
                        <div className='links-title'>
                            <h4>Contact Information</h4>
                        </div>
                        <ul className='footer-links contact-information'>
                            <li>
                                <NavLink className="address-footer"><LocationOnIcon/>6 Indell Lane, Brampton ON L6T 3Y3, Canada</NavLink>
                            </li>
                            <li>
                                <NavLink to="tel:+14168272626"><CallIcon/>Local: 416-827-2626</NavLink>
                            </li>
                            <li>
                                <NavLink to="tel:+18007259946"><CallIcon/>Toll Free: 800-725-9946</NavLink>
                            </li>
                            <li>
                                <NavLink to="mailto:info@rateshop.ca"><EmailIcon/>  info@rateshop.ca</NavLink>
                            </li>
                            <li>
                                <NavLink target='_blank' to="https://crm.rateshop.ca/"><PersonIcon/>Agent Login </NavLink>
                            </li>
                        </ul>
                    </Grid>
                    <Grid item sm={12} md={12} lg={12}>
                        <div className='foot-about'>
{/* Manitoba FIRB #10139499, */}
                            <p>RateShop Inc. is a Mortgage Brokerage offering lowest mortgage rates to Canadians. We are provincially licensed in the following provinces: Mortgage Brokerage Ontario FSRA #12733, British Columbia BCFSA #MB600776, Alberta RECA #00523056P, Saskatchewan FCAA #00511126, PEI #160622,  New Brunswick FCNB #88426, Newfoundland/Labrador. Our Quebec Mortgage Transactions are serviced by Orbis Mortgage Group AMF# 181136.</p>
                        </div>
                    </Grid>
                    <Grid item sm={12} md={12} lg={12}>
                        <div className='copright'>
                            <p className='pb-0'>Copyright 2024. All Rights Reserved. RateShop.ca</p>
                            <p class="designed-by pt-0"><small>Design & Marketing by <Link to="https://nanoqode.com/" target="_blank">Nanoqode.com</Link></small></p>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </footer>
        </>
    )
}

export default Footer