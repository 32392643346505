import React, { useRef, useState,useEffect } from 'react' 
import { Container } from "@mui/material"; 
import './calculator.scss';
import Helmet from 'react-helmet';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import {Link} from 'react-router-dom'


 
const CalculatorNew = function () { 
 
    const calculatorRef = useRef(null);
  
    useEffect(() => {
      // Create the script element
      const script = document.createElement('script');
      script.src = 'https://tools.bendigi.com/assets/calculators.js';
      script.async = true;
  
      // Append the script to the document body or target element
      document.body.appendChild(script);
  
      // Cleanup script when the component is unmounted
      return () => {
        document.body.removeChild(script);
      };
    }, []);
    return (
        <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Calculators | RateShop.ca</title>
          <meta name='description' content="Estimate your closing costs with RateShop.ca's Closing Cost Calculator. Understand all expenses involved in your home purchase and budget accordingly." />
          <meta name="keywords" content=" closing cost calculator, estimate closing costs, home purchase costs, closing expenses, closing cost estimation, RateShop, home buying costs, closing fee calculator, real estate closing costs, home closing costs, closing cost breakdown, closing cost planning, closing cost estimator, calculate closing costs, closing cost guide, closing cost tool, mortgage closing costs, purchase closing costs, closing cost prediction, closing cost calculator Canada"/>
        </Helmet>
            <section className="c-c-calc sec-padding">
                <Container> 
                <div className="cs-content cs-max-w pb-10">
                    <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" to="/">
                    Home
                </Link> 
                <Typography color="text.primary">Calculators </Typography>
            </Breadcrumbs> 
            </div>
            <div
      className="bendigi-calculators"
      ref={calculatorRef}
      apikey="096c437989677939:_1lBxDjjxU8OxTd2avjJI8N7x-Q1xNHm"
      terms="https://canadianmortgageapp.com/terms"
      navPositionTop="0px"
      navPositionTopMobile="0px"
      tools="all"
    ></div>   {/* <iframe id="idIframe" src="https://ref.rateshop.ca/web/rs-closing-cost-calc.php" width="100%" height="1000" title="cmhc insurance calculator" ></iframe> */}
                    {/* <iframe src="https://calc.rateshop.ca/purchase.html" width="100%"    
                    height="1700px"
                    title="Mortgage Payment Calculator"></iframe>  */}
                </Container>
            </section>
        </>
    )
}

export default CalculatorNew;