import React, { useEffect } from "react";
import {
     Button, 
     Container, Grid} from "@mui/material";
import AOS from 'aos';
import "aos/dist/aos.css";
import { useParams as params } from "react-router-dom";
import { posts } from "../../../../config/posts";
import { Markup } from 'react-render-markup';   
import PartnerLogo from '../../../../components/shared/partner-logo/PartnerLogo';
import Partner from '../../../../components/shared/partner/Partner'; 
import Testimonials from "../../../../components/shared/testimonial/Testimonials";
import Helmet from 'react-helmet';
import { NavLink } from "react-router-dom";
import './Blog-detail.scss';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LayersIcon from '@mui/icons-material/Layers';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { Diversity1 } from "@mui/icons-material";
import { Oval } from 'react-loader-spinner';
import { useNavigate,Link } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs'; 
    export default function Gateway() {
        const [allBlogs, setAllBlogs] = React.useState([]);
        const { post_slug } = params(); 
        const [insuredThreeYearFixed, setInsuredThreeYearFixed] = React.useState([]);
        const [insuredFourYearFixed, setInsuredFourYearFixed] = React.useState([]);
        const [insuredFiveYearFixed, setInsuredFiveYearFixed] = React.useState([]);
        const [insuredFiveYearVariable, setInsuredFiveYearVariable] = React.useState([]);
        const [insuredOneYearFixed, setInsuredOneYearVariable] = React.useState([]);
        const [insuredTwoYearFixed, setInsuredTwoYearVariable] = React.useState([]);
    
        const [buyDownLender, setBuyDownLender] = React.useState([]);
        const [buyDownTheirRates, setBuyDownTheirRates] = React.useState([]);
        const [buyDownOurRates, setBuyDownOurRates] = React.useState([]);
        const [buyDownOurSaving, setBuyDownOurSaving] = React.useState([]);
        const navigate = useNavigate();
        const routeChange = () =>{ 
            let path = `/apply`; 
            navigate(path);
          }
        useEffect(() => {
            
            AOS.init();
            AOS.refresh();

            const token = {
                Authorization: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoiQWJoaXNoZWsgUHVyaSIsIm5hbWUiOiJSZWFjdCBSUyIsInBhc3N3b3JkIjpudWxsLCJBUElfVElNRSI6MTY3NjY0MjQyNn0.kcYNItsCPFOtwPIgffGx1Zf-BGDN_Vsx-r0vnGcucOs',
                slug: post_slug,
            };
            // const apiData = {...values, ...slugObj};
            // console.log('valuesss', apiData);
            // setShowLoader(true);
           
            fetch('https://crm.rateshop.ca/ReactController/blogsById', {
                method: 'POST',
                headers: {
                    Accept: 'application/form-data',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(token),
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        if(result.blogs.length == 0){
                            navigate('/404', { replace: true });
                          }
                        setAllBlogs(result.blogs);
                        
                    }
                )

            fetch('https://crm.rateshop.ca/ReactController/mortgageRates', {
            method: 'POST',
            headers: {
                Accept: 'application/form-data',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(token),
        })
            .then(res => res.json())
            .then(
                (result) => {
                  
                    const insuredOneYearFixed = Math.min(...JSON.parse(result.rates.insured_one_year_fixed).filter(function (el) {
                        return el != '';
                      }));
                    const insuredTwoYearFixed = Math.min(...JSON.parse(result.rates.insured_two_year_fixed).filter(function (el) {
                        return el != '';
                      }));
                    const insuredThreeYearFixed = Math.min(...JSON.parse(result.rates.insured_three_year_fixed).filter(function (el) {
                        return el != '';
                      }));
                    const insuredFourYearFixed = Math.min(...JSON.parse(result.rates.insured_four_year_fixed).filter(function (el) {
                        return el != '';
                      }));
                    const insuredFiveYearFixed = Math.min(...JSON.parse(result.rates.insured_five_year_fixed).filter(function (el) {
                        return el != '';
                      }));
                    const insuredFiveYearVariable = Math.min(...JSON.parse(result.rates.insured_five_year_variable).filter(function (el) {
                        return el != '';
                      }));

                    setBuyDownLender(JSON.parse(result.buyDownRates.lender));
                    setBuyDownTheirRates(JSON.parse(result.buyDownRates.their_rates));
                    setBuyDownOurRates(JSON.parse(result.buyDownRates.our_rates));
                    setBuyDownOurSaving(JSON.parse(result.buyDownRates.your_savings));

                    setInsuredThreeYearFixed(insuredThreeYearFixed);
                    setInsuredFourYearFixed(insuredFourYearFixed);
                    setInsuredFiveYearFixed(insuredFiveYearFixed)
                    setInsuredFiveYearVariable(insuredFiveYearVariable);
                    setInsuredOneYearVariable(insuredOneYearFixed);
                    setInsuredTwoYearVariable(insuredTwoYearFixed);

                }
            )   
        }, []);
        if (allBlogs.length == 0) {
            return <Oval
                height={60}
                width={60}
                color="#FFFFFF"
                wrapperStyle={{}}
                wrapperClass="csLoader"
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor="#EEEEEE"
                strokeWidth={4}
                strokeWidthSecondary={4}
            />;
        }
        return (<>
    {/* blog banner section start here */}
        <Helmet>
            <meta charSet="utf-8" />
            <title>{allBlogs[0].meta_title}</title>
            <meta name='description' content={allBlogs[0].meta_description} />
        </Helmet>
        <section className="common-image-bg blog-banner detail-banner">
            <Container>
                <Grid container>
                    <Grid xs={12} sm={12} md={12} lg={12} item >
                        <div className="banner-content"> 
                            <div className="heading">
                                <h1>{allBlogs[0].title}</h1>
                                <p className="text-center">{allBlogs[0].categoryname}</p>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </section>
    {/* blog banner section end here */} 
    <Container> 
            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" to="/">
                    Home
                </Link>
                <Link
                    underline="hover"
                    color="inherit"
                    to="/learning-center/"
                >
                    Learning Center
                </Link>
                <Typography color="text.primary">{allBlogs[0].title}</Typography>
            </Breadcrumbs>
        </Container>
    {/* latest blog section start here */}
        <section className="latest-blog">
            <Container>
                <Grid container>
                    <Grid sm={12} md={12} lg={8} item   >
                        <div className="latest-blog-left blog-detail-left pr-0"> 
                            <Grid container>
                                <Grid xs={12} item  > 
                                    <figure>
                                        <img src={`https://crm.rateshop.ca/assets/blogs/${allBlogs[0].social_media_image}`} alt="blog"/>
                                    </figure>
                                    <div className="blog-text-inner">
                                        <p className="category"><span><PersonOutlineIcon/> {allBlogs[0].author}</span> <span><CalendarMonthIcon/>{allBlogs[0].created_at}</span> <span><LayersIcon/>{allBlogs[0].categoryname}</span> <span><AccessTimeIcon/>{allBlogs[0].reading_time} Minutes Read</span></p>
                                        <Markup markup={allBlogs[0].detail} /> 
                                    </div> 
                                </Grid> 
                            </Grid> 
                        </div>
                    </Grid>
                    <Grid sm={12} md={12} lg={4} item   >
                     
                    {/* .blog-content-wrap .blog-sidebar */}
                   
                        <div className="latest-blog-right"> 
                        <div className="blog-content-wrap blog-side-nw">
                        <div className="blog-sidebar w-100">
                            <div className="get-best-rate">
                                <h6>5 Year - Fixed Term from</h6>
                                <h2>{insuredFiveYearFixed}%</h2>
                                <h4>Apply for your Best Rate in minutes.</h4>
                                <p className="small-text">Everyone's rate is unique.What's yours?</p>
                                <p>It is our job to get your lowest possible rate. Your rate qualification depends on certain factors, such as credit score and home equity as per regulations.</p>
                                <Button onClick={routeChange}  variant="contained">Get your Best Rate</Button>
                            </div> 
                        </div>
                        </div> 
                            {/* <div className="right-blog-box">
                                <h4>Most Popular Posts</h4>
                                <ul>
                                    <li>
                                        <NavLink to="">Canadian Financial Institution Numbers for All Banks</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="">How Much House Can I Afford on $75k? </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="">How Much Are CIBC Aventura Points Worth? </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="">Self-Employed Canadians Will Have an Easier Time with CMHC in 2018</NavLink>
                                    </li>
                                </ul>
                            </div> */}
                        </div>
                    </Grid>
                    
                </Grid>
            </Container>
        </section>
    {/* latest blog section ends here */}
    {/* Partner Logo Start*/}
        {/* <PartnerLogo/> */}
    {/* Partner Logo End*/}

    {/* partner logo Start*/}
        {/* <Partner/> */}
        <Testimonials />
    {/* partner logo End*/}
    </>)
 
}