import React from "react";
import './commercial-mortgage.scss' 
import { Button, Container, Grid} from "@mui/material"; 
import { hourApproval ,freeAppraiel ,quickClosing ,bestRates } from "../../../../core/Images"; 
import PartnerLogo2 from "../../../../components/shared/partner-logo2/PartnerLogo"; 
import Input from '@mui/joy/Input'; 
import Helmet from 'react-helmet';
import { NumericFormat, NumericFormatProps } from 'react-number-format'; 
import FormLabel from '@mui/joy/FormLabel'; 
import PropTypes from 'prop-types';     
import Radio, { radioClasses } from '@mui/joy/Radio';
import RadioGroup from '@mui/joy/RadioGroup';
import Sheet from '@mui/joy/Sheet';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import * as Yup from "yup";
import {useFormik} from "formik";
const NumericFormatAdapter = React.forwardRef(function NumericFormatAdapter(
    props,
    ref,
  ) {
    const { onChange, ...other } = props;
  
    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
        prefix="$"
      />
    );
  });
  
  NumericFormatAdapter.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };
  
  
const CommercialMortgage = () => {
    const handleFormSubmit = (values) => {
        console.log('valuesss', values);
    }
    const initialValues = {
        propertyValue: "",
        firstMortgage: "",
        secondMortgage: "",
        name: "",
        email: "",
        phone: "",
    }
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const formSchema = Yup.object({
        
        propertyValue: Yup.number('Number Only')
        .label('Property Value')
        .integer()
        .min(1000)
        .max(100000)
        .required(),
        firstMortgage: Yup.number()
        .label('Number Only')
        .integer()
        .min(1000)
        .max(100000)
        .required(),
        secondMortgage: Yup.number()
        .label('Number Only')
        .integer()
        .min(1000)
        .max(100000)
        .required(),
        name: Yup.string().required("This is required field"),
        email: Yup.string().email('Enter a valid email').required("This is required field"),
        phone: Yup.string().required("This is required field").matches(phoneRegExp, 'Phone number is not valid'),
        
    });

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue, setFieldTouched } = useFormik({
        initialValues: initialValues,
        validationSchema: formSchema,
        onSubmit: (values) => {
            console.log(11);
          handleFormSubmit(values);
        },
      });

    const [value, setValue] = React.useState('');
    return (<>
    <Helmet>
          <meta charSet="utf-8" />
          <title>Lowest Mortgage Rates | Contact to Get Mortgage Rates Canada</title>
          <meta name='description' content='We are the best mortgage brokerage and offer the lowest mortgage rates in Canada. Contact us to choose from a variety of competitive options to secure the best mortgage rate!' />
        </Helmet>
    {/* contact banner section start here */}

        {/* commercial mortgage banner */}
        <section className="commercial-mortgage">
            <Container>
                <Grid container>
                <Grid sm={12} item>
                    <div className="heading text-center">
                        <h2><span>Commercial </span>Mortgages Made Simple</h2>
                    </div>
                    <ul className="commercial-mortgage--icons">
                        <li>
                            <figure>
                                <img src={hourApproval} alt=""/>
                                <figcaption>24 Hour Approvals</figcaption>
                            </figure>
                        </li>
                        <li>
                            <figure>
                                <img src={freeAppraiel} alt=""/>
                                <figcaption>Free Appraisal</figcaption>
                            </figure>
                        </li>
                        <li>
                            <figure>
                                <img src={quickClosing} alt=""/>
                                <figcaption>Quick Closing</figcaption>
                            </figure>
                        </li>
                        <li>
                            <figure>
                                <img src={bestRates} alt=""/>
                                <figcaption>Best Rate</figcaption>
                            </figure>
                        </li>
                    </ul>
                    </Grid>
                </Grid>
            </Container>
        </section>
        {/* mortgage-for */}
        <section className="mortgage-for">
            <Container>
                <Grid container>
                    <Grid lg={4} md={4} xs={12} item>
                        <div className="heading text-center">
                            <h2><span>Commercial </span>Mortgage For</h2>
                        </div>
                        <ul className="mortgage-for--listing">
                            <li>Mixed-use properties</li>
                            <li>Multi-family residential</li>
                            <li>Industrial buildings</li>
                            <li>Office buildings</li>
                            <li>Medical building</li>
                            <li>Retail plazas</li>
                            <li>Warehouses</li>
                            <li>Shopping malls</li>
                            <li>Land Development</li> 
                        </ul>
                    </Grid>
                    <Grid lg={8} md={8} xs={12} item>
                        <div className="approval-step-form"> 
                        <form method="post"  onSubmit={handleSubmit}>     
                            <div className="heading"> 
                                <h2>  Get approved in 3 simple steps</h2>
                            </div>
                            <div className="step">
                                <div className="arrow1">Step 1</div>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td className="w-215">Select Loan Type</td>
                                            <td>  
                                                <RadioGroup
                                                    aria-label="loanType"
                                                    defaultValue="Purchase"
                                                    overlay
                                                    
                                                    name="loanType"
                                                    sx={{
                                                        flexDirection: 'row',
                                                        gap: 2,
                                                        [`& .${radioClasses.checked}`]: {
                                                            [`& .${radioClasses.action}`]: {
                                                                inset: -1,
                                                                border: '3px solid',
                                                                borderColor: '#2e9a0e',
                                                            },
                                                            },
                                                            [`& .${radioClasses.radio}`]: {
                                                            display: 'contents',
                                                            '& > svg': {
                                                                zIndex: 2,
                                                                position: 'absolute',
                                                                top: '-8px',
                                                                right: '-8px',
                                                                bgcolor: 'background.surface',
                                                                borderRadius: '50%',
                                                                color:'#2e9a0e'
                                                        },
                                                        },
                                                    }}
                                                    >
                                                    {['Purchase', 'Refinance'].map((value) => (
                                                        <Sheet
                                                        key={value}
                                                        variant="outlined"
                                                        sx={{
                                                            borderRadius: '8px',
                                                            boxShadow: 'sm',
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            alignItems: 'center', 
                                                            p: 2,
                                                            minWidth: 140,
                                                        }}
                                                        >
                                                            <Radio
                                                            
                                                            id={value} value={value} checkedIcon={<CheckCircleRoundedIcon />} /> 
                                                            <FormLabel htmlFor={value}>{value}</FormLabel>
                                                        </Sheet>
                                                    ))}
                                                </RadioGroup>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Select Mortgage Type</td>
                                            <td> 
                                                <RadioGroup
                                                    aria-label="mortgageType"
                                                    defaultValue="1st Mortgage"
                                                    overlay
                                                    name="mortgageType"
                                                    sx={{
                                                        flexDirection: 'row',
                                                        gap: 2,
                                                        [`& .${radioClasses.checked}`]: {
                                                        [`& .${radioClasses.action}`]: {
                                                            inset: -1,
                                                            border: '3px solid',
                                                            borderColor: '#2e9a0e',
                                                        },
                                                        },
                                                        [`& .${radioClasses.radio}`]: {
                                                        display: 'contents',
                                                        '& > svg': {
                                                            zIndex: 2,
                                                            position: 'absolute',
                                                            top: '-8px',
                                                            right: '-8px',
                                                            bgcolor: 'background.surface',
                                                            borderRadius: '50%',
                                                            color:'#2e9a0e'
                                                        },
                                                        },
                                                    }}
                                                    >
                                                    {['1st Mortgage', '2nd Mortgage'].map((value) => (
                                                        <Sheet
                                                        key={value}
                                                        variant="outlined"
                                                        sx={{
                                                            borderRadius: '8px',
                                                            boxShadow: 'sm',
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            alignItems: 'center', 
                                                            p: 2,
                                                            minWidth: 140,
                                                        }}
                                                        >
                                                            <Radio id={value} value={value} checkedIcon={<CheckCircleRoundedIcon />} /> 
                                                            <FormLabel htmlFor={value}>{value}</FormLabel>
                                                        </Sheet>
                                                    ))}
                                                </RadioGroup> 
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="step step-2">
                                <div className="arrow1">Step 2</div>
                                    <Grid container>
                                        <Grid lg={8} xs={12}  item className="pr-0" >
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>Property Value</td>
                                                    <td> 
                                                    <Input name="propertyValue"
                                                            onBlur={handleBlur}
                                                            value={values.propertyValue}
                                                            onChange={handleChange}
                                                            placeholder="Placeholder" />
                                                        {touched.propertyValue && <p>{errors.propertyValue}</p>}
                                                    </td>  
                                                </tr>
                                                <tr className="mortgage1">
                                                    <td>1st Mortgage Request</td>
                                                    <td>  
                                                        <Input name="firstMortgage"
                                                        value={values.firstMortgage}
                                                        onChange={handleChange}
                                                            // onChange={(event) => setValue(event.target.value)}
                                                            placeholder="$0"
                                                            // slotProps={{
                                                            // input: {
                                                            //     component: NumericFormatAdapter,
                                                            // },
                                                            // }}
                                                        /> 
                                                        {touched.firstMortgage && <p>{errors.firstMortgage}</p>}
                                                    </td>  
                                                </tr>
                                                <tr className="mortgage2">
                                                    <td>2st Mortgage Request</td>
                                                    <td> 
                                                        <Input name="secondtMortgage"
                                                        value={values.secondtMortgage}
                                                        onChange={handleChange}
                                                            // value={value}
                                                            // onChange={(event) => setValue(event.target.value)}
                                                            placeholder="$0"
                                                            // slotProps={{
                                                            // input: {
                                                            //     component: NumericFormatAdapter,
                                                            // },
                                                            // }}
                                                        />  
                                                        {touched.secondMortgage && <p>{errors.secondMortgage}</p>}
                                                        </td>  
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Grid>
                                        <Grid lg={3} xs={12} item >
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <div className="ltv_value">100%</div><div className="ltv">LTV</div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table> 
                                        </Grid>
                                    </Grid>  
                                </div>
                                <div className="step step-3">
                                    <div className="arrow1">Step 3</div> 
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td><Input size="md"  name="name"
                                                        value={values.name}
                                                        onChange={handleChange} placeholder="Name" />{touched.name && <p>{errors.name}</p>}</td>
                                                <td><Input size="md"  name="email"
                                                        value={values.email}
                                                        onChange={handleChange} placeholder="Email" />{touched.email && <p>{errors.email}</p>}</td>
                                                <td><Input size="md"  name="phone"
                                                        value={values.phone}
                                                        onChange={handleChange} placeholder="Phone" />{touched.phone && <p>{errors.phone}</p>}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div> 
                                <div className="step last d-flex justify-content-center"> 
                                    <Button type="submit" variant="contained" color="secondary">
                                         Approve Me!
                                    </Button>  
                                </div> 
                            </form>
                        </div>
                    </Grid>
                </Grid>
            </Container> 
        </section>

        {/* partner logos */} 
        <div className="commercial-partner-logos">
            <PartnerLogo2 />
        </div> 
        {/* content block */}
        <section className="content-block">
            <Container>
                <Grid container>
                    <Grid  xs={12} item>
                        <div className="content-block--wrap">
                            <h2>Commercial Mortgages</h2>
                            <p>Like residential mortgages, a commercial mortgage is money that you borrow in order to purchase a property. While the difference between a residential property and a commercial one might seem small, it actually has a huge impact on the terms of the mortgage.</p>
                        </div>
                        <div className="content-block--wrap">
                            <h2>What types of properties are considered commercial?</h2>
                            <p>There are some obvious examples of commercial real estate properties, such as stores, office buildings, and warehouses. But some residential properties can be purchased with a commercial mortgage if the reason for purchasing is commercial in nature – a multi-unit apartment building that you plan to rent out can be considered commercial.</p>
                            <div className="content-block--wrap-table">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Types of Commercial Mortgages</th>
                                            <th>Example Property</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>Pure Residential (Investment Properties)</th>
                                            <td>
                                                <p>1 – 4 units, such as a home, duplex, or townhouses</p>
                                                <p>5 or more units, such as an apartment complex or condo building</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Residential Commercial Mixed</th>
                                            <td>
                                                <p>Ground-level storefront with apartments above</p>
                                                <p>Multi-level office space with commercial space on the ground floor</p>
                                                <p>Live/work (business operating on ground floor of residential property)</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Pure Commercial</th>
                                            <td>
                                                <p>Shops</p>
                                                <p>Shopping malls</p>
                                                <p>Office building</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="content-block--wrap">
                            <h2>What are commercial mortgage rates?</h2>
                            <p>Commercial mortgage rates are higher than residential rates. The exact rate depends on the type of property being financed.</p>
                        </div>
                        <div className="content-block--wrap">
                            <h2>Commercial Mortgage Fees</h2>
                            <p>Commercial mortgages have higher rates than residential mortgages, but they also have higher fees. You can expect to pay thousands of dollars in set up fees, including:</p>
                            <h3>Lender fees</h3>
                            <p>Depending on the lender that finances your mortgage, the lender fee varies. On average, your lender will charge between 4 and 6% of the mortgage amount.</p>
                            <h3>Broker fees</h3>
                            <p>Because commercial mortgages are harder to find financing for than residential mortgages, your broker will charge you a fee to find the best commercial mortgage rates. This is usually between 1 and 2%.</p>
                            <h3>Legal fees</h3>
                            <p>These include paying for services provided by a real estate lawyer or notary, such as performing a title search, and registration fees and disbursements. This usually costs $3,500 or more.</p>
                            <h3>Appraisal</h3>
                            <p>An appraisal is necessary to determine the value of the property, which will dictate how much money you're able to borrow. The more the property is worth, the more you're able to borrow – but you'll also have to pay more for your down payment. The normal cost is around $1,500.</p>
                            <h3>Title insurance</h3>
                            <p>Title insurance is a low, one-time cost that protects the legal property owner from future title fraud and challenges. It's valid for as long as you're the owner of the property, making it a very cheap way to protect yourself. On average you can expect to pay about $1,500.</p>
                            <h3>CMHC Insurance for Commercial Properties</h3>
                            <p>CMHC will not insure a purely commercial property, but they will insure a commercial mortgage that will be used as a residential property, like an apartment complex, retirement dwelling, licensed care facility, or condominium building.</p>
                        </div>
                        <div className="content-block--wrap">
                            <h2>How to Qualify for a Commercial Mortgage</h2>
                            <p>The bar for commercial mortgages is quite high because they're riskier than normal mortgages. Some of the criteria you'll have to satisfy are:</p>
                            <h3>Debt service coverage ratio</h3>
                            <p>How much lenders are willing to let you borrow is directly related to how much debt you already have. The actual number value of your debt isn't important, what's important is how much debt you have relative to your cash flow. The debt service coverage ratio (DSCR) is calculated as:</p>
                            <p className="highlighted-text">DSCR = Net operating costs ÷ Total debt service</p>
                            <p>If your DSCR is below 1, then you have a negative cash flow and have to rely on outside sources to make up the difference. Lenders don't like negative cash flow as a rule, but may make exceptions if the borrower has a lot of assets. If the ratio is close to 1, such as 1.1, then the property is vulnerable and is at risk of having a negative cash flow in the future. It's important to get your DSCR as high as possible when applying for a commercial mortgage.</p>
                            <h3>Credit score</h3>
                            <p>A good personal credit score bodes well for your business because it means that someone responsible is at the helm. The lower your score, the less likely it is that you'll be approved for a commercial mortgage.</p>
                            <h3>Your business</h3>
                            <p>It isn't every day that average investors try and get a commercial mortgage. Usually, it's businesses that are applying for them – so it's imperative that your business is in a good financial position. Your lender will want proof that your business will be able to earn enough revenue to pay for the mortgage, and often require a minimum net worth of $100,000 - $200,000.<br></br>Your type of business may also play a factor in your approval. Is your business poised for growth?</p>
                            <h3>Down payment</h3>
                            <p>The expected down payment for a commercial mortgage is much higher than a residential one. A typical down payment is 20 – 35% for a mixed use property, while a pure commercial property can require as much as 50% down.</p>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </section>

    </>)
}

export default CommercialMortgage