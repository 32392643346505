import React, { useEffect } from "react";
import Teams from "../../../../components/shared/team/Teams";
import { Container, Grid } from "@mui/material";
import Button from '@mui/material/Button';
import "./home.scss";
import Blog from "../../../../components/shared/blog/Blog";
import AOS from 'aos';
import "aos/dist/aos.css";
import {
  Link
} from "react-router-dom";
import {
  logo,
  logo2,
  logo3,
  choose1,
  choose2,
  choose3,
  choose4,
  choose5,
  choose6,
  centerImage,
  banner,
} from "../../../../core/Images";
import Testimonials from "../../../../components/shared/testimonial/Testimonials";
import LocationCarousel from "../../../../components/shared/location-carousel/location";
import BlogCarousel from "../../../../components/shared/blogs-carousel/blogs";
import MortgageRate from "../../../../components/shared/mortgage-rate/MortgageRate";
import Calculator from "../../../../components/shared/calculator/Calculator";
import PartnerLogo2 from "../../../../components/shared/partner-logo2/PartnerLogo";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { teamMemberOne, teamMemberTwo, teamMemberThree } from "../../../../core/Images";
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import get500 from "../../../../assets/public/images/webp/get-500.webp";
import mobileGif1 from "../../../../assets/public/gif/first.gif";
import mobileGif2 from "../../../../assets/public/gif/second.gif";
import mobileGif3 from "../../../../assets/public/gif/third.gif";
import mobileGif4 from "../../../../assets/public/gif/fourth.gif";
import icon1 from "../../../../assets/public/images/icons/icon-1.svg";
import icon2 from "../../../../assets/public/images/icons/icon-2.svg";
import icon3 from "../../../../assets/public/images/icons/icon-3.svg";
import abc from "../../../../assets/public/images/video-step1.mp4";
import Helmet from 'react-helmet';
import credit1 from "../../../../assets/public/images/review and compare icon.svg";
import credit2 from "../../../../assets/public/images/No Credit Checks icon.svg";
import credit3 from "../../../../assets/public/images/haggle free best rates icon.svg";
import rslogo from '../../../../assets/public/images/webp/rateshop-logo.webp';

import awardwinning from '../../../../assets/public/images/top-reasons/award-winning.png';
import bestrates from '../../../../assets/public/images/top-reasons/best-rates.png';
import customersloveus from '../../../../assets/public/images/top-reasons/customers-love-us.png';
import easetouseaiapplication from '../../../../assets/public/images/top-reasons/ease-to-use-ai-application.png';
import freemortgageratemonitoring from '../../../../assets/public/images/top-reasons/free-mortgage-rate-monitoring.png';
import largestchoiceoflenders from '../../../../assets/public/images/top-reasons/largest-choice-of-lenders.png';
import mortgagelendersloveus from '../../../../assets/public/images/top-reasons/mortgage-lenders-love-us.png';
import samedayfilereview from '../../../../assets/public/images/top-reasons/same-day-file-review.png';

// import { JsonLd } from 'react-schemaorg';

// import QuickThanks from "../../../../components/popup/QuickThanks";
// import QuickFormCompletion from "../../../../components/popup/QuickFormCompletion";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import { useNavigate  } from 'react-router-dom';
import Cookies from 'universal-cookie';
const Home = () => {
  let navigate = useNavigate(); 
  const cookies = new Cookies();
  
  const routeChange = () =>{ 
    var path = '';
    if(cookies.get('cookieAgentSlug') != '' && cookies.get('cookieAgentSlug') != undefined){
      var path = `apply/`+cookies.get('cookieAgentSlug');  
    }else{
      var path = `apply`;
    }
    navigate(path);
  }
  const [baseUrl, setBaseUrl] = React.useState('/');
  useEffect(() => {
    AOS.init();
    AOS.refresh();
    if(cookies.get('cookieAgentSlug') != undefined && cookies.get('agentSite')){
      setBaseUrl('/'+cookies.get('cookieAgentSlug')+'/');
    }
  }, []);
  var option3 = {
    items: 1,
    responsiveClass: true,
    dots: false,
    autoplay: true,
    nav: false,
    autoWidth: false,
    loop: true,
  }

  return (
    <>
      <Helmet>
          <meta charSet="utf-8" />
          <title>Best Mortgage Rates in Canada</title>
          <meta name='description' content="Find and compare the best mortgage rates in Canada with RateShop.ca. Secure the lowest rates available and save on your mortgage." />
          <meta name="keywords" content=" mortgage rates, best mobest mortgage rates, compare mortgage rates, Canada mortgage rates, lowest mortgage rates, save on mortgage, RateShop, mortgage comparison, mortgage savings, low mortgage rates, mortgage deals, secure mortgage rates rates, mortgage interest rates, find mortgage rates, mortgage rate comparison, mortgage rate Canada, best mortgage deals, mortgage quotes, mortgage rate finder, mortgage rate calculator, mortgage rate search, lowest mortgage rates"/>
      </Helmet>
      {/* <QuickFormCompletion/> */}
      {/* <QuickThanks/> */}
      {/* banner start here */}
      <section className="banner">
        <img className="get500" src={get500} alt="Find $500 for Lowest Mortgage Rates" />
        <img src={banner} alt="Get Canada's Lowest Mortgage Rates" className="banner-img" />
        <Container>
          <Grid container>
            <Grid lg={7} className="d-flex flex-column align-items-start" item data-aos="fade-right" data-aos-delay="300" data-aos-duration="1000">
              <div className="heading"  >
                <h1>
                  Canada's <span>Lowest Mortgage Rates</span><br /> Every Time, Guaranteed!
                </h1>
              </div>
              <div className="line"></div>
              <p>Haggle free Lowest Rates Upfront</p>
              <p>No Credit Checks, No Strings Attached</p>
              <Link to={`${window.location.origin}/apply${baseUrl}`}>
                <Button variant="contained">Quick Apply in 60 seconds</Button>
              </Link>
              <p>Or<b> Call Now </b> for a <b> Free Rate Quote </b> <Link to={"/"}>1800-725-9946</Link></p>
            </Grid>
          </Grid>
        </Container>
      </section>
      {/* banner end here */}

      {/* award winning start here */}
      <section className="award-winning">
        <img className="get500" src={get500} alt="" />
        <Container>
          <Grid container className="align-items-center">
            <Grid sm={6} md={6} lg={6} item>
              <div className="award-heading">
                <h2>
                  <span className="strt-line">WE ARE CANADA’S LEADING</span>
                  AWARD WINNING
                  <span> MORTGAGE BROKERAGE.</span>
                </h2>
              </div>
            </Grid>
            <Grid sm={6} md={6} lg={6} item>
              <ul className="award-logos d-flex justify-content-between align-items-center">
                <li>
                  <img src={logo} width="150" height="150"  alt="Reader Choice Award Winning Mortgage Brokerage"/>
                </li>
                <li>
                  <img src={logo2}  width="150" height="150" alt="Canadian Mortgage Professional Magazine 2021Top Brokerage"/>
                </li>
                <li>
                  <img src={logo3} width="150" height="150" alt="Top Independent Brokerages" />
                </li>
              </ul>
            </Grid>
          </Grid>
        </Container>
      </section>
      {/* award winning end here */}
      <PartnerLogo2 />
      {/* Testimonial Section Start */}
      <Testimonials />
      {/* Testimonial Section End */}

      {/* how it works start */}
      <section className="how-it-works">
        <Container>
          <div className="heading">
            <h3>
              <span>How</span> it works
            </h3>
          </div>
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            navigation={true}
            modules={[Navigation]}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className="how-it-works-inner">
                <div className="top-content">
                  {/* <h5>Step 1</h5> */}
                  <figure>
                    <img src={mobileGif1} alt="Application form to get Lowest Mortgage Rate" width="400" height="800"/>
                    <span></span>
                  </figure>
                  {/* <video width={"100%"} height={"100%"} autoPlay controls>
                    <source src={abc} type="video/mp4" />
                  </video> */}
                  <div className="info-text">Fill Quick 2 Minute Application</div>
                </div>
                <div className="h-i-w-text">
                  <h2>Quick Start Your Free Rate Quote</h2>
                  <p>Dozens of lender mortgage rates in under 2 minutes personalized by your transaction type. No credit checks, No strings attached.</p>
                  <p>RateShop works with mortgage banks, lenders and credit unions across Canada, to secure the best mortgage rates for you.</p>
                  <div className="d-flex justify-content-center mt-60 pos-button">
            <Link to={`${window.location.origin}/apply${baseUrl}`}>
              <Button variant="contained">Get started</Button>
            </Link>
          </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="how-it-works-inner">
                <div className="top-content">
                  {/* <h5>Step 2</h5> */}
                  <figure>
                    <img src={mobileGif3} alt="Rateshop"  width="400" height="800"/>
                    <span></span>
                  </figure>
                  <div className="info-text">Get Approved For Your Lowest Rate</div>
                </div>
                <div className="h-i-w-text">
                  <h2>Compare dozens of Lender Rates</h2>
                  <p>Compare your mortgage offers from the lowest rate lenders and review your savings,  amortization and type of mortgage options.</p>
                  <p>Get free unbiased advice to help you land the best mortgage rate and learn about the terms of your new mortgage before signing.</p>
                  <div className="d-flex justify-content-center mt-60 pos-button">
            <Link to={`${window.location.origin}/apply${baseUrl}`}>
              <Button variant="contained">Get started</Button>
            </Link>
          </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="how-it-works-inner">
                <div className="top-content">
                  {/* <h5>Step 3</h5> */}
                  <figure>
                    <img src={mobileGif2} alt="Rateshop"  width="400" height="800"/>
                    <span></span>
                  </figure>
                  <div className="info-text">Online Easy Mortgage Approval Experience</div>
                </div>
                <div className="h-i-w-text">
                  <h2>Online & Convenient Approvals</h2>
                  <p>Our super low rates are guaranteed, that means we're so confident you're getting the best mortgage rate, $500 on us if you don't.</p>
                  <p>Enjoy a transparent online approval process and electronic signing at your convenience, no matter where you are.</p>
                  <div className="d-flex justify-content-center mt-60 pos-button">
            <Link to={`${window.location.origin}/apply${baseUrl}`}>
              <Button variant="contained">Get started</Button>
            </Link>
          </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide><div className="how-it-works-inner">
              <div className="top-content">
                {/* <h5>Step 4</h5> */}
                <figure>
                  <img src={mobileGif4} alt="Rateshop"  width="400" height="800"/>
                  <span></span>
                </figure>
                <div className="info-text">Get Funds & Save Thousands Instantly</div>
              </div>
              <div className="h-i-w-text">
                <h2>Real Time Mortgage Tracking</h2>
                <p>Track of your mortgage approval process to the minute, every step of the way with real-time notifications for every milestone.</p>
                <p>Approvals in as little as 24 hours, streamlined closing process, and don't forget that juicy cashback to top it off.</p>
                <p>Finally, an unforgettably pleasant experience.</p>
              <div className="d-flex justify-content-center mt-60 pos-button">
            <Link to={`${window.location.origin}/apply${baseUrl}`}>
              <Button variant="contained">Get started</Button>
            </Link>
          </div>
              </div> 
            </div>
            </SwiperSlide>
            {/* <SwiperSlide>Slide 5</SwiperSlide>
            <SwiperSlide>Slide 6</SwiperSlide>
            <SwiperSlide>Slide 7</SwiperSlide>
            <SwiperSlide>Slide 8</SwiperSlide>
            <SwiperSlide>Slide 9</SwiperSlide> */}
          </Swiper>
          
         
          {/* <Grid container>
            <Grid sm={3} md={3} lg={3} item data-aos="zoom-in-right" data-aos-delay="200" data-aos-duration="1000">
            </Grid>
            <Grid sm={3} md={3} lg={3} item data-aos="zoom-in-down" data-aos-delay="200" data-aos-duration="1000">
            </Grid>
            <Grid sm={3} md={3} lg={3} item data-aos="zoom-in-left" data-aos-delay="200" data-aos-duration="1000">
            </Grid>
            <Grid sm={3} md={3} lg={3} item data-aos="zoom-in-left" data-aos-delay="200" data-aos-duration="1000">
            </Grid>
          </Grid> */}
          {/* <div className="d-flex justify-content-center mt-60">
            <Link to='/apply/'>
              <Button variant="contained">Get started</Button>
            </Link>
          </div> */}
        </Container>
      </section>

            {/* top reasons */}
            <section className="top-reasons">
        <Container>
          <div className="heading">
            <h2>
              <span>Top Reasons</span>&nbsp;To Get a Mortgage With  <img src={rslogo} alt="logo" />
            </h2>
          </div>
          <div className="choose-rate-content"> 
            <Grid container className="align-items-center">
              <Grid md={12}  item>  
                <Grid container className="justify-content-center">
                  <Grid sm={12} md={6} lg={4} item><div className="choose-innner" data-aos="fade-up" data-aos-delay="600" data-aos-duration="1000">
                    <figure>
                      <img src={bestrates} alt="" />
                    </figure>
                     
                    <h2 className="why-choose-title">Best Mortgage Rates. Period.</h2>
                    <p>We feature rock-bottom rates from several banks, credit unions, and lenders plus many exclusive low rates that aren’t available through other brokers or lenders directly.</p>
                  </div>
                  </Grid>
                  <Grid sm={12} md={6} lg={4} item><div className="choose-innner" data-aos="fade-up" data-aos-delay="600" data-aos-duration="1000">
                    <figure>
                      <img src={samedayfilereview}  alt="" />
                    </figure>
                    <h2 className="why-choose-title">Same Day File Review</h2>
                    <p>Our service commitment is, we will never sit idle on a file. The day we have your application & documents, we start our review immediately to analyze all our lender offers and suggest the best one for you.</p> 
                  </div>
                  </Grid>
                  <Grid sm={12} md={6} lg={4} item><div className="choose-innner" data-aos="fade-up" data-aos-delay="600" data-aos-duration="1000">
                    <figure>
                      <img src={largestchoiceoflenders}  alt="" />
                    </figure>
                    <h2 className="why-choose-title">Largest Choice of Lenders</h2>
                    <p>We offer the most comprehensive list of banks, credit unions, monoline lenders you’ll find in Canada. More choice, better rates, greater flexibility and dozens of purchase, refinance, renewal & home equity programs.</p>
                  </div>
                  </Grid>
                  <Grid sm={12} md={6} lg={4} item><div className="choose-innner" data-aos="fade-up" data-aos-delay="600" data-aos-duration="1000">
                    <figure>
                      <img src={freemortgageratemonitoring}  alt="" />
                    </figure>
                    <h2 className="why-choose-title">Free Mortgage Rate Monitoring </h2>
                    <p>Stay on top of mortgage savings, rate specials, wealth creation tips & investment opportunities. breaking news affecting mortgage rates and real estate in Canada. We use our Ai technology to compare your rate monthly with available lender offers and find the savings for you.</p>
                  </div>
                  </Grid>
                  <Grid sm={12} md={6} lg={4} item><div className="choose-innner" data-aos="fade-up" data-aos-delay="600" data-aos-duration="1000">
                    <figure>
                      <img src={easetouseaiapplication}  alt="" />
                    </figure>
                    <h2 className="why-choose-title">Easy Mortgage Approval</h2>
                    <p>Our application searches from 700+ mortgage rates and promotions to compare your term, rate, and location from 65+ lenders in Canada, that finds your best suited rate for your unique situation and shows you how to save money for you.</p>
                  </div>
                  </Grid>
                  <Grid sm={12} md={6} lg={4} item> <div className="choose-innner" data-aos="fade-up" data-aos-delay="600" data-aos-duration="1000">
                    <figure>
                      <img src={awardwinning}  alt="" />
                    </figure>
                    <h2 className="why-choose-title">Award-winning Service</h2>
                    <p>We work relentlessly to improve every aspect of our service, and we’ve received numerous accolades from our lenders and the mortgage industry awards. Our mission is to provide the best mortgage offerings & services on the planet.</p>
                  </div>
                  </Grid>
                  <Grid sm={12} md={6} lg={4} item> <div className="choose-innner" data-aos="fade-up" data-aos-delay="600" data-aos-duration="1000">
                    <figure>
                      <img src={customersloveus}  alt="" />
                    </figure>
                    <h2 className="why-choose-title">Customers Love Us</h2>
                    <p>We always tell our clients, it's not just about the rate! Finding a solution that works for you, instead of costs you more in the future is what our mortgage planning is about. In two to three years into the mortgage term, this becomes very apparent. Customers have called us back telling us how our advice saved them money and educated them to manage finances better.</p>
                  </div>
                  </Grid>
                  <Grid sm={12} md={6} lg={4} item> <div className="choose-innner" data-aos="fade-up" data-aos-delay="600" data-aos-duration="1000">
                    <figure>
                      <img src={mortgagelendersloveus}  alt="" />
                    </figure>
                    <h2 className="why-choose-title">Mortgage Lenders Love Us</h2>
                    <p>As a volume broker, our lender relations are important. That's what gets us great rates, and this is possible because we know the programs, we're fluent in underwriting and we are efficient with approvals. Who & What you know in the industry matters, we understand all bank programs and know where to find you flexibility and rate discounts.</p>
                  </div>
                  </Grid>
                </Grid>
              </Grid> 
            </Grid> 
          </div>
        </Container>
      </section>
      {/* why choose rateshop start */}
      <section className="why-choose-rateshop">
        <Container>
          <div className="heading">
            <h2>
              <span>Why</span> Choose   <img src={rslogo} alt="logo" />
            </h2>
          </div>
          <div className="choose-rate-content">

            <Grid container className="align-items-center">
              <Grid md={12} lg={8} item>  
                <Grid container>
                  <Grid sm={12} md={6} lg={6} item><div className="choose-innner" data-aos="fade-right" data-aos-delay="600" data-aos-duration="1000">
                    <figure>
                      <img src={choose3} alt="Compare Rates, Lenders and Your Savings" width="" height="" />
                    </figure>
                     
                    <h2 className="why-choose-title">Compare Rates, Lenders and Your Savings</h2>
                  </div>
                  </Grid>
                  <Grid sm={12} md={6} lg={6} item><div className="choose-innner" data-aos="fade-right" data-aos-delay="600" data-aos-duration="1000">
                    <figure>
                      <img src={choose1} alt="Unbiased Mortgage Advice to help Financial Growth" width="" height="" />
                    </figure>
                    <h2 className="why-choose-title">Unbiased Mortgage Advice to help Financial Growth</h2>
                  </div>
                  </Grid>
                  <Grid sm={12} md={6} lg={6} item><div className="choose-innner" data-aos="fade-right" data-aos-delay="600" data-aos-duration="1000">
                    <figure>
                      <img src={choose2} alt="Digital Experience, Guaranteed lowest Rates" width="" height="" />
                    </figure>
                    <h2 className="why-choose-title">100% Digital Experience, Guaranteed lowest Rates</h2>
                  </div>
                  </Grid>
                  <Grid sm={12} md={6} lg={6} item><div className="choose-innner" data-aos="fade-right" data-aos-delay="600" data-aos-duration="1000">
                    <figure>
                      <img src={choose5} alt="Streamlined Funding Guaranteed, You're covered!" width="" height="" />
                    </figure>
                    <h2 className="why-choose-title">Streamlined Funding Guaranteed, You're covered!</h2>
                  </div>
                  </Grid>
                  <Grid sm={12} md={6} lg={6} item><div className="choose-innner" data-aos="fade-left" data-aos-delay="600" data-aos-duration="1000">
                    <figure>
                      <img src={choose4} alt="Canada-wide Mortgage Lending" width="" height="" />
                    </figure>
                    <h2 className="why-choose-title">Canada-wide Mortgage Lending</h2>
                  </div>
                  </Grid>
                  <Grid sm={12} md={6} lg={6} item> <div className="choose-innner" data-aos="fade-left" data-aos-delay="600" data-aos-duration="1000">
                    <figure>
                      <img src={choose6}  alt="" />
                    </figure>
                    <h2 className="why-choose-title">5 Star Rated Service with Discounted Online Rates</h2>
                    
                  </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={4} xs={12}>
                <div className="center-img" >
                  <img src={centerImage} alt="Get Best Mortgage Rates in Canada" width="541" height="366" data-aos="zoom-in" data-aos-delay="600" data-aos-duration="1000" />
                </div>
              </Grid>
            </Grid>
            <div className="d-flex justify-content-center mt-20">
              <Link to={`${window.location.origin}/apply${baseUrl}`}>
                <Button variant="contained">Get Started Now</Button>
              </Link>
            </div>
          </div>
        </Container>
      </section>
      {/* why choose rateshop end */}

      {/* Quick App Start*/}
      {/* <QuickApp /> */}
      {/* Quick App End*/}

      {/* What looking for start here  */}

      {/* <section className="looking-for">
        <Container>
          <div className="heading">
            <h2>
              <span>What</span> are you looking to do?
            </h2>
          </div>
          <Grid container>
            <Grid sm={6} md={8} lg={8} item></Grid>
            <Grid sm={6} md={4} lg={4} item>
              <div className="sevices-outer services0" data-aos="fade-left" data-aos-delay="100" data-aos-duration="1000">
                <img src={services1} alt="services" />
                <h4>
                  <NavLink to="">A Purchase</NavLink>
                </h4>
              </div>
            </Grid>
            <Grid sm={6} md={4} lg={4} item>
              <div className="sevices-outer services" data-aos="fade-right" data-aos-delay="200" data-aos-duration="1000">
                <img src={services2} alt="services" />
                <h4>
                  <NavLink to="">A Refinace</NavLink>
                </h4>
              </div>
            </Grid>
            <Grid sm={6} md={4} lg={4} item>
              <div className="sevices-outer services1" data-aos="fade-right" data-aos-delay="300" data-aos-duration="1000">
                <img src={services3} alt="services" />
                <h4>
                  <NavLink to="">A Renewal/Switch</NavLink>
                </h4>
              </div>
            </Grid>
            <Grid sm={6} md={4} lg={4} item>
              <div className="sevices-outer services2" data-aos="fade-right" data-aos-delay="400" data-aos-duration="1000">
                <img src={services4} alt="services" />
                <h4>
                  <NavLink to="">A Home Equity line</NavLink>
                </h4>
              </div>
            </Grid>
          </Grid>
          <Grid md={12} lg={12} item className="mt-80 d-flex justify-content-center">
            <Button variant="contained">
              Apply Now
            </Button>
          </Grid>
          <div className="shape-1">
            <img src="" alt=""/>
          </div>
        </Container>
      </section> */}

      {/* <section className="expert-advice">
        <div className="expert-images">
          <OwlCarousel
            {...option3}
          >

            <div className="item">
              <figure>
                <img src={teamMemberTwo} alt="expert" />
              </figure>
              <div className="item-overlay">
                <h3>Gabi Mihajlovic</h3>
                <h4>RateShop Advisor</h4>
                <div className="ratIng">
                  <div className="r-starts">
                    <StarIcon />
                    <StarIcon />
                    <StarIcon />
                    <StarIcon />
                    <StarIcon />
                  </div>
                  <p>5.0 average rating on 149 transactions</p>
                </div>
              </div>
            </div>
            <div className="item">
              <figure>
                <img src={teamMemberThree} alt="expert" />
              </figure>
              <div className="item-overlay">
                <h3>Raman Saini</h3>
                <h4>RateShop Advisor</h4>
                <div className="ratIng">
                  <div className="r-starts">
                    <StarIcon />
                    <StarIcon />
                    <StarIcon />
                    <StarIcon />
                    <StarHalfIcon />
                  </div>
                  <p>4.9 average rating on 112 transactions</p>
                </div>
              </div>
            </div>
          </OwlCarousel>
        </div>
        <Container>
          <Grid container className="align-items-center">
            <Grid sm={12} md={6} lg={6} item>
            </Grid>
            <Grid sm={12} md={6} lg={6} item className="expert-advise-outer">
              <div className="expert-advice-text">
                <div className="heading">
                  <h2><span>Low Rates</span>, Fast Approvals, and a Trusted Name</h2>
                </div>
                <h3>#YourBestRate, Everytime!</h3>
                <ul>
                  <li><p>Get an Unbiased Choice of lender</p></li>
                  <li><p>Program and rate that suits your personal needs.</p></li>
                  <li><p>Dedicated Advisor from start to finish</p> </li>
                  <li><p>Unmatched Technology and convenience</p></li>
                </ul>
                <div className="expert-low-rates">
                  <ul>
                    <li>
                      <figure>
                        <img src={icon1} alt="No Credit Checks" height="110" width="110"/>
                      </figure>
                      <h4>No Credit Checks</h4>
                    </li>
                    <li>
                      <figure>
                        <img src={icon2} alt="Review and Compare Mortgage Rates Online" height="110" width="110"/>
                      </figure>
                      <h4>Review and Compare Online</h4>
                    </li>
                    <li>
                      <figure>
                        <img src={icon3} alt="Haggle Free Mortgage Rates" height="110" width="110"/>
                      </figure>
                      <h4>Haggle Free<br></br> Best Rates</h4>
                    </li>
                  </ul>
                  <Button onClick={routeChange} variant="contained">Let's get started</Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </section> */}

      {/* Calculator Section Start */}
      <Calculator />
      {/* Calculator Section End */}

      {/* Mortgage Rates Section Start */}
      <MortgageRate />
      {/* Mortgage Rates Section End */}

      {/* Digital Mortagage Section Start */}
      {/* <DigitalMortagage/> */}
      {/* Digital Mortagage Section End */}

      {/* Marble Section Start */}
      {/* <Marble /> */}

      <LocationCarousel/>
      {/* Marble Section End */}

      {/* Team Section Start */}
      <Teams />
      {/* Team Section End */} 
      {/* {/* Blog Section  */}
      <BlogCarousel/>
      {/* Blog Section End */}

      {/* Partner Logo Start*/}
      {/* <PartnerLogo /> */}
      {/* Partner Logo End*/}
 

      {/* partner logo Start*/}

      {/* partner logo End*/} 
    </>
  );
};

export default Home;
