import React from "react"; 
import './privacy-policy.scss';
import { Container } from "@mui/material"; 
import Helmet from 'react-helmet';
const PrivacyPolicy = function () {

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>RateShop Mortgages - Our Privacy policy and terms of service</title>
                <meta name='description' content="We work hard to protect your information and maintain absolute confidentiality with our systems, partners and actively monitor cybersecurity threats to ensure your data is secure." />
                <meta name='keywords' content="rateshop, privacy policy" />
            </Helmet>

            <section class="page-heading">
                <Container>
                    <h1>Privacy Policy</h1>
                </Container>
            </section>
            <section className="privacy-policy blog-content">
                <Container>
                    <div className="privacy-policy-wrapper blog-content-wrap sec-padding">
                        <div className="blog-text">
                           <p>RateShop &amp; it’s contracted agent/broker hereafter referred to as the “broker”, believes that your personal information deserves protection and subscribes to the privacy principles laid out in the</p>
                                <p>Personal Information Protection and Electronic Documents Act (PIPEDA).</p>
                                <p>Our Privacy Policy details certain policies implemented throughout Broker, governing <a href="http://rateshop.ca">Broker</a>'s use of personally identifiable information about users of our Site and users of services available through this site (the “Services”).</p>
                                <h3>Privacy policy updates</h3>
                                <p>Due to the Internet's rapidly evolving nature, <a href="http://rateshop.ca">Broker</a> may need to update this Privacy Policy from time to time. If so, <a href="http://rateshop.ca">Broker</a> will post its updated Privacy Policy on our Site and we may send registered users an email, so you are always aware of what personally identifiable information we may collect and how we may use this information. If any modification is unacceptable to you, you shall cease using this Site and the Services. If you do not cease using this Site and the Services, you will be deemed to have accepted the change.</p>
                                <p>If you have questions or complaints regarding our privacy policy or practices, you may contact us the following ways:</p>
                                <ol>
                                    <li>By email:<a href="mailto:info@rateshop.ca">info@rateshop.ca</a></li>
                                    <li>By regular mail: Rateshop Inc, #6 Indell Ln, Brampton, Ontario L6T3Y3, Canada</li>
                                    <li>Email the broker directly</li>
                                </ol>
                                <h3>Information collection and use</h3>
                                <p>You can generally visit our site without revealing any personally identifiable information about yourself. However, in certain sections of this site and when you use our services, we may invite you to contact us with questions or comments, request quotes or information, post content and other materials, participate in a user forum or blog, or complete a registration form.</p>
                                <p>Due to the nature of some of these activities, we may collect personally identifiable information that you submit such as your name, contact data (address, email address, phone number), gender, social insurance number, driver’s license number, income level and other financial information, license information, username, password, age or date of birth and employment information. We may also collect additional personally identifiable information that you voluntarily include in your postings or transmit with your communications to us.</p>
                                <p>We may use your personally identifiable information to deliver requested services or information, provide you, or have our Third Party service partners (as described in the Disclosure Section below) provide you, with notices regarding goods or services you may be interested in purchasing in the future, register you to use our site and services, verify your authority to enter our site and to improve the content and general administration of the site and our services.Types of personal information that we may collect from you</p>
                                <p>Personal information means information about an identifiable individual. It may include your name and address, e-mail address, employment history, or other details about you.</p>
                                <p>The table below explains the types of personal information we may collect from you.</p>
                                <p>Type Of Personal Information We May Collect From YouDescription of Personal InformationInformation you give us when applying to use or when using our Services</p>
                                <p>Individuals that wish to use our Services will typically be asked to provide the following information:</p>
                                <ol>
                                    <li>Contact information such as your name, address and contact telephone numbers;2. Unique identifiers such as your date of birth and social insurance number;3. Personal background such as age, marital status, income, employment history and household expenses; and4. Payment or banking information.</li>
                                </ol>
                                <p>Information we receive from consumer reporting agencies, financial services providers and other sources</p>
                                <p>We may collect personal information from consumer reporting agencies, including information about your credit scores and creditworthiness. We may also receive credit score or creditworthiness or other financial information from other sources including financial service providers such as licensed insolvency trustees, licensed insolvency counsellors, debt management solutions companies, mortgage brokers and specialty lending partners.</p>
                                <p>Information we may receive from third party account verification service providers</p>
                                <p>We may collect personal information (e.g., bank account and income verification information) from third parties that provide account verification services.</p>
                                <p>Information you provide when you communicate with us</p>
                                <p>This may include your name and contact information and other information you choose to provide us.</p>
                                <p>As permitted by applicable law, we may from time-to-time also use your personal information to keep you informed about our Services and about other products or services that may be of interest to you, to improve our Services and to administer contests or surveys. You may opt-out of the marketing and promotional messages but you will continue to receive other communication from us that is necessary to provide the Services. To opt-out of marketing and promotional messages click the “unsubscribe” button in the marketing and promotional message or Contact Us.</p>
                                <h3>Third party service providers</h3>
                                <p>We may share your information with third party service providers.</p>
                                <p>We may also share your personal information with other third parties to provide the Services you choose, including consumer reporting agencies (e.g., TransUnion), financial institutions, insurers and other financial services providers. We also may share your personal information with third parties that process payments on our behalf.</p>
                                <p>We may have to share your personal information in special circumstances. This would include situations when sharing is required by law, or we believe sharing will protect the safety, property or rights of [Reseller], our customers, or others. For example, we may share your information in response to a legal request (such as a subpoena, search warrant, or court order) if we believe, in good faith, that we are required to do so by law.</p>
                                <p>We may also share your information in connection with a sale, merger, bankruptcy, sale of assets or reorganization of our company. In such a case, we may share information with potential purchasers, successors and their advisors. This information could also be shared or transferred in the unlikely event that we are the subject of a bankruptcy or insolvency proceeding.</p>
                                <h3>Transfers of personal information outside of Canada</h3>
                                <p>Some third-party service providers may be located outside of Canada. In order to use these third-party service providers we may transfer your personal information outside of Canada. When we do that, your personal information may be subject to foreign laws and potential access by law enforcement or other governmental agencies in those countries.</p>
                                <h3>Protecting your personal information</h3>
                                <p>We maintain reasonable safeguards to protect your personal information against loss or theft and unauthorized access. These safeguards include physical, organizational and technical measures appropriate to the sensitivity of the information we collect and store. While we implement safeguards and are committed to protecting your personal information, no information can be guaranteed to be 100% secure from unauthorized access.</p>
                                <h3>Retaining your personal information</h3>
                                <p>We will retain your personal information as long as needed to provide the Services. We may, however, retain your personal information even after you have closed your account if retaining the personal information is reasonably necessary to comply with legal or regulatory requirements, resolve disputes, prevent fraud and abuse, or enforce our Terms of Service.</p>
                                <h3>Your choices</h3>
                                <p>If you are an individual that applies to use our Services, you may elect not to provide certain information. For example, you may choose not to provide your Social Insurance Number or certain financial information. However, by not providing all of the information requested in the application, you may not be able to take full advantage of our Services and it may hamper our ability to provide you with the Services you want.</p>
                                <p>You can choose how you receive communications from us by changing the communication preferences in your account settings or by clicking on an unsubscribe link in an e-mail from us. Please note that we may continue to send you certain communications relating to the management of your account.</p>
                                <h3>Opting out of electronic communication</h3>
                                <p>You may withdraw your consent to receiving electronic communications from us at any time by following the opt-out instructions provided in each communication or by emailing us at <a href="mailto:info@rateshop.ca">info@rateshop.ca</a></p>
                                <h3>Children's privacy</h3>
                                <p><a href="http://rateshop.ca">Broker</a> recognizes the privacy interests of children and we encourage parents and guardians to take an active role in their children's online activities and interests. This Site is not intended for children under the age of 13. <a href="http://rateshop.ca">Broker</a> does not target its Services or this Site to children under 13. <a href="http://rateshop.ca">Broker</a> does not knowingly collect personally identifiable information from children under the age of 13.</p>
                                <h3>Cookies</h3>
                                <p>Cookies are small text files stored on a user’s hard drive containing a unique identification number used to identify your computer. Cookies are used on most websites and do not save any personal information. Browsers will automatically accept or reject cookies, or notify you each time a cookie is set by a website you visit. <a href="http://rateshop.ca">Broker</a> uses cookies to measure traffic on our website and to track when a user accesses a partner website or views a specific offer. We use them to track our performance with our partners so that we may optimize our website to improve user experience. If you would like more information, please send us an email at <a href="mailto:info@rateshop.ca">info@rateshop.ca</a></p>
                                <h3>Aggregate information</h3>
                                <p>The Site may track the total number of visitors to our Site, the number of visitors to each page of our Site, browser type, and IP addresses, and we may analyze this data for trends and statistics in the aggregate, but such information will be maintained, used and disclosed in aggregate form only and it will not contain personally identifiable information. We may use such aggregate information to analyze trends, administer the Site, track users' movement, and gather broad demographic information for aggregate use.</p>
                                <h3>Disclosure</h3>
                                <p>We may provide your personally identifiable information and the data generated by cookies and the aggregate information to the vendors and service agencies that we may engage to assist us in providing our services to you. We may disclose your personally identifiable information and your communications on this site when we reasonably believe we are required to do so by law, or when we deem it appropriate or necessary to provide such information to law enforcement authorities.</p>
                                <p>We will not sell your personally identifiable information to any company or organization, except we may transfer your personally identifiable information to a successor entity upon a merger, consolidation or other corporate reorganization in which <a href="http://rateshop.ca">Broker</a> participates or to a purchaser of all or substantially all of <a href="http://rateshop.ca">Broker</a>'s assets to which this site relates. When you request a quote, or make an inquiry about products, services and information on this site, you are expressly choosing to, and opting-in to, have <a href="http://rateshop.ca">Broker</a> transfer your personally identifiable information to our Third-Party service partners and affiliates for the express purpose of providing financial products or services (such as loans and mortgages) that match your inquiry. We only transfer your information after you have given us express consent on our site, over the phone, or in person.</p>
                                <h3>Links to Third Party sites</h3>
                                <p>The site may provide links to other websites or resources over which <a href="http://rateshop.ca">Broker</a> does not have control ("External Websites"). Such links do not constitute an endorsement by <a href="http://rateshop.ca">Broker</a> of those external websites. You acknowledge that <a href="http://rateshop.ca">Broker</a> is providing these links to you only as a convenience, and further agree that <a href="http://rateshop.ca">Broker</a> is not responsible for the content of such External Websites. Your use of external websites is subject to the terms of use and privacy policies located on the linked to those sites.</p>
                                <h3>Security</h3>
                                <p>We may employ procedural and technological security measures that are reasonably designed to help protect your personally identifiable information from loss, unauthorized access, disclosure, alteration or destruction. <a href="http://rateshop.ca">Broker</a> may use secure socket layer and other security measures to help prevent unauthorized access to your personally identifiable information.</p>
                                <h3>Marketing</h3>
                                <p>We may collect and use customer or potential customer information retrieved from marketing platforms such as Facebook, Google, Pinterest, Instragram, Linkedin Etc. for ongoing marketing initiatives that are limited to the RateShop Inc. and its employees/independent contractors/marketing agencies. The information i.e. name, email, phone number, address etc. may be used to target market specific products offered by RateShop Inc. or its partners, service providers, and/or affiliates. RateShop Inc. will make every reasonable effort to protect this information and prohibit the use of the information by any other entity unauthorized to the use of collected information.</p>
                                <h3>Accessing your personal information</h3>
                                <p>You can also contact us to access your personal information. We will respond to requests to access, update or delete personal information.</p>
                                <h3>Terms and Conditions</h3>
                                <p>Last updated: April 13, 2024</p>
                                <p>Please read these terms and conditions carefully before using Our Service.</p>
                                <h3>Interpretation and Definitions</h3>
                                <h3>Interpretation</h3>
                                <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
                                <h3>Definitions</h3>
                                <p>For the purposes of these Terms and Conditions:</p>
                                <ul>
                                    <li><strong>Affiliate</strong>&nbsp;means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</li>
                                    <li><strong>Country</strong>&nbsp;refers to: Ontario, Canada</li>
                                    <li><strong>Company</strong>&nbsp;(referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to RateShop Inc. and it's broker, 6 Indell Ln Brampton On L6T3Y3.</li>
                                    <li><strong>Device</strong>&nbsp;means any device that can access the Service such as a computer, a cellphone or a digital tablet.</li>
                                    <li><strong>Service</strong>&nbsp;refers to the Website.</li>
                                    <li><strong>Terms and Conditions</strong>&nbsp;(also referred as "Terms") mean these Terms and Conditions that form the entire agreement between You and the Company regarding the use of the Service. This Terms and Conditions agreement has been created with the help of the&nbsp;<a href="https://www.termsfeed.com/terms-conditions-generator/" target="_blank">Terms and Conditions Generator</a>.</li>
                                    <li><strong>Third-party Social Media Service</strong>&nbsp;means any services or content (including data, information, products or services) provided by a third-party that may be displayed, included or made available by the Service.</li>
                                    <li><strong>Website</strong>&nbsp;refers to RateShop or it's broker, accessible from [www.rateshop.ca or the broker site](www.rateshop.ca or the broker site)</li>
                                    <li><strong>You</strong>&nbsp;means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
                                </ul>
                                <h3>Acknowledgment</h3>
                                <p>These are the Terms and Conditions governing the use of this Service and the agreement that operates between You and the Company. These Terms and Conditions set out the rights and obligations of all users regarding the use of the Service.</p>
                                <p>Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms and Conditions. These Terms and Conditions apply to all visitors, users and others who access or use the Service.</p>
                                <p>By accessing or using the Service You agree to be bound by these Terms and Conditions. If You disagree with any part of these Terms and Conditions then You may not access the Service.</p>
                                <p>You represent that you are over the age of 18. The Company does not permit those under 18 to use the Service.</p>
                                <p>Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your personal information when You use the Application or the Website and tells You about Your privacy rights and how the law protects You. Please read Our Privacy Policy carefully before using Our Service.</p>
                                <h3>Links to Other Websites</h3>
                                <p>Our Service may contain links to third-party web sites or services that are not owned or controlled by the Company.</p>
                                <p>The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.</p>
                                <p>We strongly advise You to read the terms and conditions and privacy policies of any third-party web sites or services that You visit.</p>
                                <h3>Termination</h3>
                                <p>We may terminate or suspend Your access immediately, without prior notice or liability, for any reason whatsoever, including without limitation if You breach these Terms and Conditions.</p>
                                <p>Upon termination, Your right to use the Service will cease immediately.</p>
                                <h3>Limitation of Liability</h3>
                                <p>Notwithstanding any damages that You might incur, the entire liability of the Company and any of its suppliers under any provision of this Terms and Your exclusive remedy for all of the foregoing shall be limited to the amount actually paid by You through the Service or 100 USD if You haven't purchased anything through the Service.</p>
                                <p>To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, loss of data or other information, for business interruption, for personal injury, loss of privacy arising out of or in any way related to the use of or inability to use the Service, third-party software and/or third-party hardware used with the Service, or otherwise in connection with any provision of this Terms), even if the Company or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.</p>
                                <p>Some states do not allow the exclusion of implied warranties or limitation of liability for incidental or consequential damages, which means that some of the above limitations may not apply. In these states, each party's liability will be limited to the greatest extent permitted by law.</p>
                                <h3>"AS IS" and "AS AVAILABLE" Disclaimer</h3>
                                <p>The Service is provided to You "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, the Company, on its own behalf and on behalf of its Affiliates and its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the Service, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, the Company provides no warranty or undertaking, and makes no representation of any kind that the Service will meet Your requirements, achieve any intended results, be compatible or work with any other software, applications, systems or services, operate without interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected.</p>
                                <p>Without limiting the foregoing, neither the Company nor any of the company's provider makes any representation or warranty of any kind, express or implied: (i) as to the operation or availability of the Service, or the information, content, and materials or products included thereon; (ii) that the Service will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through the Service; or (iv) that the Service, its servers, the content, or e-mails sent from or on behalf of the Company are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components.</p>
                                <p>Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to You. But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law.</p>
                                <h3>Governing Law</h3>
                                <p>The laws of the Country, excluding its conflicts of law rules, shall govern this Terms and Your use of the Service. Your use of the Application may also be subject to other local, state, national, or international laws.</p>
                                <h3>Disputes Resolution</h3>
                                <p>If You have any concern or dispute about the Service, You agree to first try to resolve the dispute informally by contacting the Company.</p>
                                <h3>For European Union (EU) Users</h3>
                                <p>If You are a European Union consumer, you will benefit from any mandatory provisions of the law of the country in which You are resident.</p>
                                <h3>United States Legal Compliance</h3>
                                <p>You represent and warrant that (i) You are not located in a country that is subject to the United States government embargo, or that has been designated by the United States government as a "terrorist supporting" country, and (ii) You are not listed on any United States government list of prohibited or restricted parties.</p>
                                <h3>Severability and Waiver</h3>
                                <h3>Severability</h3>
                                <p>If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.</p>
                                <h3>Waiver</h3>
                                <p>Except as provided herein, the failure to exercise a right or to require performance of an obligation under these Terms shall not affect a party's ability to exercise such right or require such performance at any time thereafter nor shall the waiver of a breach constitute a waiver of any subsequent breach.</p>
                                <h3>Translation Interpretation</h3>
                                <p>These Terms and Conditions may have been translated if We have made them available to You on our Service. You agree that the original English text shall prevail in the case of a dispute.</p>
                                <h3>Changes to These Terms and Conditions</h3>
                                <p>We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a revision is material We will make reasonable efforts to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at Our sole discretion.</p>
                                <p>By continuing to access or use Our Service after those revisions become effective, You agree to be bound by the revised terms. If You do not agree to the new terms, in whole or in part, please stop using the website and the Service.</p>
                                <h3>Contact Us</h3>
                                <p>If you have any questions about these Terms and Conditions, You can contact us:</p>
                                <ul>
                                    <li>By email:<a href="mailto:info@rateshop.ca">info@rateshop.ca</a></li>
                                    <li>By mail: 6 Indell Ln Brampton On L6T3Y3</li>
                                </ul>
                                </div> 
                    </div>
                </Container>
            </section>
        </>
    )
}

export default PrivacyPolicy;