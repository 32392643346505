import React from 'react';
import LinearStepper from './LinearStepper';
import { CssBaseline, Container, Paper, Box, Grid } from "@mui/material";
import './QuickApp.scss';
// import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import greyBulb from "../../assets/public/images/grey-bulb.png";
import bulb from "../../assets/public/images/bulb.png";
import InfoBar from '../info-bar/InfoBar';
import ApplyLanding from '../../components/popup/ApplyLanding';
import {   Pagination } from 'swiper';
import { Swiper, SwiperSlide    } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const QuickApp = () => {
  const [expandedInfo1, setExpandedInfo1] = React.useState('');
  const handleChangeInfo1 = (panelInfo1) => (event, newExpandedInfo1) => {
    setExpandedInfo1(newExpandedInfo1 ? panelInfo1 : false);
  };


  const [expandedInfoA, setExpandedInfoA] = React.useState('');
  const handleChangeInfoA = (panelInfoA) => (event, newExpandedInfoA) => {
    setExpandedInfoA(newExpandedInfoA ? panelInfoA : false);
  };

  const [expandedInfo101, setExpandedInfo101] = React.useState('');
  const handleChangeInfo101 = (panelInfo101) => (event, newExpandedInfo101) => {
    setExpandedInfo101(newExpandedInfo101 ? panelInfo101 : false);
  };

  const [expandedInfo201, setExpandedInfo201] = React.useState('');
  const handleChangeInfo201 = (panelInfo201) => (event, newExpandedInfo201) => {
    setExpandedInfo201(newExpandedInfo201 ? panelInfo201 : false);
  };

  const [expandedInfo301, setExpandedInfo301] = React.useState('');
  const handleChangeInfo301 = (panelInfo301) => (event, newExpandedInfo301) => {
    setExpandedInfo301(newExpandedInfo301 ? panelInfo301 : false);
  };

  return (
    <>
      <section className='quick-com-parent'>
        <div className="quick-container">
          <Container>
            <div className='text-center top-heading-cs heading '>
              <h1><span>Start with</span> our Mortgage Approval Tool!</h1>
              <p>Access to 65+ Lenders, Dedicated Customer Service</p>
              {/* <ul className='step2_mortgage'>
                <li>Shop several lenders with just 1 Credit Check</li>
                <li>Nationwide Mortgage Approvals</li>
                <li>No Rate Gimmicks, No Hidden Fees</li>
              </ul> */}
              <Swiper
                spaceBetween={50}
                slidesPerView={1}
                loop={true} 
                pagination={{ clickable: true }} 
      modules={[  Pagination ]}
                // navigation={true}
                // autoplay={{
                //   delay: 1,
                //   disableOnInteraction: false,
                // }}
                // freeMode={true}
                // speed={10000}
                // simulateTouch={false}
                // modules={[Autoplay]}
                // allowTouchMove={true}
                className="featuresSwiper"
              >
                <SwiperSlide>Shop several lenders with just 1 Credit Check</SwiperSlide>
                <SwiperSlide>Nationwide Mortgage Approvals</SwiperSlide>
                <SwiperSlide>No Rate Gimmicks, No Hidden Fees</SwiperSlide> 
              </Swiper>
            </div>
          </Container>
          <div className="q-i-wrap">
            <div className="info-accordions">
              <Accordion expanded={expandedInfo1 === 'panel1'} onChange={handleChangeInfo1('panel1')}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                  <Typography><img className="off" src={greyBulb} alt="" /><img className="on" src={bulb} alt="" /></Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <Accordion expanded={expandedInfoA === 'panelA'} onChange={handleChangeInfoA('panelA')}>
                      <AccordionSummary aria-controls="panelAd-content" id="panelAd-header">
                        <Typography>Purchase</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <Accordion expanded={expandedInfo101 === 'panel101'} onChange={handleChangeInfo101('panel101')}>
                            <AccordionSummary aria-controls="panel101d-content" id="panel101d-header">
                              <Typography>1. What are the downpayment requirements for a purchase?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography>Our lenders are open to accept gifted or borrowed downpayment in some cases. Down payment confirmations typically mean proving your ownership of the money for the past 3 months.</Typography>
                              <Typography>When purchasing a rental property, downpayment may not be borrowed or gifted.</Typography>
                              <Typography>Working with a RateShop broker means finding the perfect lender that is flexible to work with regardless of your situation.</Typography>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion expanded={expandedInfo101 === 'panel102'} onChange={handleChangeInfo101('panel102')}>
                            <AccordionSummary aria-controls="panel102d-content" id="panel102d-header">
                              <Typography>2. How do you calculate my budget?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography>Your maxmimum purchase price is driven by factors like downpayment, credit score, and income.</Typography>
                              <Typography>Office of the Superintendent of Financial Institutions (OSFI) mandates the use of stress test, to ensure an applicant can withstand the market interest rate changes at the time of qualification.</Typography>
                              <Typography>Your total mortgage payment, heat and property taxes may only be a maximum of 39%, and other debt obligations combined with a maximum of 44% of your total gross income.</Typography>
                              <Typography>RateShop offers access to certain credit unions and trust companies that could deliver a higher affordability.</Typography>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion expanded={expandedInfo101 === 'panel103'} onChange={handleChangeInfo101('panel103')}>
                            <AccordionSummary aria-controls="panel103d-content" id="panel103d-header">
                              <Typography>3. What's the difference between insured and uninsured. </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography>While Canadians can qualify for a mortgage with less than 20% down payment, they are subject to a default insuarance premium which is a percentage of the requested mortgage amount. This insurance policy acts as a guarantee to the bank incase of non-payment or default. The insurance premiums are added to the mortgage balance, therefore called insured mortgages.</Typography>
                              <Typography>For clients' purchashing with 20% or more, flexibilities like 30 year amortization and no cost of insurance opens up to buy and qualify for larger loans amounts.</Typography>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion expanded={expandedInfo101 === 'panel104'} onChange={handleChangeInfo101('panel104')}>
                            <AccordionSummary aria-controls="panel104d-content" id="panel104d-header">
                              <Typography>4. What's the advantage of a pre-approval?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography>Though approvals don't mean a guarantee to financing, they can be useful for securing a good rate, a condition free offer to purchase or even locking in a confirmation of mortgage funding based on a thorough review.</Typography>
                              <Typography>Incomes and debt can vary based on job types and situations, having a full review done by a mortgage advisor can support a confident home buying experience.</Typography>
                            </AccordionDetails>
                          </Accordion>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expandedInfoA === 'panelB'} onChange={handleChangeInfoA('panelB')}>
                      <AccordionSummary aria-controls="panelBd-content" id="panelBd-header">
                        <Typography>Home Equity</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <Accordion expanded={expandedInfo201 === 'panel201'} onChange={handleChangeInfo201('panel201')}>
                            <AccordionSummary aria-controls="panel201d-content" id="panel201d-header">
                              <Typography>1. Is it possible to get a home equity loan with a bad credit or no income?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography>Home equity loans are offered regardless of income or credit situations. Equity-based lending allows customers to utilize their home value built up over time to consolidate debts, use funds for investment or any other needs by offering quick and easy funding.</Typography>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion expanded={expandedInfo201 === 'panel202'} onChange={handleChangeInfo201('panel202')}>
                            <AccordionSummary aria-controls="panel202d-content" id="panel202d-header">
                              <Typography>2. What are the typical terms for a home equity loan?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography>Home equity loans are generally offered over one, two, three year terms, however we do offer short-term home equity loans with discounted borrowing costs for clients expecting to pay off the balances in 6 months or less.</Typography>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion expanded={expandedInfo201 === 'panel203'} onChange={handleChangeInfo201('panel203')}>
                            <AccordionSummary aria-controls="panel203d-content" id="panel203d-header">
                              <Typography>3. How do interest rates work on HELOC?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography>Home equity loans and Home Equity lines are interest only payments, amortization to pay off part principal is also available on certain products. These specialized products allow clients to borrow and return the money according to their needs, without having to incur an interest charge when they don't need access to the money.</Typography>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion expanded={expandedInfo201 === 'panel204'} onChange={handleChangeInfo201('panel204')}>
                            <AccordionSummary aria-controls="panel204d-content" id="panel204d-header">
                              <Typography>4. How does debt consolidation work?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography>Home equity loans is a perfect solution for that consolidation, by eliminating debt on credit cards, line of credit, high interest loans, clients can pay off their balances and boost their credit score, by instantly reducing their credit utilization, and with the additional benefit of not having the balance or the monthly payment of the home equity loan show up on their credit reports.</Typography>
                            </AccordionDetails>
                          </Accordion>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expandedInfoA === 'panelC'} onChange={handleChangeInfoA('panelC')}>
                      <AccordionSummary aria-controls="panelCd-content" id="panelCd-header">
                        <Typography>Refinance</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <Accordion expanded={expandedInfo301 === 'panel301'} onChange={handleChangeInfo301('panel301')}>
                            <AccordionSummary aria-controls="panel301d-content" id="panel301d-header">
                              <Typography>1. Mortgage renewal vs mortgage refinance</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography>A Mortgage renewal allows a client to switch the current mortgage lender to a different mortgage lender with a better interest rate, without making any changes to the existing mortgage balance. Some lenders may even cover the cost of the switch, while others May permit up to $5,000 in expenses to be added on to the balance.</Typography>
                              <Typography>A mortgage refinance permits are client to use the existing equity in the property towards debt consolidation or equity take out in cash, many clients also prefer to have a line of credit attached to their mortgage for flexibility and access to funds. Refinances prior to end of term with the current lender may result in penalties to break away from the current mortgage however allure interest rate or consolidation of debt can result in the comparative savings over the penalties.</Typography>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion expanded={expandedInfo301 === 'panel302'} onChange={handleChangeInfo301('panel302')}>
                            <AccordionSummary aria-controls="panel302d-content" id="panel302d-header">
                              <Typography>2. Why do we inquire if you would like to borrow more?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography>As property values have risen in canada, utilizing your Equity is a great opportunity to use the words consolidations, investment opportunities or setting up a retirement fund. You don't refinance every other day, when you do you will be presented with all options available to discuss the best financial plan for you by evaluating the pros and cons of getting more equity out of your property.</Typography>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion expanded={expandedInfo301 === 'panel303'} onChange={handleChangeInfo301('panel303')}>
                            <AccordionSummary aria-controls="panel303d-content" id="panel303d-header">
                              <Typography>3. Why do you need to have your house appraised for a refinance?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography>Banks use certified real estate appraisers to evaluate the current property market value before funding the mortgage, so they can determine qualification. On a conventional mortgage, Canadian financial institutions can offer up to 80% loan against its value.</Typography>
                            </AccordionDetails>
                          </Accordion>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className='quick-inner'>
              <Grid container>
                <Grid xs={12} sm={12} md={12} lg={12} item className="cs-p0">
                  <LinearStepper />
                </Grid>
              </Grid>
            </div>
          </div>
          <InfoBar />
        </div>
      </section>
      {/* <ApplyLanding /> */}
    </>
  );
}

export default QuickApp;